import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getStorage } from "firebase/storage"; // Import getStorage
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAI7bNERfEH0iDzs2ChtedERICSXsb3DGQ",
  authDomain: "quiznation-cosmostaker.firebaseapp.com",
  projectId: "quiznation-cosmostaker",
  storageBucket: "quiznation-cosmostaker.appspot.com",
  messagingSenderId: "576169725107",
  appId: "1:576169725107:web:2c0caeb317807ea89434c6",
  measurementId: "G-7YS6H2W1BY",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Storage
const storage = getStorage(app); // Initialize storage

// export const messaging = getMessaging(app);

// const requestPermission = async () => {
//   try {
//     await Notification.requestPermission();
//   } catch (error) {}
// };

// const getMessagingToken = async () => {
//   try {
//     const currentToken = await getToken(messaging, {
//       vapidKey:
//         "BNIrIrj9Lejy6vkPh3NYmJnmLfaqxbicK2mBrbfxxe1up9RD_7LVaW9TpD2-0VPu5DiySMo8tMHy_tovtxnvIwA",
//     });
//     if (currentToken) {
//       // Send the token to your server and update the UI if necessary
//     } else {
//       requestPermission();
//     }
//   } catch (error) {
//     console.error("An error occurred while retrieving token.", error);
//   }
// };

// onMessage(messaging, (payload) => {
//   // Customize notification here
//   // Show notification or update UI
// });

// getMessagingToken();

export { db, storage, collection, addDoc };
