import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  getDashboardFailure,
  getGoogleFailure,
  getGoogleSuccess,
} from "../../Actions";
import { GetAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";

// Worker saga for handling Add Category request
function* getGoogleSaga(action) {
  const token = action.payload.token;
  const path = `admin/googleToken`;
  try {
    const response = yield debounce(GetAPI, token, path);
    yield put(getGoogleSuccess(response));
  } catch (error) {
    yield put(getGoogleFailure(error));
  }
}

export default getGoogleSaga;
