import React from "react";
import {
  MdOutlineKeyboardDoubleArrowDown,
  MdOutlineKeyboardDoubleArrowUp,
} from "react-icons/md";
import { TbCoinRupeeFilled } from "react-icons/tb";
import Loading from "../../components/loading";
import GreenLoader from "../Loaders/GreenLoader";
import CountUp from "react-countup";

const revenueButtonData = [
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
  { label: "Yearly", value: "YEARLY" },
];
const Revenue = ({
  revenue,
  percentage,
  onChange,
  dataPreference,
  loading,
  startDate,
  endDate,
}) => {
  return (
    <div className=" border shadow-md rounded-lg flex-grow p-2 mx-1 mb-2 w-max">
      <div className="flex justify-between items-center gap-2">
        <div className="text-lg font-semibold text-gray-500 ">Revenue</div>
        <div
          className={`flex h-min items-center px-2 text-[0.8rem] ${
            percentage > 0 ? "text-green-600" : "text-red-600"
          } rounded-full`}
        >
          {percentage > 0 ? (
            <MdOutlineKeyboardDoubleArrowUp className="text-xl font-bold  text-green-600" />
          ) : (
            <MdOutlineKeyboardDoubleArrowDown className="text-xl font-bold text-red-600" />
          )}
          <div>{percentage > 0 ? percentage : percentage * -1}%</div>
        </div>
      </div>
      <div>
        <div className="flex items-center relative min-h-[3rem] w-full">
          {loading ? (
            <GreenLoader />
          ) : (
            <div className="text-[2.3rem] font-bold">
              {/* {revenue?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              })} */}
              ₹ <CountUp end={revenue} decimals={2} />
            </div>
          )}
        </div>
        <div className="flex h-full items-center ">
          {revenueButtonData.map((item, index) => {
            return (
              <button
                key={index}
                className={`my-1 px-2 w-max h-max text-center text-[0.8rem] py-1 hover:bg-[#47A04F] hover:text-white rounded-md border mx-1 ${
                  dataPreference === item?.value
                    ? "bg-[#47A04F] text-white"
                    : ""
                }`}
                onClick={() => onChange(item.value)}
              >
                {item.label}
              </button>
            );
          })}
        </div>
        <div className="flex gap-6 items-center"></div>
        <span className="text-sm text-gray-500">From:</span>{" "}
        <span className="text-sm text-gray-500 font-semibold">
          {startDate?.replaceAll("-", "/")} &nbsp;&nbsp;&nbsp;{" "}
          <span className="text-sm font-normal text-gray-500">To:</span>{" "}
          {endDate?.replaceAll("-", "/")}
        </span>
      </div>
    </div>
  );
};

export default Revenue;

{
  revenueButtonData.map((item, index) => {
    return (
      <button
        key={index}
        className="px-2 text-white min-w-16 text-[0.8rem] border-2 border-[#4CAE50] rounded-full bg-[#4CAE50] mx-1 hover:bg-[#3c893f]"
      >
        {item.label}
      </button>
    );
  });
}
