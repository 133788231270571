import {
  GET_USER_ALL_PLAYED_QUIZ_REQUEST,
  GET_USER_ALL_PLAYED_QUIZ_SUCCESS,
  GET_USER_ALL_PLAYED_QUIZ_FAILURE,
} from "../../Utils/Constants";

const initialState = {
  allPlayedQuiz: {},
  loading: false,
  error: null,
};

const getUserAllPlayedQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ALL_PLAYED_QUIZ_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_USER_ALL_PLAYED_QUIZ_SUCCESS:
      return {
        ...state,
        allPlayedQuiz: action.payload,
        loading: false,
        error: null,
      };

    case GET_USER_ALL_PLAYED_QUIZ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default getUserAllPlayedQuizReducer;
 