const tableHeaders = ["Rank", "Amount"];
export const WinnerBreakupModal = ({ setOpenModal, data }) => {
  return (
    <div
      className="fixed h-[100vh] w-full top-0 right-0 z-50 flex justify-center items-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
    >
      <div tabIndex="-1" aria-hidden="true">
        <div className="relative w-[300px] sm:w-[420px] md:w-[600px]">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
              data-modal-hide="authentication-modal"
            >
              <svg
                onClick={() => {
                  setOpenModal(false);
                }}
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 py-6 lg:px-8">
              {/* <h3 className="mb-3 md:mb-4 text-xl font-medium ">{`Number of winning: ${Math.round(data.totalWinner)}`}</h3> */}
              {data.totalWinner !== undefined ? (
                <h3 className="mb-3 md:mb-4 text-xl font-medium ">{`Number of winners: ${
                  data.totalWinner > 1 && data.totalWinner <= 10
                    ? Math.floor(data.totalWinner)
                    : Math.round(data.totalWinner)
                }`}</h3>
              ) : (
                <h3 className="mb-3 md:mb-4 text-xl font-medium ">{`Number of winners: Quizmaster Title 🥇`}</h3>
              )}
              {/* <h7><b>Not:- </b>Wining breakup amount may increase depending upon the filled spots</h7> */}
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
                <table className="w-full text-sm text-left text-gray-700">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    {data?.numberOfWinner?.length > 0 && (
                      <tr>
                        {tableHeaders.map((item, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="px-6 py-2 text-center"
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {data?.numberOfWinner?.map((amount, i) => {
                      return (
                        <tr className="bg-white border-b " key={i}>
                          {/* <td className="px-6 py-2 text-center text-gray-900">number of winner 1</td> */}
                          <>
                            <td className="px-6 py-2 text-center text-gray-900">
                              {`Rank ${amount.rank}`}
                            </td>
                            <td className="px-6 py-2 text-center text-gray-900">
                              {amount.amount}
                            </td>
                          </>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
