import {
    GET_RANDOM_QUESTION_REQUEST,
    GET_RANDOM_QUESTION_SUCCESS,
    GET_RANDOM_QUESTION_FAILURE,
  } from "../Utils/Constants";
  
  const initialState = {
    randomQuestion: {},
    loading: false,
    error: null,
  };
  
  const randomQuestionReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_RANDOM_QUESTION_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case GET_RANDOM_QUESTION_SUCCESS:
        return {
          ...state,
          randomQuestion: action.payload,
          loading: false,
          error: null,
        };
        
  
      case GET_RANDOM_QUESTION_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default randomQuestionReducer;
  