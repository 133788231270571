import { put, call } from "redux-saga/effects";
import { ActiveAPI, ActivePutAPI } from "../../components/Config/Apiinterface";
import { activeUserFailure, activeUserSuccess } from "../../Actions";
import { toast } from "react-toastify";

function* activeUserSaga(action) {
  const enable = action.payload.enable;
  const token = action.payload.token;
  const userId = action.payload.userId;
  const path = `admin/user/enable?id=${userId}&enable=${enable}`;
  try {
    const response = yield call(ActivePutAPI, token, path);
    yield put(activeUserSuccess(response));
    toast.success(response.message);
  } catch (error) {
    yield put(activeUserFailure(error));
  }
}

export default activeUserSaga;
