import {
  DELETE_MULTIPUL_QUESTIONS_REQUEST,
  DELETE_MULTIPUL_QUESTIONS_SUCCESS,
  DELETE_MULTIPUL_QUESTIONS_FAILURE,
  DELETE_MULTIPUL_QUESTIONS_RESET
} from "../Utils/Constants";

export const deleteMultipulQuestionsRequest = (payload) => {
  return {
    type: DELETE_MULTIPUL_QUESTIONS_REQUEST,
    payload: payload,
  };
};

export const deleteMultipulQuestionsSuccess = (data) => {
  return {
    type: DELETE_MULTIPUL_QUESTIONS_SUCCESS,
    payload: data,
  };
};

export const deleteMultipulQuestionsFailure = (error) => {
  return {
    type: DELETE_MULTIPUL_QUESTIONS_FAILURE,
    payload: error,
  };
};

export const deleteMultipulQuestionsReset = () => {
  return {
    type: DELETE_MULTIPUL_QUESTIONS_RESET
  }
}
