import {
  UPDATE_SCHEDULE_NOTIFICATION_FAILURE,
  UPDATE_SCHEDULE_NOTIFICATION_REQUEST,
  UPDATE_SCHEDULE_NOTIFICATION_SUCCESS,
} from "../../Utils/Constants";

const initialState = {
  updateScheduleNotification: {},
  loading: false,
  error: null,
};

const updateScheduleNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SCHEDULE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SCHEDULE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        updateScheduleNotification: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_SCHEDULE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default updateScheduleNotificationReducer;
