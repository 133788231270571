import {
  GET_USER_QUIESTION_REQUEST,
  GET_USER_QUIESTION_SUCCESS,
  GET_USER_QUIESTION_FAILURE,
  GET_USER_QUIESTION_RESET
} from "../../Utils/Constants";

export const getLeaderbordUserQuestionRequest = (payload) => {
  return {
    type: GET_USER_QUIESTION_REQUEST,
    payload: payload,
  };
};

export const getLeaderbordUserQuestionSuccess = (data) => {
  return {
    type: GET_USER_QUIESTION_SUCCESS,
    payload: data,
  };
};

export const getLeaderbordUserQuestionFailure = (error) => {
  return {
    type: GET_USER_QUIESTION_FAILURE,
    payload: error,
  };
};

export const getLeaderbordUserQuestionReset = () => {
  return {
    type: GET_USER_QUIESTION_RESET,
  };
};