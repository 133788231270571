import {
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE,
} from "../../Utils/Constants";

const initialState = {
  sendNotification: {},
  loading: false,
  error: null,
};

const sendNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sendNotification: action.payload,
        loading: false,
        error: null,
      };

    case SEND_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default sendNotificationReducer;
