import React from "react";
import quizNation from "../images/Quiz_Nation.svg";

function QuizHeaderIcon() {
  return (
    <div className="my-4 mr-4">
      <img alt="logo" src={quizNation} />
    </div>
  );
}

export default QuizHeaderIcon;
