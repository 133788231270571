import {
  GET_QUIZ_BOTS_PROBABILITY_FAILURE,
  GET_QUIZ_BOTS_PROBABILITY_REQUEST,
  GET_QUIZ_BOTS_PROBABILITY_SUCCESS,
} from "../../Utils/Constants";

export const getQuizBotsProbabilityRequest = (payload) => {
  return {
    type: GET_QUIZ_BOTS_PROBABILITY_REQUEST,
    payload: payload,
  };
};

export const getQuizBotsProbabilitySuccess = (data) => {
  return {
    type: GET_QUIZ_BOTS_PROBABILITY_SUCCESS,
    payload: data,
  };
};

export const getQuizBotsProbabilityFailure = (error) => {
  return {
    type: GET_QUIZ_BOTS_PROBABILITY_FAILURE,
    payload: error,
  };
};
