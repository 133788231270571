import {
  getUserAllTransectionsFailure,
  getUserAllTransectionsSuccess,
} from "../../Actions";
import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../components/Config/Apiinterface";
import { toast } from "react-toastify";

export function* getUserAllTransectionsSaga(action) {

  const token = action.payload.token;
  const userId = action.payload.userId;
  const pageNo = action.payload.pageNo;
  const path = `admin/user/allTransactions?pageNo=${pageNo}&pageSize20&userId=${userId}`;

  try {
    const response = yield call(GetAPI, token, path);
    yield put(getUserAllTransectionsSuccess(response));
    // toast.success(response.message);
  } catch (error) {
    yield put(getUserAllTransectionsFailure(error));
  }
}
