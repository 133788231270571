import { put, call } from "redux-saga/effects";
import {
  deleteData,
  postFormData,
  UpdateAPI,
} from "../components/Config/Apiinterface";
import { versionDeleteFailure, versionDeleteSuccess } from "../Actions";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Update Category request
function* versionDeleteSaga(action) {
  const token = action.payload.token;
  const item = action.payload.item;
  let path = `app/deleteVersion?id=${item}`;
  try {
    const versionPost = yield debounce(deleteData, token, path);
    if (versionPost.statusCode === 200) {
      toast.success(versionPost?.message);
    } else {
      toast.error(versionPost?.message);
    }
    yield put(versionDeleteSuccess(versionPost));
  } catch (error) {
    yield put(versionDeleteFailure(error));
  }
}

export default versionDeleteSaga;
