import React, { useState, useEffect } from "react";

export const Select = ({
  id,
  header,
  initialValue,
  options,
  callbackFn,
  showInitial,
  flage,
  disabled,
  selectedValue,
}) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (showInitial) {
      setKey((prevKey) => prevKey + 1);
    }
  }, [showInitial]);

  return (
    <div key={key} className="">
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 md:mb-2"
      >
        {header}
      </label>
      <select
        id={id}
        onChange={(e) => callbackFn(id, e.target.value)}
        disabled={disabled}
        value={selectedValue}
        className={`${flage && "lg:w-full"} ${
          disabled ? "text-gray-400 accessDenied" : "text-gray-900"
        } custom-select bg-gray-50 border border-gray-300 min-w-40  text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full md:w-[100px] lg:w-[160px] p-2.5 `}
      >
        {initialValue && (
          <option
            value={""}
            defaultValue={initialValue}
            className="text-[#000000]"
          >
            {initialValue}
          </option>
        )}
        {options?.map((option, index) => (
          <option value={option.id} key={index} className="text-[#000000]">
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};
