import {
    TOPICS_FAILURE,
    TOPICS_REQUEST,
    TOPICS_SUCCESS,
    LOGOUT,
  } from "../Utils/Constants";
  
  // Initial State
  const initialState = {
    Topics: {},
    loading: false,
    error: null,
  };
  const TopicReducer = (state = initialState, action) => {
    switch (action.type) {
      case TOPICS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
        case TOPICS_SUCCESS:
          return {
          ...state,
          Topics: action.payload,
          loading: false,
          error: null,
        };
      case TOPICS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case LOGOUT:
        return {
            Topics: null,
        };
      default:
        return state;
    }
  };
  
  export default TopicReducer;
  