import {
  RESET_PLAYER_PASSWORD_REQUEST,
  RESET_PLAYER_PASSWORD_SUCCESS,
  RESET_PLAYER_PASSWORD_FAILURE,
} from "../../../Utils/Constants";

export const resetPlayerPasswordRequest = (payload) => {
  return {
    type: RESET_PLAYER_PASSWORD_REQUEST,
    payload: payload,
  };
};

export const resetPlayerPasswordSuccess = (data) => {
  return {
    type: RESET_PLAYER_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const resetPlayerPasswordFailure = (error) => {
  return {
    type: RESET_PLAYER_PASSWORD_FAILURE,
    payload: error,
  };
};
