import {
  GET_QUIZ_BOTS_PROBABILITY_FAILURE,
  GET_QUIZ_BOTS_PROBABILITY_REQUEST,
  GET_QUIZ_BOTS_PROBABILITY_SUCCESS,
} from "../../Utils/Constants";

const initialState = {
  quizBotsProbability: {},
  loading: false,
  error: null,
};

const quizBotsProbabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUIZ_BOTS_PROBABILITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_QUIZ_BOTS_PROBABILITY_SUCCESS:
      return {
        ...state,
        quizBotsProbability: action.payload,
        loading: false,
        error: null,
      };

    case GET_QUIZ_BOTS_PROBABILITY_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default quizBotsProbabilityReducer;
