import { put, call } from "redux-saga/effects";
import {
  verifyOtpWithLogin,
} from "../../components/Config/Apiinterface";
import { verifyOtpLoginFailure, verifyOtpLoginSuccess } from "../../Actions";
import { toast } from "react-toastify";
import debounce from "../../Utils/useDebounce";

function* verifyOtpLoginSaga(action) {
  const body = {
    emailId: action.payload.emailId,
    otp: action.payload.otp,
  };
  try {
    const user = yield debounce(verifyOtpWithLogin, body);
    yield put(verifyOtpLoginSuccess(user));
    if (user.statusCode === 200) {
      toast.success(user.message);
    } else {
      toast.warning(user.message);
    }
    if(user.data.statusCode===400){
      toast.warning(user.data.message)
    }
  } catch (error) {
    yield put(verifyOtpLoginFailure(error));
  }
}

export default verifyOtpLoginSaga;
