import {
  GET_USER_BANK_ACCOUNT_REQUEST,
  GET_USER_BANK_ACCOUNT_SUCCESS,
  GET_USER_BANK_ACCOUNT_FAILURE,
} from "../../Utils/Constants";

export const getBankAccountsRequest = (payload) => {
  return {
    type: GET_USER_BANK_ACCOUNT_REQUEST,
    payload: payload,
  };
};

export const getBankAccountsSuccess = (data) => {
  return {
    type: GET_USER_BANK_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const getBankAccountsFailure = (error) => {
  return {
    type: GET_USER_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
};
