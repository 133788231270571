import {
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_REQUEST,
} from "../../Utils/Constants";

export const getUserByIdRequest = (payload) => {
  return {
    type: GET_USER_BY_ID_REQUEST,
    payload: payload,
  };
};

export const getUserByIdSuccess = (data) => {
  return {
    type: GET_USER_BY_ID_SUCCESS,
    payload: data,
  };
};

export const getUserByIdFailure = (error) => {
  return {
    type: GET_USER_BY_ID_FAILURE,
    payload: error,
  };
};

export const getUserByIdEmpty = () => {
  return {
    type: "GET_USER_BY_ID_EMPTY",
  };
};
