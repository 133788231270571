
import {
     UPDATE_QUIZ_SPOT_PERCENTAGE_FAILURE,
     UPDATE_QUIZ_SPOT_PERCENTAGE_REQUEST,
      UPDATE_QUIZ_SPOT_PERCENTAGE_SUCCESS } 
      from "../../Utils/Constants";

export const updateQuizSpotPercentageRequest = (payload) => {
  return {
    type: UPDATE_QUIZ_SPOT_PERCENTAGE_REQUEST,
    payload: payload,
  };
};

export const updateQuizSpotPercentageSuccess = (data) => {
  return {
    type: UPDATE_QUIZ_SPOT_PERCENTAGE_SUCCESS,
    payload: data,
  };
};

export const updateQuizSpotPercentageFailure = (error) => {
  return {
    type: UPDATE_QUIZ_SPOT_PERCENTAGE_FAILURE,
    payload: error,
  };
};
