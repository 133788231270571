// import React from "react";
// import { TbReportAnalytics, TbDeviceAnalytics } from "react-icons/tb";
// import { CgPerformance } from "react-icons/cg";
// import android from "../../assets/images/android.jpg";
// import IOS from "../../assets/images/apple.png";

// const buttonArray = [
//   { label: "Crash Report", icon: TbReportAnalytics },
//   { label: "Device Analytics", icon: TbDeviceAnalytics },
//   { label: "Store Listing Performance", icon: CgPerformance },
// ];

// const MobileAppCard = ({ platform, version }) => {
//   const backgroundImage = platform === "iOS" ? IOS : android;

//   return (
//     <div
//       className="flex flex-grow max-w-[9.5rem] shadow-md mx-1 bg-white mb-2 justify-center items-center border rounded-md bg-cover bg-center relative"
//       style={{
//         backgroundImage: `url(${backgroundImage})`,
//         backgroundSize: "contain", // Ensure the image fits well
//         backgroundRepeat: "no-repeat", // Prevent repeating of the image
//       }}
//     >
//       {/* Add a transparent overlay to make the text readable */}
//       <div className="absolute inset-0 bg-white bg-opacity-50"></div>
//       {/* Main content of the card */}
//       <div className="p-2 h-full w-full relative  flex flex-col gap-5">
//         <div className="text-sm font-bold">
//           Latest Version <div className="font-bold text-xl">{version}</div>
//         </div>
//         <div className="py-1 px-2 flex">
//           {buttonArray.map((item, index) => {
//             const IconComponent = item.icon;
//             return (
//               <div key={index} className="w-full flex justify-end">
//                 <button className="p-1 m-1 focus:outline-none ring-0 active:outline-none border border-[#9ce6a4] hover:bg-[#47A04F] outline-none hover:text-white text-[0.8rem] rounded-md flex items-center">
//                   <IconComponent
//                     data-tooltip-id="my-tooltip"
//                     data-tooltip-content={item.label}
//                     className="text-2xl focus:outline-none ring-0 active:outline-none"
//                   />
//                 </button>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MobileAppCard;

import React from "react";
import { TbReportAnalytics, TbDeviceAnalytics } from "react-icons/tb";
import { CgPerformance } from "react-icons/cg";
import android from "../../assets/images/android.jpg";
import IOS from "../../assets/images/apple.png";
import { Tooltip } from "react-tooltip";

const buttonArray = [
  { label: "Crash Report", icon: TbReportAnalytics, type: "crash" },
  {
    label: "Device Analytics",
    icon: TbDeviceAnalytics,
    type: "device analytics",
  },
  {
    label: "Store Listing Performance",
    icon: CgPerformance,
    type: "listing performance",
  },
];

const MobileAppCard = ({ platform, version }) => {
  const backgroundImage = platform === "iOS" ? IOS : android;

  const handleButtonClick = (type) => {
    if (type === "crash" && platform === "Android") {
      // Open Firebase Crashlytics link for Android in a new tab
      window.open(process.env.REACT_APP_ANDROID_CRASH_URL, "_blank");
    } else if (platform === "iOS") {
      // Open Firebase Crashlytics link for iOS in a new tab
      window.open(process.env.REACT_APP_IOS_CRASH_URL, "_blank");
    }
    if (type === "device analytics" && platform === "Android") {
      // Open Firebase device analytics link for Android in a new tab
      window.open(process.env.REACT_APP_ANDROID_DEVICE_ANALYTICS_URL, "_blank");
    } else if (platform === "iOS") {
      // Open Firebase Crashlytics link for iOS in a new tab
      window.open(process.env.REACT_APP_IOS_DEVICE_URL, "_blank");
    }

    if (type === "listing performance" && platform === "Android") {
      // Open Firebase listing performance link for Android in a new tab
      window.open(
        process.env.REACT_APP_ANDROID_LISTING_PERFORMANCE_URL,
        "_blank"
      );
    } else if (platform === "iOS") {
      // Open Firebase Crashlytics link for iOS in a new tab
      window.open(process.env.REACT_APP_IOS_LISTING_PERFORMANCE_URL, "_blank");
    }
  };
  return (
    <div
      className="flex flex-grow max-w-[9.5rem] shadow-md mx-1 bg-white mb-2 justify-center items-center border rounded-md bg-cover bg-center relative"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Transparent overlay to make the text readable */}
      <div className="absolute inset-0 bg-white bg-opacity-50"></div>

      {/* Main content of the card */}
      <div className="p-2 h-full w-full relative flex flex-col gap-5 z-1">
        {" "}
        {/* Added z-index */}
        <div className="text-sm font-bold">
          Latest Version <div className="font-bold text-xl">{version}</div>
        </div>
        <div className="py-1 px-2 flex">
          {buttonArray.map((item, index) => {
            const IconComponent = item.icon;
            return (
              <div
                key={index}
                className="w-full flex justify-end relative z-20"
              >
                <button
                  className="p-1 m-1 focus:outline-none ring-0 active:outline-none border border-[#9ce6a4] hover:bg-[#47A04F] outline-none hover:text-white text-[0.8rem] rounded-md flex items-center"
                  data-tooltip-id={`tooltip-${index}`} // Add unique id for each tooltip
                  data-tooltip-content={item.label}
                  onClick={() => handleButtonClick(item.type)}
                >
                  <IconComponent className="text-2xl" />
                </button>

                {/* Tooltip definition */}
                <Tooltip
                  id={`tooltip-${index}`}
                  className="z-50" // Ensure the tooltip stays above
                  place="top" // Position the tooltip on top of the button
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MobileAppCard;
