import {
  GET_SELECT_QUESTION_BY_ID_REQUEST,
  GET_SELECT_QUESTION_BY_ID_SUCCESS,
  GET_SELECT_QUESTION_BY_ID_FAILURE,
} from "../Utils/Constants";

const initialState = {
  selectQuestionByQuizId: {},
  loading: false,
  error: null,
};

const selectQuestionByQuizIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SELECT_QUESTION_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_SELECT_QUESTION_BY_ID_SUCCESS:
      return {
        ...state,
        selectQuestionByQuizId: action.payload,
        loading: false,
        error: null,
      };

    case GET_SELECT_QUESTION_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default selectQuestionByQuizIdReducer;
