import { put, call } from "redux-saga/effects";
import {
  deleteMultipulQuestionsSuccess,
  deleteMultipulQuestionsFailure,
} from "../Actions";
import { deletMultipulApi } from "../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

function* deleteMultipulQuestionsSaga(action) {
  const token = action.payload.token;
  const body = action.payload.data;
  const path = "admin/quiz/question/delete";

  try {
    const response = yield debounce(deletMultipulApi, token, path, body);
    yield put(deleteMultipulQuestionsSuccess(response));
    if (response.statusCode === 200) {
      toast.success(response.message);
    } else {
      toast.warning(response.message);
    }
  } catch (error) {
    yield put(deleteMultipulQuestionsFailure(error));
  }
}

export default deleteMultipulQuestionsSaga;
