import {
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE
} from "../../Utils/Constants"
  
  const initialState = {
    updateUser: {},
    loading: false,
    error: null,
  };
  
  const updateUserReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_USER_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          updateUser: action.payload,
          loading: false,
          error: null,
        };
      case UPDATE_USER_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default updateUserReducer;
  