import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { GetAPI } from "../components/Config/Apiinterface";
import { versionGetFailure, versionGetSuccess } from "../Actions";
import debounce from "../Utils/useDebounce";

//-----------------get all questions and by filter-------------------
function* versionGetSaga(action) {
  const token = action.payload.token;
  const sortOrder = action.payload.sortOrder;
  const filterBy = action.payload.filterBy;
  const pageNo = action.payload.pageNo;

  function buildURL(sortOrder, filterBy) {
    let url = `app/version/all?pageNo=${pageNo}&pageSize=10`;
    let hasQueryParams = true;

    if (sortOrder) {
      url += "&sortOrder=" + encodeURIComponent(sortOrder);
      hasQueryParams = true;
    }

    if (filterBy) {
      url += "&filterBy=" + encodeURIComponent(filterBy);
      hasQueryParams = true;
    }

    return url;
  }

  const newurl = buildURL(sortOrder, filterBy);

  const path = newurl;
  try {
    const result = yield debounce(GetAPI, token, path);
    yield put(versionGetSuccess(result));
    if (result.statusCode === 200) {
      // toast.success(result.message);
    } else {
      toast.info(result.message);
    }
  } catch (error) {
    yield put(versionGetFailure(error));
  }
}

export default versionGetSaga;
