import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getTransactionFailure, getTransactionSuccess } from "../../Actions";
import { GetAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";

// Worker saga for handling Topics request
function* transactionSaga(action) {
  const token = action.payload.token;
  const pageNo = action.payload.pageNo;
  const searchBy = action.payload.isSearchDropdown;
  const searchValue = action.payload.searchBy;
  const sortBy = action.payload.sortValue;
  const sortDropdownValue = action.payload.sortDropdownValue;
  const sortOrderDropdown = action.payload.sortOrderDropdown;
  const startDate = action.payload.startDate;
  const endDate = action.payload.endDate;

  function buildURL(
    pageNo,
    pageSize,
    sortDropdownValue,
    sortOrderDropdown,
    sortBy,
    searchBy,
    searchValue,
    startDate,
    endDate
  ) {
    let url = `admin/allTransactions?pageNo=${pageNo}&pageSize=${pageSize}`;

    let hasQueryParams = false;

    if (sortDropdownValue) {
      url += "&sortBy=" + encodeURIComponent(sortDropdownValue);
      hasQueryParams = true;
    }

    if (sortOrderDropdown) {
      url += "&sortOrder=" + encodeURIComponent(sortOrderDropdown);
      hasQueryParams = true;
    }

    if (sortBy) {
      url += "&filterBy=" + encodeURIComponent(sortBy);
      hasQueryParams = true;
    }

    if (searchBy) {
      url += "&searchBy=" + encodeURIComponent(searchBy);
      hasQueryParams = true;
    }

    if (searchValue) {
      url += "&searchValue=" + encodeURIComponent(searchValue);
      hasQueryParams = true;
    }
    if (startDate) {
      url += "&startDate=" + encodeURIComponent(startDate);
      hasQueryParams = true;
    }
    if (endDate) {
      url += "&endDate=" + encodeURIComponent(endDate);
      hasQueryParams = true;
    }

    return url;
  }

  const newurl = buildURL(
    pageNo,
    20,
    sortDropdownValue,
    sortOrderDropdown,
    sortBy,
    searchBy,
    searchValue,
    startDate,
    endDate
  );
  // ...

  const path = newurl;

  try {
    const result = yield debounce(GetAPI, token, path);
    yield put(getTransactionSuccess(result));
    if (result.statusCode === 200) {
      // toast.success(result.message);
    } else {
      toast.warning(result.message);
    }
  } catch (error) {
    yield put(getTransactionFailure(error));
  }
}

export default transactionSaga;
