import {
  DELETE_TOPIC_FAILURE,
  DELETE_TOPIC_REQUEST,
  DELETE_TOPIC_RESET,
  DELETE_TOPIC_SUCCESS,
  LOGOUT,
} from "../Utils/Constants";

// Initial State
const initialState = {
  topic: {},
  loading: false,
  error: null,
};

// Reducer
const DeleteTopicReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_TOPIC_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_TOPIC_SUCCESS:
      return {
        ...state,
        topic: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_TOPIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_TOPIC_RESET:
      return {
        topic: {},
        loading: false,
        error: null,
      };
    case LOGOUT:
      return {
        topic: null,
      };
    default:
      return state;
  }
};

export default DeleteTopicReducer;
