import { MdArrowBack, MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
import { connect, useDispatch } from "react-redux";
import { Select } from "../../components/select/Select";
import { Search } from "../../components/Search";
import { Pagination } from "../../components/Pagination";
import { SECRET_KEY } from "../../Utils/Constants";
import { getActivityRequest } from "../../Actions";
import {
  notificationLocalReset,
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../../components/constant";
import Loading from "../../components/loading";
import { TbServerOff } from "react-icons/tb";

const ActivityTracker = ({ activityLog }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInitial, setShowInitial] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearchDropdown, setIsSearchDropdown] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [activityArray, setActivityArray] = useState([]);
  const [desabledSerchBox, setDesabledSerchBox] = useState(true);
  const timerRef = useRef(null);

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const serchDropDown = [
    {
      id: "",
      name: "Search By",
    },
    {
      id: "role",
      name: "Role",
    },
    {
      id: "email",
      name: "E-mail",
    },
  ];

  const tableHeaders = [
    "id",
    "email",
    "updated at(date & time)",
    "type",
    "module name",
    "query name",
  ];

  const getActivityLog = () => {
    const body = {
      pageNo: currentPage,
      token: token,
    };
    dispatch(getActivityRequest(body));
  };

  useEffect(() => {
    getActivityLog();
    resetQuestionLocalFilters();
    resetUserLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
    notificationLocalReset();
  }, []);

  useEffect(() => {
    getActivityLog();
  }, [currentPage]);

  useEffect(() => {
    setIsLoading(activityLog?.loading);

    if (activityLog?.error) {
      setIsLoading(false);
    }

    if (activityLog?.activityLog?.data?.logs) {
      setActivityArray(activityLog?.activityLog?.data?.logs);
      setIsLoading(false);
    }

    if (searchValue && activityLog?.data) {
      setActivityArray([activityLog?.data]);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (activityLog?.activityLog?.statusCode === 404 ||
        activityLog?.activityLog?.statusCode === 400)
    ) {
      setActivityArray([]);
      setIsLoading(false);
    } else if (activityLog?.activityLog?.statusCode === 403) {
      window.location.href = "/";
    }

    if (activityLog?.activityLog?.data?.totalPages) {
      setTotalPages(activityLog?.activityLog?.data?.totalPages);
    }

    // eslint-disable-next-line
  }, [activityLog]);

  const searchByActivity = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event?.target.value);
    }
    const parems = {
      token,
      pageNo: searchPage,
      searchBy: searchParam || event?.target.value,
      isSearchDropdown,
    };
    const body = {
      ...parems,
      ...(isSearchDropdown && { isSearchDropdown }),
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(getActivityRequest(body));
    }, 10);
  };

  const handleSortValues = (id, value) => {
    if (id === "searchDropdown") {
      setIsSearchDropdown(value);
    }
  };

  useEffect(() => {
    if (isSearchDropdown === "role" || isSearchDropdown === "email") {
      setDesabledSerchBox(false);
    } else {
      setDesabledSerchBox(true);
    }
  }, [isSearchDropdown]);

  useEffect(() => {
    searchByActivity();
  }, [searchPage]);

  // if (isLoading) {
  //   return <Loading />;
  // }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] gap-2">
          <div className="flex justify-start cursor-pointer mb-4">
            <span
              onClick={() => navigate(-1)}
              className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
            >
              <MdArrowBack className="text-base" /> Back
            </span>
          </div>
          <div className="flex justify-between items-center">
            <div className="font-bold uppercase text-lg text-center">
              Activity
            </div>
          </div>
          <div className="flex justify-between flex-wrap">
            <button
              onClick={() => {
                setSearchValue("");
                setIsSearchDropdown("");
                getActivityLog();
              }}
            >
              <MdRefresh className="w-6 h-6 mt-2" />
            </button>

            <div className="flex justify-end gap-2">
              <div className="-mt-2.5">
                <Select
                  id="searchDropdown"
                  options={serchDropDown}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                  selectedValue={isSearchDropdown} // Pass the selectedValue state
                />
              </div>
              <Search
                label={`Category`}
                placeholder={"search record by name"}
                name={"Search"}
                searchCallbackfn={searchByActivity}
                isSearching={searchValue && isLoading}
                disabled={desabledSerchBox}
                searchValue={searchValue}
              />
            </div>
          </div>
          {activityArray?.length ? (
            <>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
                <table className="w-full text-sm text-left text-gray-700 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      {tableHeaders?.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-start"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {activityArray?.map((items, index) => (
                      <tr className="bg-white border-b " key={index}>
                        <td className="px-6 py-2 text-start">
                          <div>
                            <div>{index}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-start">
                          <div>
                            <div>{items.email}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-start">
                          <div>
                            <div>{items.updateDate}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-start">
                          <div>
                            <div>{items.type}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-start">
                          <div>
                            <div>{items.activity}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-start">
                          <div>
                            <div>{items.role}</div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {(totalPages > 1 || searchValue) && (
                <div className="flex justify-end pr-5 pt-4">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={searchValue ? searchPage : currentPage}
                    setCurrentPage={
                      searchValue ? setSearchPage : setCurrentPage
                    }
                    isLoading={isLoading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="text-center flex flex-col justify-center items-center pt-36">
              <TbServerOff className="text-[2rem] font-bold  m-2" />
              Activity Not Found
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activityLog: state?.activityLogReducer,
  };
};

export default connect(mapStateToProps)(ActivityTracker);
