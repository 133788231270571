import React from "react";
import "./Select.css";
export const ModalOptionsSelector = ({
  id,
  header,
  initialValue,
  options,
  callbackFn,
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 md:mb-2 "
      >
        {header}
      </label>
      <select
        id={id}
        onChange={(e) => callbackFn(id, e.target.value)}
        className=" focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mb-1 md:mb-2  font-medium  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 sm:w-full"
      >
        {initialValue && (
          <option
            value={""}
            defaultValue={initialValue}
            className="sm:text-sm text-[#000000]"
          >
            {initialValue}
          </option>
        )}
        {options?.map((option, index) => (
          <option
            value={option.id}
            key={index}
            className="custom-option text-[#000000]"
          >
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};
