import React from "react";

function CheckboxGroup({
  options,
  register,
  watch,
  placholder,
  Ans,
  name,
  setValue,
  error,
}) {
  const selectAns = watch(Ans)
  const handleCheckboxChange = (option) => {
    setValue('answerHi.option', option);
    setValue('answerEn.option', option);
  };

  return (
    <>
      {options.map((option, index) => (
        <div className="flex gap-4 items-center p-2 flex-wrap" key={index}>
          <div className="sm:text-center">
            <input
              id={`checkbox-option-${option}`}
              type="checkbox"
              value={option}
              checked={selectAns === option}
              onChange={() => handleCheckboxChange(option)}

              className="w-5 h-5 text-green-600 bg-gray-100 border-gray-300 form-checkbox focus:ring-green-500 focus:border-green-500"
            />
          </div>
          <div className="w-3/5 flex">
            <input
              {...register(`${name}.${option}`)}
              type="text"
              name={`${name}.${option}`}
              required
              placeholder={`${placholder} ${option}`}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  font-medium"
            />
          </div>
          {selectAns === option && (
            <p className="text-green-600 self-center">Right Answer</p>
          )}
        </div>
      ))}
    </>
  );
}

export default CheckboxGroup;
