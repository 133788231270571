import {
  DELETE_QUIZ_FAILURE,
  DELETE_QUIZ_REQUEST,
  DELETE_QUIZ_RESET,
  DELETE_QUIZ_SUCCESS,
} from "../Utils/Constants";

export const deleteQuizRequest = (payload) => {
  return {
    type: DELETE_QUIZ_REQUEST,
    payload: payload,
  };
};

export const deleteQuizSuccess = (response) => {
  return {
    type: DELETE_QUIZ_SUCCESS,
    payload: response,
  };
};

export const deleteQuizFailure = (error) => {
  return {
    type: DELETE_QUIZ_FAILURE,
    payload: error,
  };
};

export const deleteQuizReset = () => {
  return {
    type: DELETE_QUIZ_RESET,
  };
};
