import {
  GET_GOOGLE_REQUEST,
  GET_GOOGLE_SUCCESS,
  GET_GOOGLE_FAILURE,
} from "../../Utils/Constants";

// Initial State
const initialState = {
  data: {},
  loading: false,
  error: null,
};

// Reducer
const getGoogleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOOGLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_GOOGLE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case GET_GOOGLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default getGoogleReducer;
