
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { MdArrowBack } from "react-icons/md";
import { TbServerOff } from "react-icons/tb";
import { Pagination } from "../../components/Pagination";
import Loading from "../../components/loading";
import { capitalizeWords } from "../../Utils/capitalizeFn";
import { Search } from "../../components/Search";

const tableHeaders = [
  "SCREEN NAME",
  "PLATFORM",
  "PHONE",
  "DATE/TIME",
  "VALUES",
];

const AnalyticsPage = () => {
  const location = useLocation();
  const { state } = location;
  const [itemData, setItemData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 20;
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.eventList) {
      setItemData(state.eventList);
      setFilteredData(state.eventList);
      setTotalPages(Math.ceil(state.eventList.length / itemsPerPage));
      paginateData(state.eventList, 1);
    }
  }, [state]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    paginateData(filteredData, newPage);
  };
  
  // Slice the data for the current page
  const paginateData = (data, page) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedData(data.slice(startIndex, endIndex));
  };

  const searchByUser = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);
    const filtered = itemData.filter((item) =>
      item.phone?.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    setCurrentPage(1);
    paginateData(filtered, 1);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year} ${date.toLocaleTimeString()}`;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className={`bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3`}
    >
      <div className="bg-white p-4 shadow-md rounded-lg min-h-[85vh] gap-2">
        <span
          onClick={() => navigate(-1)}
          className="bg-blue-100 cursor-pointer hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
        >
          <MdArrowBack className="text-base" /> Back
        </span>
        <div className="flex justify-between items-center flex-wrap">
          <div className="my-2 text-lg font-bold uppercase">
            {state?.scenario ? capitalizeWords(state?.scenario) : "N/A"}
          </div>
          <div className="flex justify-end gap-2">
            <Search
              label={`Category`}
              placeholder={"Search by Phone"}
              name={"Search"}
              searchCallbackfn={searchByUser}
              isSearching={loading}
              searchValue={searchValue || ""}
            />
          </div>
        </div>
        {paginatedData?.length ? (
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    {tableHeaders?.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-3 py-3 text-start"
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index) => (
                    <tr className="bg-white border-b" key={index}>
                      <td className="px-3 py-2 text-start">
                        {item?.screen_name
                          ? capitalizeWords(item?.screen_name)
                          : "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {item?.platform || "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {item?.phone || "Guest"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {item?.datetime
                          ? formatDate(Number(item?.datetime))
                          : "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        <div>
                          {item?.values && (
                            <>
                              {Object.entries(item.values)
                                .filter(([key]) => key !== "participants")
                                .map(([key, value]) => (
                                  <div key={key}>
                                    <strong>
                                      {capitalizeWords(key)
                                        .replace(/([A-Z])/g, " $1")
                                        .trim()}
                                      :
                                    </strong>{" "}
                                    {value || "N/A"}
                                  </div>
                                ))}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {totalPages > 1 && (
              <div className="flex justify-end pr-5 pt-4">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={handlePageChange}
                  isLoading={loading}
                />
              </div>
            )}
          </>
        ) : (
          <div className="text-center flex flex-col justify-center items-center pt-36">
            <TbServerOff className="text-[2rem] font-bold m-2" />
            No Data Found
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalyticsPage;
