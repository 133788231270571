import {
  GET_SERVER_REQUEST,
  GET_SERVER_SUCCESS,
  GET_SERVER_FAILURE,
} from "../../Utils/Constants";

export const getServerInfoRequest = (payload) => {
  return {
    type: GET_SERVER_REQUEST,
    payload: payload,
  };
};

export const getServerInfoSuccess = (data) => {
  return {
    type: GET_SERVER_SUCCESS,
    payload: data,
  };
};

export const getServerInfoFailure = (error) => {
  return {
    type: GET_SERVER_SUCCESS,
    payload: error,
  };
};
