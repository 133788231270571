import { put, call } from "redux-saga/effects";
import {
  getTopicsByCategoryIdSuccess,
  getTopicsByCategoryIdFailure,
} from "../Actions";
import { GetTopicByCatergoryIdAPI } from "../components/Config/Apiinterface";
import debounce from "../Utils/useDebounce";

function* GetTopicByCatergoryIdSaga(action) {
  const categoryId = action.payload.categoryId;
  const token = action.payload.token;
  const pageNo = action.payload.pageNo;
  const pageSize = 20;

  try {
    if (categoryId) {
      const topics = yield debounce(
        GetTopicByCatergoryIdAPI,
        token,
        categoryId,
        pageNo,
        pageSize
      );
      if (topics.statusCode === 403 || topics.statusCode === 401) {
        yield put(getTopicsByCategoryIdSuccess(topics));
      } else {
        yield put(getTopicsByCategoryIdSuccess(topics.data));
      }
    } else {
      return;
    }
  } catch (error) {
    yield put(getTopicsByCategoryIdFailure(error));
  }
}

export default GetTopicByCatergoryIdSaga;
