// src/redux/sagas.js

import { put, call } from "redux-saga/effects";
// import { UpdateAPI } from "../components/Config/Apiinterface";
import { putRequestAPI } from "../components/Config/Apiinterface";
import { updateQuizSuccess, updateQuizFailure } from "../Actions";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

export function* updateQuizSaga(action) {
  const path = "quiz";

  const token = action.payload.token;
  const body = action.payload.data;

  try {
    const response = yield debounce(putRequestAPI, token, body, path);
    yield put(updateQuizSuccess(response));
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    yield put(updateQuizFailure(error));
  }
}

export default updateQuizSaga;
