import {
  ADD_TOPIC_REQUEST,
  ADD_TOPIC_SUCCESS,
  ADD_TOPIC_FAILURE,
  ADD_TOPIC_RESET,
  LOGOUT,
} from "../Utils/Constants";

// Action Creators
export const AddTopicRequest = (payload) => {
  return {
    type: ADD_TOPIC_REQUEST,
    payload: payload,
  };
};

export const AddTopicSuccess = (data) => {
  return {
    type: ADD_TOPIC_SUCCESS,
    payload: data,
  };
};

export const AddTopicFailure = (error) => {
  return {
    type: ADD_TOPIC_FAILURE,
    payload: error,
  };
};

export const AddTopicReset = () => {
  return {
    type: ADD_TOPIC_RESET,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT,
  };
};
