import { call, put } from "redux-saga/effects";
import {
  getSelectQuestionByIdSuccess,
  getSelectQuestionByIdFailure,
} from "../Actions";
import { GetAPI } from "../components/Config/Apiinterface";
import debounce from "../Utils/useDebounce";

export function* selectQuestionByQuizIdSaga(action) {
  const quizId = action.payload.id;
  const pageNo = action.payload.pageNo;
  // const path = `admin/quiz/question/selected?quizId=${quizId}&pageNo=${pageNo}&pageSize=10`;
  const path = `admin/quiz/question/selected?quizId=${quizId}`;

  const token = action.payload.token;
  try {
    const responce = yield debounce(GetAPI, token, path);
    yield put(getSelectQuestionByIdSuccess(responce));
  } catch (err) {
    yield put(getSelectQuestionByIdFailure(err));
  }
}
