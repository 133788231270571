import { call, delay, put } from "redux-saga/effects";
import { AddUserSuccess, AddUserFailure } from "../../Actions";
import { addUserApi } from "../../components/Config/Apiinterface";
import { toast } from "react-toastify";

export function* addUserSaga(action) {
  const formdata = new FormData();
  formdata.append("profilePic", action.payload.profilePic[0]);

  const name = action.payload.name;
  const emailId = action.payload.emailId;
  const mobile = action.payload.mobile;
  const password = action.payload.password;
  const token = action.payload.token;
  const role = action.payload.role;


  try {
    const NewUser = yield call(
      addUserApi,
      token,
      name,
      emailId,
      mobile,
      password,
      role,
      formdata
    );
    yield delay(2000);
    yield put(AddUserSuccess(NewUser));
    if (NewUser.statusCode === 200 || NewUser.statusCode === 201) {
      toast.success(NewUser.message);
    } else {
      toast.info(NewUser.message);
    }
  } catch (error) {
    yield put(AddUserFailure(error));
  }
}
