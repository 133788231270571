import { UPDATE_SCHEDULE_NOTIFICATION_FAILURE, UPDATE_SCHEDULE_NOTIFICATION_REQUEST, UPDATE_SCHEDULE_NOTIFICATION_SUCCESS } from "../../Utils/Constants";


export const updateScheduleNotificationRequest = (payload) => {
  return {
    type: UPDATE_SCHEDULE_NOTIFICATION_REQUEST,
    payload: payload,
  };
};

export const updateScheduleNotificationSuccess = (data) => {
  return {
    type: UPDATE_SCHEDULE_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const updateScheduleNotificationFailure = (error) => {
  return {
    type: UPDATE_SCHEDULE_NOTIFICATION_FAILURE,
    payload: error,
  };
};
