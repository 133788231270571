import {
  GET_ACTIVITY_LOG_REQUEST,
  GET_ACTIVITY_LOG_SUCCESS,
  GET_ACTIVITY_LOG_FAILURE,
} from "../../Utils/Constants";

export const getActivityRequest = (payload) => {
  return {
    type: GET_ACTIVITY_LOG_REQUEST,
    payload: payload,
  };
};

export const getActivitySuccess = (data) => {
  return {
    type: GET_ACTIVITY_LOG_SUCCESS,
    payload: data,
  };
};

export const getActivityFailure = (error) => {
  return {
    type: GET_ACTIVITY_LOG_FAILURE,
    payload: error,
  };
};
