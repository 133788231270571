import {
  ADD_BALANCE_REQUEST,
  ADD_BALANCE_SUCCESS,
  ADD_BALANCE_FAILURE,
} from "../../Utils/Constants";

export const addBalanceRequest = (payload) => {
  return {
    type: ADD_BALANCE_REQUEST,
    payload: payload,
  };
};

export const addBalanceSuccess = (data) => {
  return {
    type: ADD_BALANCE_SUCCESS,
    payload: data,
  };
};

export const addBalanceFailure = (error) => {
  return {
    type: ADD_BALANCE_FAILURE,
    payload: error,
  };
};
