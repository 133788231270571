import {
  SELECT_QUESTION_SUCCESS,
  SELECT_QUESTION_REQUEST,
  SELECT_QUESTION_FAILURE,
} from "../Utils/Constants";

export const selectQuestionRequest = (payload) => {
  return {
    type: SELECT_QUESTION_REQUEST,
    payload: payload,
  };
};

export const selectQuestionSuccess = (data) => {
  return {
    type: SELECT_QUESTION_SUCCESS,
    payload: data,
  };
};

export const selectQuestionFailure = (error) => {
  return {
    type: SELECT_QUESTION_FAILURE,
    payload: error,
  };
};
