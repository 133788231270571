import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { deletePlayerFailure, deletePlayerSuccess } from "../../../Actions";
import { deleteData } from "../../../components/Config/Apiinterface";

// Worker saga for handling DeleteCtegory request
function* deletePlayerSaga(action) {
  const token = action.payload.token;
  const id = action.payload?.data?.id;
  const path = `admin/player/delete?id=${id}`;

  try {
    const response = yield call(deleteData, token, path);
    yield put(deletePlayerSuccess(response));
    if (response.statusCode === 403) {
      toast.error(response.message);
      return;
    }
    toast.success(response.message);
  } catch (error) {
    yield put(deletePlayerFailure(error));
  }
}

export default deletePlayerSaga;
