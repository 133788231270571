import {
  DELETE_QUIZ_FAILURE,
  DELETE_QUIZ_REQUEST,
  DELETE_QUIZ_RESET,
  DELETE_QUIZ_SUCCESS,
} from "../Utils/Constants";

const initialState = {
  deleteQuiz: {},
  loading: false,
  error: null,
};

const deleteQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_QUIZ_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_QUIZ_SUCCESS:
      return {
        ...state,
        deleteQuiz: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_QUIZ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_QUIZ_RESET:
      return {
        deleteQuiz: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default deleteQuizReducer;
