import {
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
} from  "../../Utils/Constants";

export const   resetPasswordRequest = (payload) => {
    return {
      type: RESET_PASSWORD_REQUEST,
      payload: payload,
    };
  };
  
  export const resetPasswordSuccess = (data) => {
    return {
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    };
  };
  
  export const resetPasswordFailure = (error) => {
    return {
      type: RESET_PASSWORD_FAILURE,
      payload: error,
    };
  };
  