import {
    UPDATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    LOGOUT,
    UPDATE_CATEGORY_RESET,
  } from "../Utils/Constants";
  
  // Action Creators
  export const UpdateCategoryRequest = (payload) => {
    return {
      type: UPDATE_CATEGORY_REQUEST,
      payload: payload,
    };
  };
  
  export const UpdateCategorySuccess = (data) => {
    return {
      type: UPDATE_CATEGORY_SUCCESS,
      payload: data,
    };
  };
  
  export const UpdateCategoryFailure = (error) => {
    return {
      type: UPDATE_CATEGORY_FAILURE,
      payload: error,
    };
  };

  export const UpdateCategoryReset = () => {
    return {
      type: UPDATE_CATEGORY_RESET
    }
  }
 
  export const logoutAction = () => {
    return {
      type: LOGOUT,
    };
  };
  