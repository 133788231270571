import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getQuestionsRequest } from "../Actions";
import { RandomlyData } from "../components/table/randomlyData";
import { SECRET_KEY } from "../Utils/Constants";
import CryptoJS from "crypto-js";
import { Pagination } from "../components/Pagination";
import Table2 from "../components/table/Table";
import { selectQuestionRequest } from "../Actions/SelectQuestionAction";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

const ReplaceQuestion = ({ questions, AddQuiz, quizProp, selectQuestion }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const deleteId = queryParams.get("deleteId");
  const newDataJSON = queryParams.get("newData");
  const newData = JSON.parse(decodeURIComponent(newDataJSON));
  const [questionsArray, setQuestionsArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedBoxes, setCheckedBoxes] = useState(newData);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const dispatch = useDispatch();
  const noOfQuestions = AddQuiz?.noOfQuestions;
  const topicId = AddQuiz?.topicId;
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    const body = {
      token,
      pageNo: currentPage,
      topicId: topicId,
    };
    dispatch(getQuestionsRequest(body));
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (questions?.loading) {
      setIsLoading(true);
    }
    if (questions?.error) {
      setIsLoading(false);
    }

    if (questions?.questions?.data?.questions) {
      setQuestionsArray(questions?.questions?.data?.questions);
      setIsLoading(false);
    }
    if (questions?.questions?.data?.totalPages) {
      setTotalPages(questions?.questions?.data.totalPages);
    }
  }, [questions]);

  const filteredQuestionsArray = questionsArray.filter((quest) => {
    return quest.topicId == AddQuiz?.topicId;
  });

  const handleCheckboxChange = (id) => {
    if (checkedBoxes.includes(id)) {
      setCheckedBoxes(checkedBoxes.filter((selectedId) => selectedId !== id));
    } else {
      setCheckedBoxes([...checkedBoxes, id]);
    }
  };

  const handleCheckboxId = (data) => {
    if (checkedBoxes.length === noOfQuestions) {
      const body = {
        data: {
          ...data,
          quizId: AddQuiz?.id,
          questionIds: checkedBoxes,
        },
        token: token,
      };
      dispatch(selectQuestionRequest(body));
      toast.success("Question Replace Successfully");
    } else {
      toast.info(
        "Number of selected questions is not the same as noOfQuestions"
      );
    }
  };

  useEffect(() => {
    if (deleteId) {
      setCheckedBoxes((prevCheckedBoxes) =>
        prevCheckedBoxes.filter((id) => id !== parseInt(deleteId))
      );
      navigate("/ReplaceQuestion");
    }
  }, [deleteId]);

  return (
    <>
      <div className="mt-[70px] p-4">
        {/* <div className="flex justify-start cursor-pointer">
          <span
            onClick={() => navigate(-2)}
            className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
          >
            <MdArrowBack className="text-base" /> Back
          </span>
        </div> */}
        <div className="text-lg font-bold mt-4">REPLACE QUESTIONS</div>
        <Table2
          itemsArray={questionsArray}
          checkedBoxes={checkedBoxes}
          handleCheckboxChange={handleCheckboxChange}
          handleCheckboxId={handleCheckboxId}
        />

        {totalPages > 1 && !searchValue && (
          <div className="flex justify-end pr-5 pt-4">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    questions: state?.questionsReducer,
    AddQuiz: state?.AddQuizReducer?.AddQuiz?.data,
    selectQuestion: state?.selectQuestionReducer?.selectQuestion,
  };
};

export default connect(mapStateToProps)(ReplaceQuestion);
