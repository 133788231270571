import React, { useState, useEffect } from "react";
import { useForm, useFormState } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import {
  getTopicsByCategoryIdRequest,
  CategoriesRequest,
  createQuestionRequest,
  updateQuestionRequest,
} from "../../Actions";
import { SECRET_KEY } from "../../Utils/Constants";
import { style } from "../../components/constant";
import { QuestionForm, SelectDropdown } from "../../layout";

import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { FiUpload } from "react-icons/fi";

function AddQuestion(props) {
  const location = useLocation();
  const item = location.state;
  const dispatch = useDispatch();
  const [topicId, setTopicId] = useState();
  const [questionLavel, setQuestionLavel] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [questionType, setQuestionType] = useState(null);
  const [isReload, setReload] = useState(false);
  const [flag, setFlag] = useState(true);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const navigate = useNavigate();

  const { topics } = props.TopicsByCategoryId.topics;
  const { categories } = props?.allCategoriesProp?.AllCategories;
  const form = useForm({
    defaultValues: async () => {
      return {
        descriptionEn: item?.descriptionEn,
        descriptionHi: item?.descriptionHi,
        optionsEn: {
          A: item?.optionsEn?.A,
          B: item?.optionsEn?.B,
          C: item?.optionsEn?.C,
          D: item?.optionsEn?.D,
        },
        optionsHi: {
          A: item?.optionsHi?.A,
          B: item?.optionsHi?.B,
          C: item?.optionsHi?.C,
          D: item?.optionsHi?.D,
        },
        answerEn: {
          option: item?.answerEn?.option,
        },
        answerHi: {
          option: item?.answerHi?.option,
        },
      };
    },
  });

  const { register, handleSubmit, setValue, control, watch, reset } = form;
  const { error } = useFormState({ control });

  useEffect(() => {
    dispatch(CategoriesRequest({ token }));
    // eslint-disable-next-line
  }, []);

  const handleSortValues = (id, value) => {
    if (id === "categoryOptions") {
      getTopic(value);
    } else if (id === "topicOptions") {
      setTopicId(value);
    } else if (id === "levelOptions") {
      setQuestionLavel(value);
    } else if (id === "questionType") {
      setQuestionType(
        (value === "true" && true) || (value === "false" && false)
      );
    }
  };

  const getTopic = (cat) => {
    const body = {
      token: token,
      categoryId: cat,
    };
    dispatch(getTopicsByCategoryIdRequest(body));
  };

  const onSubmit = async (data) => {
    let keys = Object.keys(data.optionsEn);
    let English;
    let Hindi;
    if (
      // data.answerEn.option === data.answerHi.option &&
      data.answerHi.option ||
      data.answerHi.option
    ) {
      if (questionLavel && topicId) {
        for (let i = 0; i < keys.length; i++) {
          if (
            keys[i] === data.answerEn.option ||
            keys[i] === data.answerHi.option
          ) {
            setValue("answerEn.answer", data.optionsEn[data.answerEn.option]);
            setValue("answerHi.answer", data.optionsEn[data.answerHi.option]);
            English = data.optionsEn[data.answerEn.option];
            Hindi = data.optionsEn[data.answerHi.option];
          }
        }

        if (questionType === null) {
          toast.info("Please select the question type");
          return;
        }

        history("/questionPool");

        const formData = {
          ...data,
          id: item?.id ? item?.id : 0,
          topicId: topicId,
          level: questionLavel,
          isFree: questionType,
          answerHi: {
            ...data.answerHi,
            answer: Hindi,
          },
          answerEn: {
            ...data.answerEn,
            answer: English,
          },
        };
        const body = {
          token: token,
          data: formData,
        };
        // setReload(true);

        setFlag(false);

        if (item?.id) {
          dispatch(updateQuestionRequest(body));
          reset();
        } else {
          dispatch(createQuestionRequest(body));
          reset();
        }
      } else if (!questionLavel && !topicId) {
        toast.info("Please select the Topic and Question level.");
      } else if (!topicId) {
        toast.info("Please select the Topic.");
      } else if (!questionLavel) {
        toast.info("Please select the Question level.");
      }
    } else {
      toast.info("Please Select Correct Answer.");
    }
  };

  useEffect(() => {
    if (item?.topicId && item?.level) {
      setTopicId(item?.topicId);
      setQuestionLavel(item?.level);
    }
  }, [item]);
  useEffect(() => {
    if (isReload) {
      window.location.reload();
      setReload(false);
    }
    // eslint-disable-next-line
  }, [props?.newQuiestion]);

  const history = useNavigate();

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
        <div className="bg-white p-4 shadow-md rounded-lg">
          <div className="flex justify-between">
            <div>
              <div className="flex justify-start cursor-pointer">
                <span
                  onClick={() => navigate(-1)}
                  className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border-blue-400 inline-flex items-center justify-center"
                >
                  <MdArrowBack className="text-base" /> Back
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mt-4">
            <div className="font-bold uppercase text-lg text-center">
              Add Questions Form
            </div>
          </div>
          <form className="space-y-6 mt-5" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-4 mb-8 sm:grid-cols-2 lg:grid-cols-4">
              <SelectDropdown
                categories={categories}
                topics={topics}
                handleSortValues={handleSortValues}
                item={item}
              />
            </div>
            <div className="space-y-6">
              <QuestionForm
                register={register}
                watch={watch}
                setValue={setValue}
                error={error}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                flag={flag}
                setFlag={setFlag}
              />
            </div>

            <button type="submit" className={style.buttonClass}>
              {/* change */}
              <span className=" md:inline-block">{"Save Question"}</span>
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    TopicsByCategoryId: state?.TopicByCategoryIdReducer,
    allCategoriesProp: state?.AllCategoriesReducer,
    newQuiestion: state?.questionsReducer,
  };
};

export default connect(mapStateToProps)(AddQuestion);
