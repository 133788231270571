import React, { useEffect, useState } from "react";
import { Search } from "../Search";
import { Pagination } from "../Pagination";
import madelSvg from "../../assets/svg/medal 1.svg";
import avtar from "../../assets/images/download.png";
import { MdRefresh } from "react-icons/md";
import {
  getLeaderbordUserQuestionRequest,
  getLeaderbordUserQuestionReset,
} from "../../Actions";
import { useDispatch } from "react-redux";
import { TbServerOff } from "react-icons/tb";
import { checkAccess } from "../../Utils/RoleBasedRoute";

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();

  const {
    itemsArray,
    getSearchLeaderboard,
    searchLeaderboard,
    // selectQuestion,
    totalPages,
    currentPage,
    setCurrentPage,
    isLoading,
    searchValue,
    isLive = { isLive },
    getData,
    // handleLeaderboardUserQuestion,
    navigate,
    id,
    token,
    setSearchValue,
    userQuestions,
  } = props;

  const handleLeaderboardUserQuestion = (userId) => {
    const body = {
      token,
      id: id,
      userId: userId,
    };
    // if (userId) {
    dispatch(getLeaderbordUserQuestionRequest(body));
    // }
  };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const tableHead = [
    "Participant Name",
    "Points",
    "Rank",
    "Cash Won",
    "Questions",
  ];

  const handleViewQuestions = (userId) => {
    handleLeaderboardUserQuestion(userId); // Fetch questions for the selected user
    navigate(`/UserQuestions`);
  };

  useEffect(() => {
    dispatch(getLeaderbordUserQuestionReset());
  }, []);

  const calculateTime = (data) => {
    if (data < 1) {
      return "-";
    }
    if (data === 1) {
      return "1 Sec";
    }
    if (data > 1 && data < 60) {
      return `${data} Secs`;
    }
    const minutes = Math.floor(data / 60);
    const seconds = data % 60;
    if (seconds === 0) {
      return `${minutes} Mins`;
    }
    return `${minutes}:${seconds} Mins`;
  };

  return (
    <div className="w-full">
      <div>
        <div className="flex">
          <div className="border border-gray-400 p-2 rounded-lg w-[250px]">
            <button
              className={`w-full p-2.5 rounded-lg ${
                activeTab === 1 ? "text-white bg-[#182241]" : "text-[#182241]"
              }`}
              onClick={() => handleTabClick(1)}
            >
              <div className="flex text-center gap-2">
                <div>
                  <img src={madelSvg} />
                </div>
                <div>LEADERBOARD</div>
              </div>
            </button>
          </div>
        </div>
      </div>

      {activeTab === 1 && (
        <>
          <div className="bg-white shadow-md rounded-lg h-[100%] overflow-hidden mt-4">
            <button
              onClick={() => {
                getData();
                setSearchValue("");
              }}
              className="float-right mr-4 "
            >
              <MdRefresh className="w-6 h-6 mt-2" />
            </button>
            <div className="">
              <div className="lg:flex w-full lg:justify-between items-center">
                <div className="mb-4 lg:mb-0 mt-2 pl-2">
                  <p className="text-2xl font-bold">LEADERBOARD</p>
                </div>
                <div className="w-full lg:w-1/3 pr-2">
                  <Search
                    label={`Category`}
                    placeholder={"Enter Participants Name"}
                    name={"Search"}
                    searchCallbackfn={getSearchLeaderboard}
                    isSearching={searchLeaderboard?.loading}
                    searchValue={searchValue}
                  />
                </div>
              </div>
              {itemsArray?.length ? (
                <table className="w-full mt-2 text-sm text-left rtl:text-right text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      {tableHead?.map((item, i) => (
                        <th scope="col" className="px-6 py-3" key={i}>
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {itemsArray.map((item, index) => (
                      <>
                        {item.rank === 0 ? (
                          <tr
                            className="bg-white border-b hover:bg-gray-50"
                            key={index}
                          >
                            <th
                              scope="row"
                              className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <img
                                className="w-10 h-10 rounded-full"
                                src={item.profilePic ? item.profilePic : avtar}
                                alt="Jese image"
                              />
                              <div className="ps-3">
                                <div
                                  className={`text-base font-semibold hover:text-blue-800 ${
                                    !checkAccess("", "")
                                      ? "accessDenied"
                                      : "hover:cursor-pointer"
                                  }`}
                                  onClick={() => {
                                    if (checkAccess("PLAYER", "R")) {
                                      navigate(
                                        {
                                          pathname: "/viewPlayers",
                                        },
                                        { state: { ...item, id: item.userId } }
                                      );
                                    }
                                  }}
                                >
                                  {item.userName}
                                </div>
                                <div className="font-normal text-gray-500">
                                  {/* bonnie@flowbite.com */}
                                </div>
                              </div>
                            </th>
                            <td className="px-6 py-4 pl-10">0</td>
                            <td className="px-6 py-4 pl-10">--</td>
                            <td className="px-6 py-4 pl-10">Rs. 0 </td>
                            <td>
                              <button
                                className={`text-blue-500 ${
                                  !checkAccess("QUESTIONPOOL", "R")
                                    ? "accessDenied"
                                    : "hover:cursor-pointer"
                                }`}
                                disabled={!checkAccess("QUESTIONPOOL", "R")}
                                onClick={() => {
                                  handleViewQuestions(item.userId);
                                }}
                              >
                                View Questions
                              </button>
                            </td>
                          </tr>
                        ) : (
                          <tr
                            className="bg-white border-b hover:bg-gray-50"
                            key={index}
                          >
                            <th
                              scope="row"
                              className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <img
                                className="w-10 h-10 rounded-full"
                                src={item.profilePic ? item.profilePic : avtar}
                                alt="Jese image"
                              />
                              <div className="ps-3">
                                <div
                                  className={`text-base font-semibold hover:text-blue-800 ${
                                    !checkAccess("", "")
                                      ? "accessDenied"
                                      : "hover:cursor-pointer"
                                  }`}
                                  onClick={() => {
                                    if (checkAccess("PLAYER", "R")) {
                                      navigate(
                                        {
                                          pathname: "/viewPlayers",
                                        },
                                        { state: { ...item, id: item.userId } }
                                      );
                                    }
                                  }}
                                >
                                  {item.userName}
                                </div>
                                <div className="font-normal text-gray-500">
                                  {/* bonnie@flowbite.com */}
                                </div>
                              </div>
                            </th>
                            <td className="px-6 py-4 pl-10">
                              <div>Pts. {item.points}</div>
                              <div className="text-sm">
                                {calculateTime(item?.completedTime)}
                              </div>
                            </td>
                            <td className="px-6 py-4 pl-10">{item.rank}</td>
                            <td className="px-6 py-4 pl-10">
                              Rs. {item.cashWon}
                            </td>
                            <td>
                              <button
                                disabled={!checkAccess("QUESTIONPOOL", "R")}
                                className={`text-blue-500 ${
                                  !checkAccess("QUESTIONPOOL", "R")
                                    ? "accessDenied"
                                    : "hover:cursor-pointer"
                                }`}
                                onClick={() => {
                                  handleViewQuestions(item.userId);
                                }}
                              >
                                View Questions
                              </button>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center m-8 flex flex-col justify-center items-center">
                  <TbServerOff className="text-[2rem] font-bold  m-2" />
                  Data Not Found
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between h-auto">
            {totalPages > 1 && !searchValue && (
              <div className="flex justify-end pr-5 pt-4">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isLoading={isLoading}
                />
              </div>
            )}
          </div>
          {/* {openRandomlyScreen && <RandomlyData item={userQuestionsArray} />} */}
        </>
      )}
    </div>
  );
};

export default Tabs;
