import axios from "axios";
import {
  APPLICATION_ENVIRONMENT,
  PRODUCTION_URL,
  STAGING_URL,
} from "../../Utils/Constants";

export const getBaseUrl = function () {
  return APPLICATION_ENVIRONMENT === "STAGING"
    ? axios.create({
        baseURL: STAGING_URL,
      })
    : axios.create({
        baseURL: PRODUCTION_URL,
      });
};

export const getUrlString = () => {
  return APPLICATION_ENVIRONMENT === "STAGING" ? STAGING_URL : PRODUCTION_URL;
};
