import { call, cancel, delay } from "redux-saga/effects";
import { delayTime } from "./Constants";
// Debounce function utility
function* debounce(fn, ...args) {
  let task = null;

  try {
    // Cancel previous task if it exists
    if (task) {
      yield cancel(task);
    }

    // Start a new task with delay (debouncing)
    task = yield delay(delayTime);

    // Call the provided function with arguments after delay
    const response = yield call(fn, ...args);
    return response;
  } catch (err) {
    console.error(err);
    return err;
  } finally {
    task = null; // Reset task after execution
  }
}

export default debounce;
