import {
  QUIZ_REQUEST,
  QUIZ_SUCCESS,
  QUIZ_FAILURE,
  QUIZ_RESET,
} from "../Utils/Constants";

export const quizRequest = (payload) => {
  return {
    type: QUIZ_REQUEST,
    payload: payload,
  };
};

export const quizSuccess = (data) => {
  return {
    type: QUIZ_SUCCESS,
    payload: data,
  };
};

export const quizFailure = (error) => {
  return {
    type: QUIZ_FAILURE,
    payload: error,
  };
};

export const quizReset = (data) => {
  return {
    type: QUIZ_RESET,
    payload : data,
  };
};
