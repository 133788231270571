import { useDispatch } from "react-redux";
import {
  AddTopicReset,
  DeleteTopicReset,
  UpdateTopicReset,
} from "../../Actions";
import { toast } from "react-toastify";

const useTopicAlerts = () => {
  const dispatch = useDispatch();

  return {
    showAlert: (addTopic, updatedTopic, deleteTopic) => {
      if (addTopic?.topic?.statusCode === 201) {
        toast.success("Topic Added Successfully");
        dispatch(AddTopicReset());
      }
      if (updatedTopic?.updateTopic?.statusCode === 200) {
        toast.success("Topic Updated Successfully");
        dispatch(UpdateTopicReset());
      }
      if (deleteTopic?.topic?.statusCode === 200) {
        toast.success("Topic Deleted Successfully");
        dispatch(DeleteTopicReset());
      }
      if (addTopic.error || updatedTopic.error || deleteTopic.error) {
        toast.error("Something Went Wrong!!! Please Try Again");
      }
    },
  };
};

export default useTopicAlerts;
