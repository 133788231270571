import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../Utils/Constants";

// Action Creators
export const loginRequest = (email, password) => {
  return {
    type: LOGIN_REQUEST,
    payload: { email, password },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};
export const logoutAction = () => {
  return {
    type: LOGOUT,
  };
};
