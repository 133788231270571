import {
  ADD_CATEGORY_FAILURE,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_RESET,
  ADD_CATEGORY_SUCCESS,
  LOGOUT,
} from "../Utils/Constants";

// Initial State
const initialState = {
  newCategory: {},
  loading: false,
  error: null,
};

// Reducer
const AddCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        newCategory: action.payload,
        loading: false,
        error: null,
      };
    case ADD_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_CATEGORY_RESET:
      return {
        newCategory: {},
        loading: false,
        error: null,
      };
    case LOGOUT:
      return {
        newCategory: null,
      };
    default:
      return state;
  }
};

export default AddCategoryReducer;
