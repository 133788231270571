import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  sendSheduledNotificationFailure,
  sendSheduledNotificationSuccess,
} from "../../Actions";
import { postFormData } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";

export function* sendSheduledNotificationSaga(action) {
  const path = "/notification/schedule";
  const token = action.payload.token;
  const body = action.payload.data;
  try {
    const result = yield call(debounce, postFormData, path, body, token);  
    
    yield put(sendSheduledNotificationSuccess(result));
    if (result.statusCode === 201 || 200) {
      toast.success(result.message);
    } else {
      toast.warning(result.message);
    }
  } catch (err) {
    yield put(sendSheduledNotificationFailure(err));
    toast.error(err);
  }
}
