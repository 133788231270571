import { put, call } from "redux-saga/effects";
import { GetAPI } from "../components/Config/Apiinterface";
import { getContactUSAllSuccess, getContactUSAllFailure } from "../Actions";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Topics request
function* contactUsAllSaga(action) {
  const token = action.payload.token;
  const pageNo = action.payload.pageNo;
  const searchBy = action.payload.isSearchDropdown;
  const searchValue = action.payload.searchBy;
  const sortBy = action.payload.sortValue;

  function buildURL(pageNo, pageSize, sortBy, searchBy, searchValue) {
    let url;

    if (searchValue) {
      url = `admin/contactUs/all?pageNo=${pageNo}&pageSize=${pageSize}`;
    } else {
      url = `admin/contactUs/all?pageNo=${pageNo}&pageSize=${pageSize}`;
    }

    let hasQueryParams = false;

    if (sortBy) {
      url += hasQueryParams ? "?" : "&";
      url += "filterBy=" + encodeURIComponent(sortBy);
      hasQueryParams = true;
    }

    if (searchBy) {
      url += hasQueryParams ? "?" : "&";
      url += "searchBy=" + encodeURIComponent(searchBy);
      hasQueryParams = true;
    }
    // }

    if (searchValue) {
      url += hasQueryParams ? "&" : "?";
      url += "searchValue=" + encodeURIComponent(searchValue);
      hasQueryParams = true;
    }

    return url;
  }

  const newurl = buildURL(pageNo, 20, sortBy, searchBy, searchValue);
  // ...

  const path = newurl;
  try {
    const result = yield debounce(GetAPI, token, path);
    yield put(getContactUSAllSuccess(result));
    if (result.statusCode === 200) {
      // toast.success(result.message);
    } else {
      toast.warning(result.message);
    }
  } catch (error) {
    yield put(getContactUSAllFailure(error));
  }
}

export default contactUsAllSaga;
