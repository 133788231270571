import {
  SEARCH_QUIZ_REQUEST,
  SEARCH_QUIZ_SUCCESS,
  SEARCH_QUIZ_FAILURE,
  SEARCH_QUIZ_RESET,
} from "../Utils/Constants";

export const searchQuizRequest = (payload) => {
    return {
        type: SEARCH_QUIZ_REQUEST,
        payload: payload
    }
}

export const searchQuizSuccess = (data) => {
    return {
        type: SEARCH_QUIZ_SUCCESS,
        payload: data
    }
}

export const searchQuizFailure = (error) => {
    return {
        type: SEARCH_QUIZ_FAILURE,
        payload: error
    }
}

export const searchQuizReset = () => {
    return {
        type : SEARCH_QUIZ_RESET
    }
}