import { useDispatch } from "react-redux";
import { deleteQuizReset } from "../../Actions";
import { toast } from "react-toastify";

const useQuizAlerts = () => {
  const dispatch = useDispatch();

  return {
    showAlert: (deleteQuizProp) => {
      if (deleteQuizProp?.deleteQuiz?.statusCode === 200) {
        toast.success("Quiz Deleted Successfully");
        dispatch(deleteQuizReset());
      }
      if (deleteQuizProp?.error) {
        toast.error("Something Went Wrong!!! Please Try Again");
      }
    },
  };
};

export default useQuizAlerts;
