import { put, call } from "redux-saga/effects";
import { GetAPI } from "../components/Config/Apiinterface";
import { CategoriesFailure, CategoriesSuccess } from "../Actions";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Categories request
function* CategoriesSagas(action) {
  const token = action.payload.token;
  const pageNo = action.payload.pageNo;
  let path;

  if (pageNo) {
    path = `admin/category/all?pageNo=${pageNo}&pageSize=${20}&sortBy=${"createDate"}&sortOrder=${false}`;
  } else {
    path = `admin/category/all?sortBy=${"createDate"}&sortOrder=${false}`;
  }

  try {
    const AllCategories = yield debounce(GetAPI, token, path);
    if (AllCategories.statusCode === 403 || AllCategories.statusCode === 401) {
      yield put(CategoriesSuccess(AllCategories));
    } else {
      yield put(CategoriesSuccess(AllCategories.data));
    }
  } catch (error) {
    yield put(CategoriesFailure(error));
  }
}

export default CategoriesSagas;
// admin/categories/all?pageNo=1&pageSize=2&sortBy=name&sortOrder=true
