import {
  TOPIC_BY_CATEGORYID_REQUEST,
  TOPIC_BY_CATEGORYID_SUCCESS,
  TOPIC_BY_CATEGORYID_FAILURE,
  TOPIC_BY_CATEGORYID_RESET,
} from "../Utils/Constants";

const initialState = {
  topics: {},
  loading: false,
  error: null,
};

const TopicByCategoryIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOPIC_BY_CATEGORYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case TOPIC_BY_CATEGORYID_SUCCESS:
      return {
        ...state,
        topics: action.payload,
        loading: false,
        error: null,
      };

    case TOPIC_BY_CATEGORYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TOPIC_BY_CATEGORYID_RESET:
      return {
        topics: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default TopicByCategoryIdReducer;
