import {
  GET_USER_ALL_REQUEST,
  GET_USER_ALL_SUCCESS,
  GET_USER_ALL_FAILURE,
  GET_PLAYER_ALL_REQUEST,
  GET_PLAYER_ALL_SUCCESS,
  GET_PLAYER_ALL_FAILURE,
} from "../../../Utils/Constants";

export const getPlayerAllRequest = (payload) => {
  return {
    type: GET_PLAYER_ALL_REQUEST,
    payload: payload,
  };
};

export const getPlayerAllSuccess = (data) => {
  return {
    type: GET_PLAYER_ALL_SUCCESS,
    payload: data,
  };
};

export const getPlayerAllFailure = (error) => {
  return {
    type: GET_PLAYER_ALL_FAILURE,
    payload: error,
  };
};

export const getUserAllRequest = (payload) => {
  return {
    type: GET_USER_ALL_REQUEST,
    payload: payload,
  };
};

export const getUserAllSuccess = (data) => {
  return {
    type: GET_USER_ALL_SUCCESS,
    payload: data,
  };
};

export const getUserAllFailure = (error) => {
  return {
    type: GET_USER_ALL_FAILURE,
    payload: error,
  };
};
