import { put } from "redux-saga/effects";
import { postManageFailure, postManageSuccess } from "../../Actions";
import { putRequestAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";
import { toast } from "react-toastify";

export function* postManageAccessSaga(action) {
  const { data, token } = action.payload;
  const path = `privilege/user/update`;

  try {
    const response = yield debounce(putRequestAPI, token, data, path);
    toast.info(response.message);
    yield put(postManageSuccess(response));
  } catch (error) {
    yield put(postManageFailure(error));
  }
}
