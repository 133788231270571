import { call, put } from "redux-saga/effects";
import { getMenuFailure, getMenuSuccess } from "../../Actions";
import { GetAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";

export function* menuSaga(action) {
  const token = action.payload.token;
  const path = `admin/getMenu`;

  try {
    const response = yield debounce(GetAPI, token, path);
    yield put(getMenuSuccess(response));
  } catch (error) {
    yield put(getMenuFailure(error));
  }
}
