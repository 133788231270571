import {
    CANCEL_QUIZ_REQUEST,
    CANCEL_QUIZ_SUCCESS,
    CANCEL_QUIZ_FAILURE,
    CANCEL_QUIZ_RESET
  } from "../Utils/Constants";
  
  // Action Creators
  
  export const cancelQuizRequiest = (payload) => {
    return {
      type: CANCEL_QUIZ_REQUEST,
      payload: payload,
    };
  };
  
  export const cancelQuizSuccess = (data) => {
    return {
      type: CANCEL_QUIZ_SUCCESS,
      payload: data,
    };
  };
  
  export const cancelQuizFailure = (error) => {
    return {
      type: CANCEL_QUIZ_FAILURE,
      payload: error,
    };
  };
  
  export const cancleQuizReset = (data) => {
    return {
      type: CANCEL_QUIZ_RESET,
      payload : data,
    };
  };