import {
    ADD_CATEGORY_FAILURE,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_RESET,
    ADD_CATEGORY_SUCCESS,
    LOGOUT,
  } from "../Utils/Constants";
  
  // Action Creators
  export const AddCategoriesRequest = (payload) => {
    return {
      type: ADD_CATEGORY_REQUEST,
      payload: payload,
    };
  };
  
  export const AddCategoriesSuccess = (data) => {
    return {
      type: ADD_CATEGORY_SUCCESS,
      payload: data,
    };
  };
  
  export const AddCategoriesFailure = (error) => {
    return {
      type: ADD_CATEGORY_FAILURE,
      payload: error,
    };
  };

  export const AddCategoriesReset = () => {
    return {
      type: ADD_CATEGORY_RESET
    }
  }
  export const logoutAction = () => {
    return {
      type: LOGOUT,
    };
  };
  
