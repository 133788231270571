import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { MdDelete, MdRefresh } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { Search } from "../components/Search";
import { Pagination } from "../components/Pagination";
import { Select } from "../components/select/SelectNew";
import { Button } from "../components/ui-elements/Button";
import { SpinnerLoader } from "../components/Loaders/SpinnerLoader";
import { AddNewModal } from "../components/modal/AddNewModal";
import { DeleteModal } from "../components/modal/DeleteModal";
import useQuizAlerts from "../components/alertHelper/useQuizzAlerts";
import {
  CategoriesRequest,
  TopicsRequest,
  deleteQuizRequest,
  quizRequest,
  searchQuizRequest,
  activeQuizStatus,
  getTopicsByCategoryIdRequest,
  topicsByCategoryIdReset,
  CategoriesReset,
} from "../Actions";
import { SECRET_KEY } from "../Utils/Constants";
import CryptoJS from "crypto-js";
import cashQuiz from "../assets/images/cashQuiz.svg";
import freeQuiz from "../assets/images/freeQuiz.svg";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router";
import { cancleQuizReset } from "../Actions/CancelQuizAction";
import {
  notificationLocalReset,
  resetQuestionLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../components/constant";
import { getUserByIdEmpty } from "../Actions/User/getUserByIdAction";
import Loading from "../components/loading";
import { TbServerOff } from "react-icons/tb";
import { checkAccess } from "../Utils/RoleBasedRoute";
import DateRangePicker from "../components/DateRangePicker";

const Quizzes = ({
  quizProp,
  allTopicsProp,
  allCategoriesProp,
  searchQuizProp,
  deleteQuizProp,
  quizStatus,
  TopicsByCategoryId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const [exportDate, setExportDate] = useState({
    startDate: localStorage.getItem("quizStartDate") || "",
    endDate: localStorage.getItem("quizEndDate") || "",
  });
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("quizCurrentPage")) || 1
  );
  const [quizArray, setQuizArray] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchValue, setSearchValue] = useState(
    localStorage.getItem("quizSearchValue") || ""
  );
  const [sortValue, setSortValue] = useState(
    localStorage.getItem("quizSort") || ""
  );
  const [filterByTopic, setFilterByTopic] = useState(
    localStorage.getItem("quizTopicFilter") || ""
  );
  const [filterByCategory, setFilterByCategory] = useState(
    localStorage.getItem("quizCatagoryFilter") || ""
  );
  const [isSortOrder, setIsSortOrder] = useState(
    localStorage.getItem("quizSortOrder") || true
  );
  const [openEditQuizModal, setOpenEditQuizModal] = useState(false);
  const [openDeleteQuizModal, setOpenDeleteQuizModal] = useState(false);
  const [deleteQuizData, setDeleteQuizData] = useState({});
  const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showFilterArea, setShowFilterArea] = useState(true);
  const [showInitial, setShowInitial] = useState(false);
  const [resetDateRange, setResetDateRange] = useState(false);
  const [filter, setFilter] = useState(
    localStorage.getItem("quizFilter") || ""
  );
  const [subFilter, setSubFilter] = useState(
    localStorage.getItem("quizSubFilter") || ""
  );
  const timerRef = useRef(null);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const tableHeaders = [
    "Id",
    "Start Date/Time",
    "Category",
    "Spots",
    "Winning Amount",
    "Fees",
    "Status",
    "Details",
  ];

  const dropdownOptions = [
    {
      id: "",
      name: "Recent",
    },
    {
      id: "totalWinnings",
      name: "Winning Prize",
    },
    {
      id: "entryFees",
      name: "Entry Fees",
    },
    {
      id: "createDate",
      name: "Created Date",
    },
    {
      id: "startTime",
      name: "Start Time",
    },
  ];

  const sortOrder = [
    {
      id: true,
      name: "Ascending Order",
    },
    {
      id: false,
      name: "Descending Order ",
    },
  ];

  const filterData = [
    {
      id: "FREE",
      name: "Free quiz",
    },
    {
      id: "CASH",
      name: "Cash quiz",
    },
  ];

  const filterQuizData = [
    {
      id: "LIVE",
      name: "Live quiz",
    },

    {
      id: "UPCOMING",
      name: "Upcoming quiz",
    },
    {
      id: "COMPLETED",
      name: "Completed quiz",
    },
    {
      id: "CANCELLED",
      name: "Cancelled quiz",
    },
  ];

  const categoryOptions = allCategories?.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  const topic = TopicsByCategoryId?.topics?.topics;

  const getQuizData = () => {
    const body = {
      token,
      pageNo: currentPage,
      sortValue,
      filterByCategory,
      filterByTopic,
      isSortOrder,
      startDate: exportDate.startDate,
      endDate: exportDate.endDate,
    };
    dispatch(quizRequest(body));
    setShowInitial(true);
  };

  useEffect(() => {
    getQuizData();
    resetQuestionLocalFilters();
    transactionResetLocalData();
    resetUserLocalFilters();
    notificationLocalReset();
    dispatch(getUserByIdEmpty());
    return () => {
      dispatch(topicsByCategoryIdReset());
      dispatch(cancleQuizReset());
    };
  }, []);

  const quizDown = localStorage.getItem("quizDrop");

  const handlePageChange = (pageNumber) => {
    localStorage.setItem("quizCurrentPage", pageNumber);
    setCurrentPage(pageNumber);

    setSearchValue(localStorage.getItem("quizSearchValue") || "");
  };

  useEffect(() => {
    if (quizProp.loading || searchQuizProp.loading) {
      setIsLoading(true);
      setError(false);
    }
    if (quizProp.error || searchQuizProp.error) {
      setError(true);
      setIsLoading(false);
    }

    if (quizProp?.quiz?.data?.quizzes) {
      setQuizArray(quizProp?.quiz?.data?.quizzes);
      setError(false);
      setIsLoading(false);
    }

    if (searchValue && searchQuizProp?.searchQuiz?.data) {
      setQuizArray([searchQuizProp?.searchQuiz?.data]);
      setError(false);
      setIsLoading(false);
    }

    if (
      searchValue &&
      (searchQuizProp?.searchQuiz?.data?.statusCode === 404 ||
        searchQuizProp?.searchQuiz?.data?.statusCode === 400)
    ) {
      setQuizArray([]);
      setError(false);
      setIsLoading(false);
    }

    if (quizProp?.quiz?.data?.totalPages) {
      setTotalPages(quizProp?.quiz?.data?.totalPages);
    }
    setIsSubmittingFilter(false);
    setShowInitial(true);
    // eslint-disable-next-line
  }, [quizProp, searchQuizProp]);

  useEffect(() => {
    const topics = allTopicsProp?.Topics?.topics;
    topics && setAllTopics(topics);
  }, [allTopicsProp]);

  useEffect(() => {
    const categories = allCategoriesProp?.AllCategories?.categories;
    categories && setAllCategories(categories);
  }, [allCategoriesProp]);

  useEffect(() => {
    alertHook.showAlert(deleteQuizProp);
    // eslint-disable-next-line
  }, [deleteQuizProp]);

  const alertHook = useQuizAlerts();

  useEffect(() => {
    localStorage.setItem("quizSearchValue", searchValue);
    localStorage.setItem("quizCurrentPage", currentPage);
  }, [searchValue, currentPage]);

  const searchByQuizId = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event?.target.value);
      localStorage.setItem("quizSearchValue", event?.target.value);
    }
    const body = {
      token,
      quizId: searchParam || event?.target.value,
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(searchQuizRequest(body));
    }, 10);
  };

  const getCategoryName = (currentId) => {
    const currentCategory = allCategories?.find(
      (elem) => elem.id === currentId
    );
    return currentCategory?.name;
  };

  const getTopicName = (currentId) => {
    const currentTopic = allTopics?.find((elem) => elem.id === currentId);
    return currentTopic?.name;
  };

  const getTopicsByCategoryId = (cat) => {
    const body = {
      token: token,
      categoryId: cat,
    };
    dispatch(getTopicsByCategoryIdRequest(body));
  };

  const handleSortValues = (id, value) => {
    if (id === "filterOptions") {
      setFilter(value);
      localStorage.setItem("quizFilter", value);
      localStorage.setItem("quizDrop", true);
    }
    if (id === "filterStatusOptions") {
      setSubFilter(value);
      localStorage.setItem("quizSubFilter", value);
      localStorage.setItem("quizDrop", true);
    }
    if (id === "dropdownOptions") {
      setSortValue(value);
      localStorage.setItem("quizSort", value);
      localStorage.setItem("quizDrop", true);
    }
    if (id === "categoryOptions") {
      getTopicsByCategoryId(value);
      setFilterByCategory(value);
      localStorage.setItem("quizCatagoryFilter", value);
      localStorage.setItem("quizDrop", true);
    }
    if (id === "topicOptions") {
      setFilterByTopic(value);
      localStorage.setItem("quizTopicFilter", value);
    }
    if (id === "sortOrder") {
      setIsSortOrder(value);
      localStorage.setItem("quizSortOrder", value);
    }
    if (id === "filterDate") {
      localStorage.setItem("quizStartDate", exportDate?.startDate);
      localStorage.setItem("quizEndDate", exportDate?.endDate);
    }
  };

  useEffect(() => {
    setFilter(localStorage.getItem("quizFilter"));
    setFilter(localStorage.getItem("quizSubFilter"));
    setSortValue(localStorage.getItem("quizSort"));
    setIsSortOrder(localStorage.getItem("quizSortOrder"));
    setFilterByCategory(localStorage.getItem("quizCatagoryFilter"));
    setFilterByTopic(localStorage.getItem("quizTopicFilter"));
    setExportDate({
      startDate: localStorage.getItem("quizStartDate"),
      endDate: localStorage.getItem("quizEndDate"),
    });
   
  }, []);

  const handleSubmitFilter = () => {
    setIsSubmittingFilter(true);
    const params = {
      token,
      filter,
      subFilter,
      pageNo: currentPage,
      sortValue,
      filterByCategory,
      filterByTopic,
      isSortOrder,
      startDate: exportDate.startDate,
      endDate: exportDate.endDate,
    };

    const body = {
      ...params,
      ...(filter && { filter }),
      ...(subFilter && { subFilter }),
      ...(sortValue && { sortValue }),
      ...(filterByCategory && { filterByCategory }),
      ...(filterByTopic && { filterByTopic }),
      ...(isSortOrder && { isSortOrder }),
      ...(exportDate.startDate && { startDate: exportDate.startDate }),
      ...(exportDate.endDate && { endDate: exportDate.endDate }),
    };
    dispatch(quizRequest(body));
    setShowInitial(false);
  };
  //  TODO: adjust bellow aparamrters
  useEffect(() => {
    getQuizData();
    if (checkAccess("TOPIC", "R")) {
      dispatch(TopicsRequest({ token }));
    }
    if (checkAccess("CATEGORY", "R")) {
      dispatch(CategoriesRequest({ token }));
    }

    handleSubmitFilter();
    // eslint-disable-next-line
  }, [deleteQuizProp, quizStatus, currentPage]);

  const resetLocalValues = () => {
    localStorage.removeItem("quizFilter");
    localStorage.removeItem("quizSubFilter");
    localStorage.removeItem("quizSort");
    localStorage.removeItem("quizSortOrder");
    localStorage.removeItem("quizTopicFilter");
    localStorage.removeItem("quizCatagoryFilter");
    localStorage.removeItem("quizSearchValue");
    localStorage.removeItem("quizCurrentPage");
    localStorage.removeItem("quizDrop");
    localStorage.removeItem("quizStartDate");
    localStorage.removeItem("quizEndDate");
    localStorage.removeItem("dateRange-filterDate");
    setCurrentPage(1);
  };

  const refreshQuizData = () => {
    resetLocalValues();
    const body = {
      pageNo: currentPage,
      token: token,
    };
    dispatch(quizRequest(body));
    setShowInitial(true);
    setSearchValue("");
  };

  const handleClearFilter = () => {
    setResetDateRange(true);
    resetLocalValues();
    const body = {
      token,
      pageNo: currentPage,
    };
    dispatch(quizRequest(body));
    setSortValue("");
    setFilterByCategory("");
    setFilterByTopic("");
    setIsSortOrder("");
    setShowInitial(true);
    setExportDate({ startDate: "", endDate: "" });
    dispatch(topicsByCategoryIdReset());
    setTimeout(() => setResetDateRange(false), 0);
    
  };

  // useEffect(() => {
  //   getQuizData();
  // }, [currentPage, exportDate]);

  const handleDeleteQuiz = (quizId) => {
    const body = {
      token: token,
      quizId,
    };
    dispatch(deleteQuizRequest(body));
  };

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFilterAreaView = () => {
    if (screenWidth < 768) {
      setShowFilterArea((prev) => !prev);
    }
  };

  const handleActive = (item, boolean) => {
    const body = {
      token: token,
      isEnabled: boolean,
      topicId: item.topicId,
      id: item.id,
    };
    dispatch(activeQuizStatus(body));
  };

  const formatDate = (dateTimeString) => {
    const formattedDateTimeString = dateTimeString?.replace(
      /(\d{2})-(\d{2})-(\d{4})/,
      "$2/$1/$3"
    );

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const formattedDate = new Date(formattedDateTimeString).toLocaleString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const onSelect = (date, dateString) => {
    setExportDate({
      startDate: dateString[0],
      endDate: dateString[1],
    });
  };
  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
        <div className="bg-white p-4 shadow-md rounded-lg">
          <div className="flex justify-between items-center">
            <div className="font-bold uppercase text-lg text-center">
              Quizzes
            </div>

            {screenWidth < 768 && (
              <div onClick={handleFilterAreaView}>
                <GiHamburgerMenu className="text-2xl" />
              </div>
            )}
          </div>

          {showFilterArea && (
            <div className="md:flex justify-between md:items-end mt-3">
              <div className="flex flex-col md:flex-row md:flex-wrap gap-2 md:items-end w-full">
                <div className="flex-shrink-0 md:w-auto w-full">
                  <Select
                    id="dropdownOptions"
                    header="Sort Quizzes"
                    options={dropdownOptions}
                    callbackFn={handleSortValues}
                    showInitial={showInitial}
                    dropDown={quizDown}
                  />
                </div>
                <div className="flex-shrink-0 md:w-auto w-full">
                  <Select
                    id="sortOrder"
                    header="Sort Order"
                    initialValue="Select Sorting Order"
                    options={sortOrder}
                    showInitial={showInitial}
                    callbackFn={handleSortValues}
                    dropDown={quizDown}
                  />
                </div>
                <div className="flex-shrink-0 md:w-auto w-full">
                  <Select
                    id="categoryOptions"
                    header="Category"
                    initialValue="Select Category"
                    options={categoryOptions}
                    showInitial={showInitial}
                    callbackFn={handleSortValues}
                    dropDown={quizDown}
                    disabled={!checkAccess("CATEGORY", "R")}
                  />
                </div>
                <div className="flex-shrink-0 md:w-auto w-full">
                  <Select
                    id="topicOptions"
                    header="Topic"
                    initialValue="Select Topic"
                    options={topic}
                    showInitial={showInitial}
                    callbackFn={handleSortValues}
                    dropDown={quizDown}
                    disabled={!checkAccess("TOPIC", "R")}
                  />
                </div>
              </div>

              <div className="flex md:flex md:gap-2 items-center md:items-center bg-white justify-between md:justify-end w-full md:w-auto mt-3 md:mt-0 ">
                <span onClick={handleFilterAreaView} className="flex-shrink-0">
                  <Button
                    callbackFn={() => {
                      setCurrentPage(1);
                      handleSubmitFilter();
                    }}
                    displayName={"Submit"}
                    type={"submit"}
                    isLoading={isSubmittingFilter}
                  />
                </span>
                <span onClick={handleFilterAreaView} className="flex-shrink-0">
                  <Button
                    callbackFn={handleClearFilter}
                    displayName={"Reset"}
                    type={"reset"}
                  />
                </span>
              </div>
            </div>
          )}
          <div className="flex flex-col md:flex-row md:flex-wrap gap-2 md:items-end w-full mt-3">
            <div className="flex-shrink-0 md:w-auto w-full">
              <Select
                id="filterOptions"
                header="Quiz Type"
                options={filterData} 
                initialValue="Quiz Type"
                callbackFn={handleSortValues}
                showInitial={showInitial}
                dropDown={quizDown}
              />
            </div>
            <div className="flex-shrink-0 md:w-auto w-full">
              <Select
                id="filterStatusOptions"
                header="Quiz status"
                initialValue="Quiz Status"
                options={filterQuizData}
                callbackFn={handleSortValues}
                showInitial={showInitial}
                dropDown={quizDown}
              />
            </div>
            <div className="flex-shrink-0 md:w-auto w-full flex-row">
              <span className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
                Date
              </span>
              <DateRangePicker
                onSelect={onSelect}
                callbackFn={handleSortValues}
                id="filterDate"
                resetDateRange={resetDateRange}
              />
            </div>
          </div>
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] gap-2">
          <div className="flex justify-between flex-wrap">
            <div className="flex gap-3 items-center">
              <button
                onClick={() => {
                  setSearchValue("");
                  localStorage.removeItem("quizSearchValue");
                  getQuizData();
                  refreshQuizData();
                }}
              >
                <MdRefresh className="w-6 h-6" />
              </button>
              <div className="font-bold">
                COUNT: {quizProp?.quiz?.data?.totalRecords}
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <Search
                label={`Category`}
                placeholder={"Enter Quiz Id"}
                name={"Search"}
                searchCallbackfn={searchByQuizId}
                isSearching={searchQuizProp?.loading}
                searchValue={searchValue}
              />
              <button
                onClick={() => {
                  navigate({
                    pathname: "/createQuiz",
                  });
                }}
                className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 py-1 px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                  !checkAccess("QUIZ", "C")
                    ? "accessDenied"
                    : "hover:cursor-pointer"
                }`}
                disabled={!checkAccess("QUIZ", "C")}
              >
                Create Quiz
                <FaPlus />
              </button>
            </div>
          </div>
          {isLoading ? (
            <Loading noGap={true} />
          ) : (
            <div>
              {quizArray.length ? (
                <>
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
                    <table className="w-full text-sm text-left text-gray-700">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                          {tableHeaders?.map((item, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="px-6 py-3 text-center"
                            >
                              {item}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {quizArray?.map((item, index) => (
                          <tr className="bg-white border-b" key={index}>
                            <td className="px-6 py-2 text-center">
                              <div className="flex gap-2">
                                <div className="flex justify-center w-8">
                                  <img
                                    className="w-8"
                                    src={item.entryFees ? cashQuiz : freeQuiz}
                                    alt=""
                                  />
                                </div>
                                <div>{item.id}</div>
                              </div>
                            </td>
                            <td className="px-6 py-2  text-center">
                              <div>
                                <div className="w-[9rem]">
                                  {item.startTime === "IMMEDIATELY"
                                    ? "IMMEDIATELY"
                                    : formatDate(item.startTime)}
                                </div>
                              </div>
                            </td>

                            <td className="px-6 py-2 text-center">
                              <div>
                                <div>{item.categoryName}</div>
                                <div>{item.topicName}</div>
                              </div>
                            </td>
                            <td className="px-6 py-2 text-center">
                              <div>
                                <div>
                                  {item.filledSpots}/{item.totalSpots}
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-2 text-center">
                              <div>
                                <div>{item.totalWinnings}</div>
                              </div>
                            </td>
                            <td className="px-6 py-2 text-center">
                              {item.entryFees}
                            </td>
                            <td className="px-6 py-2 text-center">
                              {item.quizStatus}
                            </td>
                            <td className="px-6 py-2 text-center">
                              <button
                                onClick={() =>
                                  navigate("/viewQuiz", { state: item })
                                }
                                type="button"
                                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-blue-500 rounded-lg "
                              >
                                <span className="hidden md:inline-block">
                                  View Details{" "}
                                </span>
                                <span className="inline-block md:hidden">
                                  Details{" "}
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {totalPages > 1 && !searchValue && (
                    <div className="flex justify-end pr-5 pt-4">
                      <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={handlePageChange}
                        isLoading={quizProp?.loading}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="text-center flex flex-col justify-center items-center pt-36">
                  <TbServerOff className="text-[2rem] font-bold  m-2" />
                  Quizzes Not Found
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {openEditQuizModal && (
        <AddNewModal
          heading={"Edit Topic"}
          nameLabel={"Name of Topic"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          openModal={openEditQuizModal}
          setOpenModal={setOpenEditQuizModal}
        />
      )}

      {openDeleteQuizModal && (
        <DeleteModal
          data={deleteQuizData}
          handleDelete={handleDeleteQuiz}
          setOpenDeleteModal={setOpenDeleteQuizModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    quizProp: state?.quizReducer,
    allTopicsProp: state?.TopicReducer,
    allCategoriesProp: state?.AllCategoriesReducer,
    searchQuizProp: state?.searchQuizReducer,
    deleteQuizProp: state?.deleteQuizReducer,
    quizStatus: state?.updateQuizStatusReducer,
    TopicsByCategoryId: state?.TopicByCategoryIdReducer,
  };
};

export default connect(mapStateToProps)(Quizzes);
