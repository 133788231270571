import {
  GET_GOOGLE_REQUEST,
  GET_GOOGLE_SUCCESS,
  GET_GOOGLE_FAILURE,
} from "../../Utils/Constants";

// Action Creators
export const getGoogleRequest = (payload) => {
  return {
    type: GET_GOOGLE_REQUEST,
    payload: payload,
  };
};

export const getGoogleSuccess = (data) => {
  return {
    type: GET_GOOGLE_SUCCESS,
    payload: data,
  };
};

export const getGoogleFailure = (error) => {
  return {
    type: GET_GOOGLE_FAILURE,
    payload: error,
  };
};
