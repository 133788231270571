import {
  GET_REVIEW_TIME_REQUEST,
  GET_REVIEW_TIME_SUCCESS,
  GET_REVIEW_TIME_FAILURE,
} from "../../Utils/Constants";

export const getReviewTimeRequest = (payload) => {
  return {
    type: GET_REVIEW_TIME_REQUEST,
    payload: payload,
  };
};

export const getReviewTimeSuccess = (data) => {
  return {
    type: GET_REVIEW_TIME_SUCCESS,
    payload: data,
  };
};

export const getReviewTimeFailure = (error) => {
  return {
    type: GET_REVIEW_TIME_FAILURE,
    payload: error,
  };
};
