import {
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_FAILURE,
} from "../../Utils/Constants";

export const getMenuRequest = (payload) => {
  return {
    type: GET_MENU_REQUEST,
    payload: payload,
  };
};

export const getMenuSuccess = (data) => {
  return {
    type: GET_MENU_SUCCESS,
    payload: data,
  };
};

export const getMenuFailure = (error) => {
  return {
    type: GET_MENU_FAILURE,
    payload: error,
  };
};
