import { useDispatch } from "react-redux";
import { SearchCategoriesReset } from "../../../Actions";


const useResetCategoryReducers = () => {
  const dispatch = useDispatch();
  return {
    reset: () => {
      dispatch(SearchCategoriesReset());
    },
  };
};

export default useResetCategoryReducers;
