import {
  TOPICS_FAILURE,
  TOPICS_REQUEST,
  TOPICS_SUCCESS,
  LOGOUT,
  } from "../Utils/Constants";
  
  // Action Creators
  export const TopicsRequest = (payload) => {
    return {
      type: TOPICS_REQUEST,
      payload: payload,
    };
  };
  
  export const TopicsSuccess = (data) => {
    return {
      type: TOPICS_SUCCESS,
      payload: data,
    };
  };
  
  export const TopicsFailure = (error) => {
    return {
      type: TOPICS_FAILURE,
      payload: error,
    };
  };
  export const logoutAction = () => {
    return {
      type: LOGOUT,
    };
  };
  