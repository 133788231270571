import {
    VERIFY_OTP_LOGIN_REQUEST,
    VERIFY_OTP_LOGIN_FAILURE,
    VERIFY_OTP_LOGIN_SUCCESS,
    VERIFY_OTP_LOGIN_RESET,
    LOGOUT,
  } from "../../Utils/Constants";
  
  // Action Creators
  export const verifyOtpLoginRequest = (payload) => {
    return {
      type: VERIFY_OTP_LOGIN_REQUEST,
      payload: payload,
    };
  };
  
  export const verifyOtpLoginSuccess = (data) => {
    return {
      type: VERIFY_OTP_LOGIN_SUCCESS,
      payload: data,
    };
  };
  
  export const verifyOtpLoginFailure = (error) => {
    return {
      type: VERIFY_OTP_LOGIN_FAILURE,
      payload: error,
    };
  };

  export const verifyOtpLoginReset = (data) => {
    return {
      type: VERIFY_OTP_LOGIN_RESET,
      payload : data,
    };
  };

  export const logoutAction = () => {
    return {
      type: LOGOUT,
    };
  };
  