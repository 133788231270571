import { put, call } from "redux-saga/effects";
// import { deleteQuizSuccess, deleteQuizFailure } from "../Actions";
import { DeleteAPI, deleteData } from "../../components/Config/Apiinterface";
import { deleteUserSuccess, deleteUserFailure } from "../../Actions";
import { toast } from "react-toastify";

// Worker saga for handling DeleteCtegory request
function* deleteUserSaga(action) {
  const token = action.payload.token;
  const id = action.payload?.data?.id;
  const path = `admin/user/delete?id=${id}`;

  try {
    const response = yield call(deleteData, token, path);
    yield put(deleteUserSuccess(response));
    if (response.statusCode === 403) {
      toast.error(response.message);
      return;
    }
    toast.success(response.message);
  } catch (error) {
    yield put(deleteUserFailure(error));
  }
}

export default deleteUserSaga;
