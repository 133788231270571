import { call, put } from "redux-saga/effects";

import { GetAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";
import { getQuizBotsProbabilityFailure, getQuizBotsProbabilitySuccess } from "../../Actions";

export function* getQuizBotsProbabilitySaga(action) {
  const token = action.payload.token;
  const path = `admin/quiz/botProbability`;

  try {
    const response = yield debounce(GetAPI, token, path);
    yield put(getQuizBotsProbabilitySuccess(response));
  } catch (error) {
    yield put(getQuizBotsProbabilityFailure(error));
  }
}
