// src/redux/sagas.js

import { put, call } from "redux-saga/effects";
import { GetAPI } from "../components/Config/Apiinterface";
import { TopicsFailure, TopicsSuccess } from "../Actions";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Topics request
function* TopicsSaga(action) {
  const token = action.payload.token;
  const pageNo = action.payload.pageNo;
  const path = `admin/topic/all`;
  try {
    const Topics = yield debounce(GetAPI, token, path);
    if (Topics.statusCode === 403) {
      yield put(TopicsSuccess(Topics));
    } else {
      yield put(TopicsSuccess(Topics.data));
    }
  } catch (error) {
    yield put(TopicsFailure(error));
  }
}

export default TopicsSaga;
