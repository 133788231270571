import {
  VERSION_GET_REQUEST,
  VERSION_GET_SUCCESS,
  VERSION_GET_FAILURE,
} from "../Utils/Constants";

// Initial State
const initialState = {
  version: {},
  loading: false,
  error: null,
};
const VersionGetReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERSION_GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VERSION_GET_SUCCESS:
      return {
        ...state,
        version: action.payload,
        loading: false,
        error: null,
      };
    case VERSION_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default VersionGetReducer;
