import React, { useState } from "react";
import { Input } from "../../components/constant";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { MdArrowBack } from "react-icons/md";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import { useDispatch } from "react-redux";
import { updatePlayerRequest } from "../../Actions/User/player_all/playerEditAction";

const EditUser = () => {
  const form = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const item = location.state;
  const [name, setName] = useState(item.name);
  const [mobile, setMobile] = useState(item.mobile);
  const [walletAmount, setWalletAmount] = useState(item.wallet);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const { register, handleSubmit } = form;

  const onSubmit = (data) => {
    const body = {
      ...data,
      token: token,
      id: item.id,
    };
    dispatch(updatePlayerRequest(body));
    navigate(-1);
  };

  const totalWin = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "mobile") {
      setMobile(value);
    } else if (name === "walletAmount") {
      setWalletAmount(value);
    }
  };

  return (
    <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
      <div className="bg-white p-4 shadow-md rounded-lg">
        <div className="flex justify-start cursor-pointer">
          <span
            onClick={() => navigate(-1)}
            className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  border border-blue-400 inline-flex items-center justify-center"
          >
            <MdArrowBack className="text-base" /> Back
          </span>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center gap-2 mt-4 pb-3">
          <div className="font-bold uppercase text-lg text-center">
            Update Player
          </div>
        </div>
        <form
          className="space-y-6 mt-4 my-12"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid gap-4 mb-4 sm:grid-cols-1 lg:grid-cols-2">
            <div className="">
              <Input
                type="text"
                name="name"
                label="Enter Full Name"
                register={register}
                required
                value={name}
                totalWinnings={totalWin}
              />
            </div>
            <div className="">
              <Input
                type="text"
                name="mobile"
                label="Enter Mobile Number"
                register={register}
                required
                value={mobile}
                totalWinnings={totalWin}
              />
            </div>
            <div>
              <Input
                type="text"
                name="walletAmount"
                label="Enter Wallet Balance"
                register={register}
                required
                value={walletAmount}
                totalWinnings={totalWin}
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-auto text-white bg-gradient-to-r from-[#4CAF50] to-[#23304C] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          >
            Update Player
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
