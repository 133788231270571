import { call, put } from "redux-saga/effects";
import { getRandomQuestionSuccess, getRandomQuestionFailure } from "../Actions";
import { GetAPI } from "../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

export function* randomQuestionSaga(action) {
  const quizId = action.payload.data.quizId;
  const token = action.payload.token;
  const easyCount = action.payload.data.easyCount;
  const mediumCount = action.payload.data.mediumCount;
  const difficultCount = action.payload.data.difficultCount;

  // /v1/admin/quiz/question/random?quizId=32&easy=2&medium=1&difficult=1

  function buildURL(easyCount, mediumCount, difficultCount) {
    let url = `admin/quiz/question/random?quizId=${quizId}`;
    let hasQueryParams = true;

    if (easyCount) {
      url += hasQueryParams ? "&" : "?";
      url += "easy=" + encodeURIComponent(easyCount);
      hasQueryParams = true;
    }

    if (mediumCount) {
      url += hasQueryParams ? "&" : "?";
      url += "medium=" + encodeURIComponent(mediumCount);
      hasQueryParams = true;
    }

    if (difficultCount) {
      url += hasQueryParams ? "&" : "?";
      url += "difficult=" + encodeURIComponent(difficultCount);
      hasQueryParams = true;
    }

    return url;
  }

  const newurl = buildURL(easyCount, mediumCount, difficultCount);

  const path = newurl;

  try {
    const responce = yield debounce(GetAPI, token, path);
    yield put(getRandomQuestionSuccess(responce));
    if (responce.statusCode === 200) {
      // toast.success(responce.message);
    } else {
      toast.warn(responce.message);
    }
  } catch (err) {
    yield put(getRandomQuestionFailure(err));
  }
}
