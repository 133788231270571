import { ModalOptionsSelector } from "../../components/select/ModalOptionsSelector";
import { levelOptions, questionTypeOption } from "../../components/constant";

export function SelectDropdown(props) {
  const { topics, categories, handleSortValues, item } = props;
  return (
    <>
      <ModalOptionsSelector
        id="categoryOptions"
        header="Category"
        initialValue={
          item?.category?.name ? item?.category?.name : "Select Category"
        }
        options={categories}
        callbackFn={handleSortValues}
      />
      <ModalOptionsSelector
        id="topicOptions"
        header="Topic"
        initialValue={item?.topic?.name ? item?.topic?.name : "Select Topic"}
        options={topics}
        callbackFn={handleSortValues}
      />
      <ModalOptionsSelector
        id="levelOptions"
        header="Select Level"
        initialValue={item?.level ? item?.level : "Level"}
        options={levelOptions}
        callbackFn={handleSortValues}
      />
      <ModalOptionsSelector
        id="questionType"
        header="Select question type"
        options={questionTypeOption}
        callbackFn={handleSortValues}
      />  
    </>
  );
}
