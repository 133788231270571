const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const transformYearData = (data) => {
  // Ensure data is an array, default to empty array if data is undefined or null
  const validData = Array.isArray(data) ? data : [];

  const transformedData = validData.map((item) => {
    return [monthNames[parseInt(item.month) - 1], parseInt(item.activeUsers)];
  });

  return [["Month", "Active User"], ...transformedData];
};

function transformWeekData(data) {
  // Create a mapping for day of the week abbreviation
  const dayAbbreviations = {
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
    Sunday: "Sun",
  };

  // Initialize the result array with the headers
  const result = [["Day", "Active User"]];

  // Iterate over the data array and transform each object
  data?.forEach((item) => {
    const dayAbbrev = dayAbbreviations[item.dayOfWeekName];
    const activeUsers = parseInt(item.activeUsers, 10); // Convert active users to a number
    result.push([dayAbbrev, activeUsers]);
  });

  return result;
}

const convertDataFormCompletedQuiz = (data) => {
  const transformedData = [["Month", "Quiz completed"]];

  data?.forEach((value, index) => {
    if (index < monthNames?.length) {
      transformedData.push([monthNames[index], value]);
    }
  });

  return transformedData;
};
export const dashBoardGetActions = (index, data) => {
  switch (index) {
    case 0:
      return transformYearData(data);
    case 1:
      return transformWeekData(data);
    case 2:
      return convertDataFormCompletedQuiz(data);
  }
};
