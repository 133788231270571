
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { MdArrowBack } from "react-icons/md";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import { useDispatch } from "react-redux";
import { updateScheduleNotificationRequest } from "../../Actions";
import DatePicker from "react-datepicker";
import { Select } from "../../components/select/Select";

const notification = [
  {
    id: "",
    name: "Select",
  },
  {
    id: "PUSH",
    name: "Push Notification",
  },
  {
    id: "EMAIL",
    name: "Email Notification",
  },
];
const NotificationTypeOption = [
  {
    id: "",
    name: "Select",
  },
  {
    id: "WITH_NAME",
    name: "With Name",
  },
  {
    id: "WITHOUT_NAME",
    name: "Without Name",
  },
];
const scheduleNotification = [
  {
    id: false,
    name: "No",
  },
  {
    id: true,
    name: "Yes",
  },
];

const dropdownOptions = [
  {
    id: "ALL",
    name: "All",
  },
  {
    id: "PLAYER",
    name: "Player",
  },
  {
    id: "ADMIN",
    name: "Admins",
  },
  {
    id: "INVESTOR",
    name: "Investors",
  },
  {
    id: "FINANCE",
    name: "Finance/Legal",
  },
  {
    id: "CUSTOMER_SUPPORT",
    name: "Customer Support",
  },
  {
    id: "MARKETING_REPRESENTATIVE",
    name: "Marketing",
  },
];
const UpdateScheduleNotification = () => {
  const form = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const item = location.state;
  const [title, setTitle] = useState(item.title);
  const [body, setBody] = useState(item.body);
  const [showInitial, setShowInitial] = useState(false);
  const [notificationUserType, setNotificationUserType] = useState(item.role);
  const [notificationType, setNotificationType] = useState(item.type);
  const [pushNotificationType, setPushNotificationType] = useState(
    item.pushNotificationType
  );

  const [scheduleTime, setScheduleTime] = useState(
    item.sendAt
      ? new Date(item.sendAt.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
      : new Date()
  );
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const { handleSubmit } = form;
  const onSubmit = () => {
    const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = d.getFullYear();
      const hours = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    const formattedSendAt = scheduleTime ? formatDate(scheduleTime) : "";
    const bodyToSend = {
      title,
      body,
      token: token,
      id: item.id,
      sendAt: formattedSendAt,
      notificationType,
      pushNotificationType,
      notificationUserType,
    };
    dispatch(updateScheduleNotificationRequest(bodyToSend));
    navigate(-1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setTitle(value);
    } else if (name === "body") {
      setBody(value);
    }
  };

  const handleTimeChange = (date) => {
    setScheduleTime(date);
  };

  const handleSortValues = (id, value) => {
    if (id === "notificationType") {
      setNotificationType(value);
    } else if (id === "usersType") {
      setNotificationUserType(value);
    } else if (id === "pushNotificationType") {
      setPushNotificationType(value);
    }
  };

  return (
    <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
      <div className="bg-white p-4 shadow-md rounded-lg">
        <div className="flex justify-start cursor-pointer">
          <span
            onClick={() => navigate(-1)}
            className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  border border-blue-400 inline-flex items-center justify-center"
          >
            <MdArrowBack className="text-base" /> Back
          </span>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center gap-2 mt-4 pb-3">
          <div className="font-bold uppercase text-lg text-center">
            Update Schedule Notification
          </div>
        </div>
        <form
          className="space-y-6 mt-4 my-12"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* <div className="grid gap-4 mb-4 sm:grid-cols-1 lg:grid-cols-2"> */}
          <div className="w-full flex flex-col items-center">
            <div className="flex flex-wrap gap-4 md:w-[70%] w-full">
              <Select
                id="usersType"
                header="Users type"
                options={dropdownOptions}
                callbackFn={handleSortValues}
                showInitial={showInitial}
                selectedValue={notificationUserType}
              />
              <Select
                id="notificationType"
                header="Notification type"
                options={notification}
                callbackFn={handleSortValues}
                showInitial={showInitial}
                selectedValue={notificationType}
              />
              <Select
                id="pushNotificationType"
                header="Push Notification type"
                options={NotificationTypeOption}
                callbackFn={handleSortValues}
                showInitial={showInitial}
                selectedValue={pushNotificationType}
                disabled={notificationType !== "PUSH"}
              />
            </div>
            <div className="w-full flex justify-center">
              <div className="md:w-[70%] w-full flex my-3 text-sm rounded-lg gap-4 flex-col">
                <div className="w-full">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Schedule Time
                  </label>
                  <DatePicker
                    selected={scheduleTime}
                    onChange={handleTimeChange}
                    showTimeSelect
                    required
                    dateFormat="dd-MM-yyyy HH:mm:ss"
                    minDate={new Date()}
                    onKeyDown={(e) => e.preventDefault()}
                    className="custom-date-picker bg-gray-50 border border-gray-900 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1"

                    dropdownMode="select"
                  />
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Enter Title
                  </label>
                  <textarea
                    name="title"
                    rows="3"
                    className="bg-gray-50 border border-gray-900 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1"
                    value={title}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Enter Message
                  </label>
                  <textarea
                    name="body"
                    rows="5"
                    className="bg-gray-50 border border-gray-900 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1"
                    value={body}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-auto text-white bg-gradient-to-r from-[#4CAF50] to-[#23304C] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Update Player
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateScheduleNotification;

