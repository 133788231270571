import {
  DELETE_VERSION_REQUEST,
  DELETE_VERSION_SUCCESS,
  DELETE_VERSION_FAILURE,
} from "../Utils/Constants";

// Action Creators
export const versionDeleteRequest = (payload) => {
  return {
    type: DELETE_VERSION_REQUEST,
    payload: payload,
  };
};

export const versionDeleteSuccess = (data) => {
  return {
    type: DELETE_VERSION_SUCCESS,
    payload: data,
  };
};

export const versionDeleteFailure = (error) => {
  return {
    type: DELETE_VERSION_FAILURE,
    payload: error,
  };
};
