import { put, call } from "redux-saga/effects";
// import { deleteQuizSuccess, deleteQuizFailure } from "../Actions";
import { DeleteAPI, deleteData } from "../../components/Config/Apiinterface";
import {
  deleteBankUpiAccountsSuccess,
  deleteBankUpiAccountsFailure,
} from "../../Actions";
import { toast } from "react-toastify";

// Worker saga for handling DeleteCtegory request
function* deleteBankUpiAccountsSaga(action) {
  const token = action.payload.token;
  const fundAccountId = action.payload?.data?.fundAccountId;
  const accountType = action.payload.data.accountType;

  const path = `admin/user/bankOrUpi?fundAccountId=${fundAccountId}&type=${accountType}`;

  try {
    const response = yield call(deleteData, token, path);
    yield put(deleteBankUpiAccountsSuccess(response));
    toast.success(response.message);
  } catch (error) {
    yield put(deleteBankUpiAccountsFailure(error));
  }
}

export default deleteBankUpiAccountsSaga;
