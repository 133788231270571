import { put, call } from "redux-saga/effects";
import { GetAPI } from "../components/Config/Apiinterface";
import { quizSuccess, quizFailure } from "../Actions";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Topics request

function* quizSaga(action) {
  const token = action.payload.token;
  const sortBy = action.payload.sortValue;
  const categoryId = action.payload.filterByCategory;
  const topicId = action.payload.filterByTopic;
  const filter = action.payload.filter;
  const subFilter=action.payload.subFilter;
  const pageNo = action.payload.pageNo;
  var sortOrder = action.payload.isSortOrder;
  const startDate = action.payload.startDate;
  const endDate = action.payload.endDate;
  const special=action.payload.special;

  function buildURL(
    categoryId,
    sortBy,
    topicId,
    sortOrder,
    filter,
    subFilter,
    startDate,
    endDate,
    special,
  ) {
    let url = `admin/quiz/all?pageNo=${pageNo}&pageSize=20`;
    let hasQueryParams = true;

    if (filter) {
      url += hasQueryParams ? "&" : "?";
      url += "quizType=" + encodeURIComponent(filter);
      hasQueryParams = true;
    }
    if (subFilter) {
      url += hasQueryParams ? "&" : "?";
      url += "quizStatus=" + encodeURIComponent(subFilter);
      hasQueryParams = true;
    }

    if (categoryId) {
      url += hasQueryParams ? "&" : "?";
      url += "categoryId=" + encodeURIComponent(categoryId);
      hasQueryParams = true;
    }

    if (sortBy) {
      url += hasQueryParams ? "&" : "?";
      url += "sortBy=" + encodeURIComponent(sortBy);
      hasQueryParams = true;
    }

    if (topicId) {
      url += hasQueryParams ? "&" : "?";
      url += "topicId=" + encodeURIComponent(topicId);
      hasQueryParams = true;
    }

    if (sortOrder) {
      url += hasQueryParams ? "&" : "?";
      url += "sortOrder=" + encodeURIComponent(sortOrder);
      hasQueryParams = true;
    }

    if (startDate) {
      url += hasQueryParams ? "&" : "?";
      url += "startDate=" + encodeURIComponent(startDate);
      hasQueryParams = true;
    }

    if (endDate) {
      url += hasQueryParams ? "&" : "?";
      url += "endDate=" + encodeURIComponent(endDate);
      hasQueryParams = true;
    }
    if (special) {
      url += hasQueryParams ? "&" : "?";
      url += "special=" + encodeURIComponent();
      hasQueryParams = true;
    }

    return url;
  }

  const newurl = buildURL(
    categoryId,
    sortBy,
    topicId,
    sortOrder,
    filter,
    subFilter,
    startDate,
    endDate
  );

  const path = newurl;

  try {
    const result = yield debounce(GetAPI, token, path);
    yield put(quizSuccess(result));
    if (result.statusCode === 200) {
      // toast.success(result.message);
    } else {
      toast.warning(result.message);
    }
  } catch (error) {
    yield put(quizFailure(error));
  }
}

export default quizSaga;



// import { put, call } from "redux-saga/effects";
// import { GetAPI } from "../components/Config/Apiinterface";
// import { quizSuccess, quizFailure } from "../Actions";
// import { toast } from "react-toastify";
// import debounce from "../Utils/useDebounce";

// // Worker saga for handling Topics request

// function* quizSaga(action) {
//   const token = action.payload.token;
//   const sortBy = action.payload.sortValue;
//   const categoryId = action.payload.filterByCategory;
//   const topicId = action.payload.filterByTopic;
//   const filter = action.payload.filter;
//   const subFilter=action.payload.subFilter;
//   const pageNo = action.payload.pageNo;
//   var sortOrder = action.payload.isSortOrder;
//   const startDate = action.payload.startDate;
//   const endDate = action.payload.endDate;
//   const special=action.payload.special;
//   const addBots=action.payload.addBots;

//   function buildURL(
//     categoryId,
//     sortBy,
//     topicId,
//     sortOrder,
//     filter,
//     subFilter,
//     startDate,
//     endDate,
//     special,
//     addBots,
//   ) {
//     let url = `admin/quiz/all?pageNo=${pageNo}&pageSize=20`;
//     let hasQueryParams = true;

//     if (filter) {
//       url += hasQueryParams ? "&" : "?";
//       url += "quizType=" + encodeURIComponent(filter);
//       hasQueryParams = true;
//     }
//     if (subFilter) {
//       url += hasQueryParams ? "&" : "?";
//       url += "quizStatus=" + encodeURIComponent(subFilter);
//       hasQueryParams = true;
//     }

//     if (categoryId) {
//       url += hasQueryParams ? "&" : "?";
//       url += "categoryId=" + encodeURIComponent(categoryId);
//       hasQueryParams = true;
//     }

//     if (sortBy) {
//       url += hasQueryParams ? "&" : "?";
//       url += "sortBy=" + encodeURIComponent(sortBy);
//       hasQueryParams = true;
//     }

//     if (topicId) {
//       url += hasQueryParams ? "&" : "?";
//       url += "topicId=" + encodeURIComponent(topicId);
//       hasQueryParams = true;
//     }

//     if (sortOrder) {
//       url += hasQueryParams ? "&" : "?";
//       url += "sortOrder=" + encodeURIComponent(sortOrder);
//       hasQueryParams = true;
//     }

//     if (startDate) {
//       url += hasQueryParams ? "&" : "?";
//       url += "startDate=" + encodeURIComponent(startDate);
//       hasQueryParams = true;
//     }

//     if (endDate) {
//       url += hasQueryParams ? "&" : "?";
//       url += "endDate=" + encodeURIComponent(endDate);
//       hasQueryParams = true;
//     }
//     if (special) {
//       url += hasQueryParams ? "&" : "?";
//       url += "special=" + encodeURIComponent(special);
//       hasQueryParams = true;
//     }
//     if (addBots) {
//       url += hasQueryParams ? "&" : "?";
//       url += "addBots=" + encodeURIComponent(addBots);
//       hasQueryParams = true;
//     }

//     return url;
//   }

//   const newurl = buildURL(
//     categoryId,
//     sortBy,
//     topicId,
//     sortOrder,
//     filter,
//     subFilter,
//     startDate,
//     endDate,
//     special,
//     addBots,
//   );

//   const path = newurl;

//   try {
//     const result = yield debounce(GetAPI, token, path);
//     yield put(quizSuccess(result));
//     if (result.statusCode === 200) {
//       // toast.success(result.message);
//     } else {
//       toast.warning(result.message);
//     }
//   } catch (error) {
//     yield put(quizFailure(error));
//   }
// }

// export default quizSaga;
