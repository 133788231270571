import {
  CANCEL_QUIZ_REQUEST,
  CANCEL_QUIZ_SUCCESS,
  CANCEL_QUIZ_FAILURE,
  CANCEL_QUIZ_RESET
} from "../Utils/Constants";

// initial state

const initialState = {
  CancelQuiz: {},
  loading: false,
  error: null,
};

// Reducer

const CancelQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_QUIZ_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CANCEL_QUIZ_SUCCESS:
      return {
        ...state,
        CancelQuiz: action.payload,
        loading: false,
        error: null,
      };
    case CANCEL_QUIZ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case CANCEL_QUIZ_RESET:
        return {
          CancelQuiz: {},
          loading: false,
          error: null,
        };
    default:
      return state;
  }
};

export default CancelQuizReducer;
