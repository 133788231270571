import {
    VERIFY_OTP_LOGIN_REQUEST,
    VERIFY_OTP_LOGIN_SUCCESS,
    VERIFY_OTP_LOGIN_FAILURE,
    LOGOUT,
    VERIFY_OTP_LOGIN_RESET,
  } from "../../Utils/Constants";

  const initialState = {
    verifyOtp: {},
    loading: false,
    error: null,
  };
  
  const verifyOtpWithLoginReducer = (state = initialState, action) => {
    switch (action.type) {
      case VERIFY_OTP_LOGIN_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case VERIFY_OTP_LOGIN_SUCCESS:
        return {
          ...state,
          verifyOtp: action.payload,
          loading: false,
          error: null,
        };
  
      case VERIFY_OTP_LOGIN_FAILURE:
        return {
          ...state,
          loading: true,
          error: action.payload,
        };
        case VERIFY_OTP_LOGIN_RESET:
          return {
            verifyOtp: {},
            loading: false,
            error: null,
          };
      default:
        return state;
    }
  };
  
  export default verifyOtpWithLoginReducer;