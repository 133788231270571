import { put, call } from "redux-saga/effects";
import { sendOtpLoginFailure, sendOtpLoginSuccess } from "../../Actions";
import { sendOtpWithLogin } from "../../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../../Utils/useDebounce";

function* sendOtpWithLoginSaga(action) {
  const body = {
    emailId: action.payload.emailId,
    password: action.payload.password,
  };
  try {
    const user = yield debounce(sendOtpWithLogin, body);
    yield put(sendOtpLoginSuccess(user));
    if (user.statusCode === 200) {
      toast.success(user.message);
    } else {
      toast.warning(user.data.message);
    }
  } catch (error) {
    yield put(sendOtpLoginFailure(error));
  }
}

export default sendOtpWithLoginSaga;
