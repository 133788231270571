import "./custom.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { WinnerBreakupModal } from "../modal/WinnerBreakupModal";
import { Input, style } from "../constant";
import { SelectQuizMode } from "../../layout";
import CheckboxBtn from "../ui-elements/CheckboxBtn";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router";
import { SpinnerLoader } from "../Loaders/SpinnerLoader";

const CreateQuiz = (props) => {
  const {
    onSubmit,
    categories,
    handleStartTimeChange,
    topics,
    register,
    handleSubmit,
    checkTotalSpot,
    openQuizAddModal,
    setOpenQuizAddModal,
    winningBreackup,
    totalWinnings,
    handleSortValues,
    setTotalWinningAmount,
    setValue,
    setQuizMod,
    setIsRepeating,
    isRepeating,
    isEnabled,
    setIsEnabled,
    isSubmitSuccessful,
    totalWinningAmount,
    cashChecked,
    botChecked,
    specialChecked,
    freeChecked,
    setCashChecked,
    setBotChecked,
    setSpecialChecked,
    setFreeChecked,
    isPrime,
    setIsPrime,
    isDaily,
    setIsDaily,
    isWeekly,
    setIsWeekly,
    setFrequency,
    AddQuiz,
    submited,
    minUser,
    isPractice,
    setIsPractice,
    specialQuizUsers,
    getValues,
    cr,
    winingData,
  } = props;

  const getDate15MinutesFromNow = () => {
    let date = new Date();
    date.setMinutes(date.getMinutes() + 15);
    return date;
  };

  const [startTime, setStartTime] = useState(getDate15MinutesFromNow());
  const [isChecked, setIsChecked] = useState(false);

  const handleTimeChange = (date) => {
    setStartTime(date);
    handleStartTimeChange(date);
  };

  const navigate = useNavigate();

  const checkboxData = [
    { id: 1, label: "Cash", flage: "cash" },
    { id: 2, label: "Free", flage: "free" },
    { id: 2, label: "Practice", flage: "practise" },
    { id: 8, label: "Special", flage: "special" },
    { id: 3, label: "Enable", flage: "Quiz-Enable" },
    { id: 4, label: "Quiz-Repeating", flage: "Quiz-Reapating" },
    { id: 5, label: "Prime", flage: "Prime" },
    { id: 6, label: "Daily", flage: "Daily" },
    { id: 7, label: "Weekly", flage: "Weekly" },
    { id: 8, label: "Add Bots", flage: "bots" },
  ];

  function handleIsReapting() {
    setIsRepeating(!isRepeating);
    if (!isRepeating) {
      setIsDaily(false);
      setIsWeekly(false);
    }
  }

  function handleIsEnbled() {
    setIsEnabled(!isEnabled);
  }

  function handleIsPrime() {
    setIsPrime(!isPrime);
  }

  function handleIsDaily() {
    setIsDaily(true);
    setIsWeekly(false);
    setFrequency("Daily");
  }

  function handleIsWeekly() {
    setIsWeekly(true);
    setIsDaily(false);
    setFrequency("Weekly");
  }

  const handleClick = (item) => {
    if (item.flage === "Quiz-Enable") {
      handleIsEnbled();
    } else if (item.flage === "Quiz-Reapating") {
      handleIsReapting();
    } else if (item.flage === "cash") {
      handleCashChange();
    } else if (item.flage === "bots") {
      handleBotChange();
    } else if (item.flage === "special") {
      handleSpecialChange();
    } else if (item.flage === "free") {
      handleFreeChange();
    } else if (item.flage === "Prime") {
      handleIsPrime();
    } else if (item.flage === "Daily") {
      handleIsDaily();
    } else if (item.flage === "Weekly") {
      handleIsWeekly();
    } else if (item.flage === "practise") {
      handlePracticeChange();
    }
  };

  const handleCashChange = () => {
    if (!cashChecked) {
      setCashChecked(true);
      setFreeChecked(false);
      setSpecialChecked(false);
      setIsPractice(false);
      setIsChecked(false);
      setIsRepeating(false);
      setIsDaily(false);
      setIsWeekly(false);
      setIsPrime(false);
    }
  };
  const handleBotChange = () => {
    if (!botChecked) {
      setBotChecked(true);
      setCashChecked(true);
      setFreeChecked(false);
      setSpecialChecked(false);
      setIsPractice(false);
      setIsChecked(false);
      setIsRepeating(false);
      setIsDaily(false);
      setIsWeekly(false);
      setIsPrime(false);
    }
  };
  const handleSpecialChange = () => {
    if (!specialChecked) {
      setSpecialChecked(true);
      setCashChecked(true);
      setFreeChecked(false);
      setBotChecked(false);
      setIsPractice(false);
      setIsChecked(false);
      setIsRepeating(false);
      setIsDaily(false);
      setIsWeekly(false);
      setIsPrime(false);
    } else {
      setSpecialChecked(false);
      setCashChecked(true);
    }
  };

  const handleFreeChange = () => {
    if (!freeChecked) {
      setValue("entryFees", 0);
      setValue("totalWinnings", 0);
      setTotalWinningAmount(0);
      const e = {
        target: {
          name: "totalWinnings",
          value: 0,
        },
      };
      totalWinnings(e);
      setFreeChecked(true);
      setIsPractice(false);
      setCashChecked(false);
      setBotChecked(false);
      setSpecialChecked(false);
      setIsChecked(true);
      setIsRepeating(false);
      setIsDaily(false);
      setIsWeekly(false);
      setIsPrime(false);
    }
  };
  const handlePracticeChange = () => {
    // setIsRepeating(false);
    if (!isPractice) {
      setValue("entryFees", 0);
      setValue("totalWinnings", 0);
      setTotalWinningAmount(0);
      const e = {
        target: {
          name: "totalWinnings",
          value: 0,
        },
      };
      setValue("totalSpots", 1);
      totalWinnings(e);
      setFreeChecked(false);
      setCashChecked(false);
      setBotChecked(false);
      setSpecialChecked(false);
      setIsChecked(true);
      setIsPractice(true);
      setIsRepeating(true);
      setIsDaily(false);
      setIsWeekly(false);
      setIsPrime(false);
    }
  };

  let calculateTotalWin =
    winingData.totalSpots * winingData.entryFees -
    (winingData.totalSpots * winingData.entryFees * cr) / 100;
  const handleTotalWinningsChange = (e) => {
    const value = e?.target?.value;
    if (specialChecked) {
      setTotalWinningAmount(value);
    } else if (value <= calculateTotalWin) {
      setTotalWinningAmount(value);
    } else {
      setTotalWinningAmount(totalWinningAmount);
    }
  };

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh] relative ">
          <div className="flex justify-start cursor-pointer">
            <span
              onClick={() => navigate(-1)}
              className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  border border-blue-400 inline-flex items-center justify-center"
            >
              <MdArrowBack className="text-base" /> Back
            </span>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center gap-2 mt-4 pb-3">
            <div className="font-bold uppercase text-lg text-center">
              Create Quiz
            </div>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex gap-4">
              <div className="flex gap-4 flex-wrap space-x-3">
                {checkboxData.map((item, index) => (
                  <div key={index}>
                    <div>
                      <CheckboxBtn
                        handleClick={() => handleClick(item)}
                        label={item.label}
                        checked={
                          (item.flage === "cash" && cashChecked) ||
                          (item.flage === "bots" && botChecked) ||
                          (item.flage === "special" && specialChecked) ||
                          (item.flage === "free" && freeChecked) ||
                          (item.flage === "Quiz-Enable" && isEnabled) ||
                          (item.flage === "Quiz-Reapating" && isRepeating) ||
                          (item.flage === "Prime" && isPrime) ||
                          (item.flage === "Daily" && isDaily) ||
                          (item.flage === "Weekly" && isWeekly) ||
                          (item.flage === "practise" && isPractice)
                        }
                        disabled={
                          (item.flage === "practise" ||
                            item.flage === "Weekly" ||
                            item.flage === "Daily") &&
                          isPractice
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="grid gap-4 mb-4 sm:grid-cols-2 lg:grid-cols-3">
              <SelectQuizMode
                categories={categories}
                topics={topics}
                handleSortValues={handleSortValues}
                setQuizMod={setQuizMod}
                register={register}
                disabled={freeChecked || isPractice}
              />
              <div>
                <Input
                  type="number"
                  name="entryFees"
                  label="Entry Fees"
                  register={register}
                  totalWinnings={totalWinnings}
                  required={!freeChecked && !isPractice}
                  disabled={freeChecked || isPractice}
                />
              </div>
              <div>
                <Input
                  type="number"
                  name="totalSpots"
                  label="Total Spots"
                  register={register}
                  disabled={isPractice}
                  totalWinnings={freeChecked ? null : totalWinnings}
                  required={!freeChecked && !isPractice}
                />
              </div>
              <div>
                <Input
                  name="noOfQuestions"
                  label="Total number of questions"
                  register={register}
                  required
                />
              </div>
              <div>
                <Input
                  name="duration"
                  label="Quiz Duration (mins)"
                  register={register}
                  required
                />
              </div>
              <div className="w-full">
                {isPractice ? (
                  <>
                    <label className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
                      Start Time
                    </label>
                    <input
                      type="text"
                      value={"IMMEDIATELY"}
                      disabled={isPractice}
                      autoComplete="off"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1 md:mb-2 font-medium`}
                    />
                  </>
                ) : (
                  <>
                    <label className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
                      {"Start Time"}
                    </label>
                    <DatePicker
                      key={isSubmitSuccessful ? "reset" : "datepicker"}
                      selected={startTime}
                      onChange={handleTimeChange}
                      showTimeSelect
                      required
                      dateFormat="dd-MM-yyyy HH:mm:ss"
                      minDate={new Date()}
                      className="custom-date-picker bg-gray-50 border border-gray-900 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1 md:mb-2  font-medium "
                    />
                  </>
                )}
              </div>
              <div className="flex flex-col sm:flex-row gap-5">
                <div className="sm:w-full">
                  <Input
                    type="number"
                    name="totalWinnings"
                    label="Total Winnings Amount"
                    register={register}
                    required={!freeChecked && !isPractice}
                    disabled={isPractice}
                    value={freeChecked ? 0 : totalWinningAmount}
                    totalWinnings={handleTotalWinningsChange}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => checkTotalSpot()}
                  disabled={isChecked}
                  className="sm:w-full md:mt-4 lg:mt-4 sm:mt-0 text-left text-blue-500"
                >
                  <span>{"Winner Breakup"}</span>
                </button>
              </div>
              <div>
                <Input
                  name="easyCount"
                  label="Enter Easy Questions"
                  register={register}
                  errorMessage={
                    "Please enter 0 for proceed or this field is required"
                  }
                  submitted={submited}
                  required
                />
              </div>
              <div>
                <Input
                  name="mediumCount"
                  label="Enter Medium Questions"
                  register={register}
                  required
                  errorMessage={
                    "Please enter 0 for proceed or this field is required"
                  }
                  submitted={submited}
                />
              </div>
              <div>
                <Input
                  name="difficultCount"
                  label="Enter Difficult Questions"
                  register={register}
                  required
                  errorMessage={
                    "Please enter 0 for proceed or this field is required"
                  }
                  submitted={submited}
                />
              </div>
            </div>
            <div className="flex gap-5 flex-wrap items-center">
              <button type="submit" className={style.buttonClass}>
                {AddQuiz.loading ? (
                  <SpinnerLoader size={"sm"} />
                ) : (
                  <span className=" md:inline-block">{"Create Quiz"}</span>
                )}
              </button>
              {specialChecked && specialQuizUsers ? (
                <>
                  <span className="text-sm font-bold text-green-700">
                    Minimum {specialQuizUsers} User(s) are required to join
                  </span>
                </>
              ) : (
                <>
                  {botChecked ? (
                    <span className="text-sm font-bold text-green-700">
                      Minimum 1 User is required to join
                    </span>
                  ) : minUser ? (
                    <span className="text-sm font-bold text-green-700">
                      Minimum {Math.ceil(minUser)} User(s) are required to join
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>

      {openQuizAddModal && (
        <WinnerBreakupModal
          heading={"Show Breackup of Quiz"}
          nameLabel={"New Quiz"}
          submitLabel={"Submit"}
          submitLabelsm={"Save Changes"}
          openModal={openQuizAddModal}
          setOpenModal={setOpenQuizAddModal}
          data={winningBreackup()}
        />
      )}
    </>
  );
};

export default CreateQuiz;
