import { put, call } from "redux-saga/effects";
import { GetAPISearch, searchByID } from "../components/Config/Apiinterface";
import { searchQuizSuccess, searchQuizFailure } from "../Actions";
import debounce from "../Utils/useDebounce";

function* SearchQuizSaga(action) {
  const searchValue = action.payload.quizId;
  const path = `admin/quiz/id?quizId=${searchValue}`;

  try {
    const topic = yield debounce(searchByID, action.payload.token, path);
    yield put(searchQuizSuccess(topic));
  } catch (error) {
    yield put(searchQuizFailure(error));
  }
}

export default SearchQuizSaga;
