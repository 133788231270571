import {
    UPDATE_TOPIC_REQUEST,
    UPDATE_TOPIC_SUCCESS,
    UPDATE_TOPIC_FAILURE,
    LOGOUT,
    UPDATE_TOPIC_RESET,
  } from "../Utils/Constants";
  
  // Action Creators
  export const UpdateTopicRequest = (payload) => {
    return {
      type: UPDATE_TOPIC_REQUEST,
      payload: payload,
    };
  };
  
  export const UpdateTopicSuccess = (data) => {
    return {
      type: UPDATE_TOPIC_SUCCESS,
      payload: data,
    };
  };
  
  export const UpdateTopicFailure = (error) => {
    return {
      type: UPDATE_TOPIC_FAILURE,
      payload: error,
    };
  };

  export const UpdateTopicReset = () => {
    return {
      type: UPDATE_TOPIC_RESET
    }
  }
  export const logoutAction = () => {
    return {
      type: LOGOUT,
    };
  };
  