import {
  getLeaderbordUserQuestionSuccess,
  getLeaderbordUserQuestionFailure,
} from "../../Actions";
import { GetAPI } from "../../components/Config/Apiinterface";
import { call, put } from "redux-saga/effects";
import debounce from "../../Utils/useDebounce";

export function* leaderboardUserQuestionSaga(action) {
  const token = action.payload.token;
  const quizId = action.payload.id;
  const userId = action.payload.userId;
  const path = `admin/quiz/userQuestions?userId=${userId}&quizId=${quizId}`;

  try {
    const response = yield debounce(GetAPI, token, path);
    yield put(getLeaderbordUserQuestionSuccess(response));
  } catch (error) {
    yield put(getLeaderbordUserQuestionFailure(error));
  }
}
