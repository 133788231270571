import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getDashboardFailure, getDashboardSuccess } from "../../Actions";
import { GetAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";

// Worker saga for handling Add Category request
function* dashboardSaga(action) {
  const data = action.payload.data;
  const token = action.payload.token;
  const path = `admin/dashboard?filterBy=${data}`;
  try {
    const response = yield debounce(GetAPI, token, path);  
    
    if (response.statusCode === 200 || response.statusCode === 201) {
      // toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
    yield put(getDashboardSuccess(response));
  } catch (error) {
    yield put(getDashboardFailure(error));
  }
}

export default dashboardSaga;
