import { put, call } from "redux-saga/effects";
import { deleteQuizSuccess, deleteQuizFailure } from "../Actions";
import { DeleteAPI } from "../components/Config/Apiinterface";
import debounce from "../Utils/useDebounce";

// Worker saga for handling DeleteCtegory request
function* deleteQuizSaga(action) {
  const token = action.payload.token;
  const quizId = action.payload.quizId;
  const path = "quiz?quizId=";
  try {
    const response = yield debounce(DeleteAPI, token, quizId, path);
    yield put(deleteQuizSuccess(response));
  } catch (error) {
    yield put(deleteQuizFailure(error));
  }
}

export default deleteQuizSaga;
