import { put, call } from "redux-saga/effects";
import { cancelQuizSuccess, cancelQuizFailure } from "../Actions";
import { putRequestAPI } from "../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

// Worker saga for handling DeleteCtegory request
export function* cancelQuizSaga(action) {
  const token = action.payload.token;
  const quizId = action.payload.id;
  const path = `admin/quiz/cancel?quizId=${quizId}`;
  try {
    const response = yield debounce(putRequestAPI, token, quizId, path);
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
    yield put(cancelQuizSuccess(response));
  } catch (error) {
    yield put(cancelQuizFailure(error));
  }
}
