import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import createStore from "./Reducers";
import SiteBlocked from "./Screens/siteBlocked";
import IpBlockingFn from "./Utils/ipBlockingFn";
import { Tooltip } from "react-tooltip";

const store = createStore();
const root = ReactDOM.createRoot(document.getElementById("root"));

// ! this function checking if request ip  inside india or not
const PreLoadScript = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          "https://stag-api.quiznation.in/quiznation/v1/admin/ipInfo"
        );
        const data = await response.json();
        setIsSuccess(data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsSuccess(false);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading) {
    return <div className="w-screen h-screen bg-[#e8f5e9]"></div>;
  }

  return isSuccess ? <App /> : <SiteBlocked />;
};

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("/firebase-messaging-sw.js")
//     .then(function (registration) {
//     })
//     .catch(function (err) {
//     });
// }

root.render(
  <>
    <BrowserRouter>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <Notification /> */}
        <Tooltip id="my-tooltip" />

        <PreLoadScript />
        <IpBlockingFn />
      </Provider>
    </BrowserRouter>
  </>
);
