import { put } from "redux-saga/effects";
import { getManageFailure, getManageSuccess } from "../../Actions";
import { GetAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";

export function* getManageAccessSaga(action) {
  const userId = action.payload.id;
  const token = action.payload.token;
  const path = `privilege/user/all?userId=${userId}`;
  try {
    const response = yield debounce(GetAPI, token, path);
    yield put(getManageSuccess(response));
  } catch (error) {
    yield put(getManageFailure(error));
  }
}
