export function calculateGrowthRate(currentNumber, previousNumber) {
  if (previousNumber === 0) {
    if (currentNumber === 0) {
      return 0; // Both are zero, no growth
    }
    return Infinity; // Previous number is zero and current is not zero
  }

  const growthRate = ((currentNumber - previousNumber) / previousNumber) * 100;
  return growthRate ? growthRate.toFixed(2) : 0;
}
