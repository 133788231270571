import React from "react";
import { FcLock } from "react-icons/fc";
import { useNavigate } from "react-router";

const AccessDenied = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#e8f5e9] min-h-screen px-4 md:px-14 pt-14 md:pt-20 flex items-center justify-center">
      <div className="bg-white flex flex-col justify-center items-center p-8 shadow-lg rounded-lg min-h-[60vh] w-full md:w-1/2 lg:w-1/3 text-center">
        <FcLock className="text-6xl mb-4 text-[red]" />
        <h1 className="text-2xl font-semibold mb-2">Access Denied</h1>
        <p className="text-gray-700 mb-4">
          You don't have permission to view this page.
        </p>
        <button
          className="bg-[#4caf50] text-white px-4 py-2 rounded-lg shadow hover:bg-[#45a049] transition duration-300"
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default AccessDenied;
