import { call, put } from "redux-saga/effects";
import { postFormData } from "../../components/Config/Apiinterface";
import {
  updateCommissionRateFailure,
  updateCommissionRateSuccess,
} from "../../Actions";
import { toast } from "react-toastify";
import debounce from "../../Utils/useDebounce";

export function* updateCommissionRateSaga(action) {
  const path = "admin/quiz/commissionRate";
  const token = action.payload.token;
  const body = action.payload.data;
  try {
    const responce = yield debounce(postFormData, path, body, token);
    yield put(updateCommissionRateSuccess(responce));
    if (responce.statusCode === 200) {
      toast.success(responce.message);
    } else {
      toast.warning(responce.message);
    }
  } catch (err) {
    yield put(updateCommissionRateFailure(err));
  }
}
