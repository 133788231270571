import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router";
import { Search } from "../Search";
import ReadMoreLess from "../ui-elements/ReadMoreLess";
import { style } from "../constant";
import { MdDelete } from "react-icons/md";
import cashQuiz from "../../assets/images/cashQuiz.svg";
import freeQuiz from "../../assets/images/freeQuiz.svg";
import { FiUpload } from "react-icons/fi";
import { BiExport } from "react-icons/bi";
import { FaFileExport } from "react-icons/fa6";
import { TbServerOff } from "react-icons/tb";
import Loading from "../loading";
import { checkAccess } from "../../Utils/RoleBasedRoute";

export default function Table(props) {
  const navigate = useNavigate();
  const {
    isLoading,
    searchQuizProp,
    itemsArray,
    setOpenDeleteModal,
    setDeleteData,
    searchByQuestionId,
    isSubmittingFilter,
    handleCheckboxChange,
    checkedBoxes,
    handleCheckboxId,
    setOpenDeleteQuestionModal,
    setDeleteQuestionData,
    setCheckedBoxes,
    searchValue,
    countOfQuestion,
    handleQuestionExport,
  } = props;

  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChangeAll = () => {
    setSelectAll(!selectAll);
    const allCheckboxIds = itemsArray.map((item) => item.id);
    setCheckedBoxes(selectAll ? [] : allCheckboxIds);
  };

  const handleSelectAll = () => {
    handleCheckboxChangeAll();
  };

  if (isLoading) {
    return <Loading noGap={true} />;
  }

  return (
    <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] gap-2">
      <div className="md:hidden block text-left font-bold">
        Count: <span className=" font-normal ">{countOfQuestion}</span>
      </div>
      <div className="flex justify-between items-center">
        <div className="md:block hidden text-left font-bold uppercase">
          Count: <span className="font-bold">{countOfQuestion}</span>
        </div>
        <div className="flex justify-between gap-2">
          <Search
            label={`Category`}
            placeholder={"Enter Question Id"}
            name={"Search"}
            searchCallbackfn={searchByQuestionId}
            isSearching={searchQuizProp?.loading}
            searchValue={searchValue}
          />
          <button
            disabled={!checkAccess("QUESTIONPOOL", "C")}
            onClick={() => {
              navigate("/addQuestion");
            }}
            className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 md:py-1 px-1 md:px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
              !checkAccess("QUESTIONPOOL", "C")
                ? "accessDenied"
                : "hover:cursor-pointer"
            }`}
          >
            Add Question
            <FaPlus />
          </button>
          <button
            disabled={!checkAccess("QUESTIONPOOL", "C")}
            onClick={() => {
              navigate("/BulkUpload");
            }}
            className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 md:py-1 px-1 md:px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
              !checkAccess("QUESTIONPOOL", "C")
                ? "accessDenied"
                : "hover:cursor-pointer"
            }`}
          >
            Bulk Upload
            <FaPlus />
          </button>
          <button
            disabled={!checkAccess("QUESTIONPOOL", "R")}
            onClick={() => {
              handleQuestionExport();
            }}
            className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 md:py-1 px-1 md:px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
              !checkAccess("QUESTIONPOOL", "R")
                ? "accessDenied"
                : "hover:cursor-pointer"
            }`}
          >
            Export
            <FaFileExport />
          </button>
        </div>
      </div>
      {itemsArray?.length ? (
        <>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
            {isSubmittingFilter && (
              <div className="flex gap-2 m-2">
                <div>
                  <button onClick={handleSelectAll}>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleCheckboxChangeAll}
                    />
                  </button>
                </div>
                <div>
                  <button
                    disabled={!checkAccess("QUESTIONPOOL", "D")}
                    onClick={() => {
                      setDeleteQuestionData({
                        name: `Question with id ${checkedBoxes}`,
                        id: checkedBoxes,
                      });
                      setOpenDeleteQuestionModal(true);
                    }}
                  >
                    <MdDelete
                      className={`text-2xl text-red-500 ${
                        !checkAccess("QUESTIONPOOL", "D")
                          ? "accessDenied"
                          : "hover:cursor-pointer"
                      }`}
                    />
                  </button>
                </div>
                <div>
                  <span>Delete Questions</span>
                </div>
              </div>
            )}
            {itemsArray?.map((item, index) => (
              <div className="bg-white border-b" key={index}>
                <div className="flex flex-col sm:flex-row items-center px-3 py-2 sm:py-4 gap-4">
                  {isSubmittingFilter === true ? (
                    <input
                      type="checkbox"
                      onClick={() => handleCheckboxChange(item.id)}
                      checked={checkedBoxes?.includes(item.id)}
                    />
                  ) : null}
                  <h5 className="">{`Question Id #${item?.id}`}</h5>
                  <h5>
                    <img
                      className="w-12"
                      src={item.isFree ? freeQuiz : cashQuiz}
                      alt=""
                    />
                  </h5>
                  <div className="flex self-center bg-green-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                    <div className="">{item?.level}</div>
                  </div>
                </div>

                <div className="flex flex-wrap">
                  <div>
                    <td className="px-6 py-2 ">
                      <ReadMoreLess
                        descriptionEn={item.descriptionEn}
                        maxLength={100}
                      />
                    </td>
                    <h6 className="px-6 py-2">A : {item.optionsEn?.A}</h6>
                    <h6 className="px-6 py-2">B : {item.optionsEn?.B}</h6>
                    <h6 className="px-6 py-2">C : {item.optionsEn?.C}</h6>
                    <h6 className="px-6 py-2">D : {item.optionsEn?.D}</h6>{" "}
                    <h6 className="px-6 py-2">
                      {" "}
                      {`Ans:- ${item.answerEn?.option}) ` +
                        item.answerEn?.answer}
                    </h6>
                  </div>
                  <div>
                    <td className="px-6 py-2 ">
                      <ReadMoreLess
                        descriptionEn={item.descriptionHi}
                        maxLength={100}
                      />
                    </td>
                    <h6 className="px-6 py-2">A : {item.optionsHi?.A}</h6>
                    <h6 className="px-6 py-2">B : {item.optionsHi?.B}</h6>
                    <h6 className="px-6 py-2">C : {item.optionsHi?.C}</h6>
                    <h6 className="px-6 py-2">D : {item.optionsHi?.D}</h6>{" "}
                    <h6 className="px-6 py-2">
                      {`Ans:- ${item.answerHi?.option}) ` +
                        item.answerHi?.answer}
                    </h6>
                  </div>
                </div>
                <div className="flex gap-4 flex-wrap mb-3 px-6">
                  <button
                    disabled={!checkAccess("QUESTIONPOOL", "U")}
                    onClick={() => navigate("/addQuestion", { state: item })}
                    className={`flex self-end  bg-green-500 text-white px-3 py-1 rounded-md ${
                      !checkAccess("QUESTIONPOOL", "U")
                        ? "accessDenied"
                        : "hover:cursor-pointer"
                    }`}
                  >
                    {"Edit"}
                  </button>
                  <button
                    disabled={!checkAccess("QUESTIONPOOL", "D")}
                    onClick={() => {
                      setDeleteData({
                        name: `Question with id ${item?.id}`,
                        id: item.id,
                      });
                      setOpenDeleteModal(true);
                    }}
                    className={`flex self-end  bg-red-500 text-white px-3 py-1 rounded-md ${
                      !checkAccess("QUESTIONPOOL", "D")
                        ? "accessDenied"
                        : "hover:cursor-pointer"
                    }`}
                  >
                    {"Delete"}
                  </button>
                  <div className="flex flex-col sm:flex-row gap-2">
                    <div className="bg-blue-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                      {item?.category?.name}
                    </div>
                    <div className="bg-yellow-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                      {item?.topic?.name}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center flex flex-col justify-center items-center pt-36">
          <TbServerOff className="text-[2rem] font-bold  m-2" />
          Questions Not Found
        </div>
      )}
    </div>
  );
}
