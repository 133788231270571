import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
} from "../../Utils/Constants";

export const getNotificationRequest = (payload) => {
  return {
    type: GET_NOTIFICATION_REQUEST,
    payload: payload,
  };
};

export const getNotificationSuccess = (data) => {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const getNotificationFailure = (error) => {
  return {
    type: GET_NOTIFICATION_FAILURE,
    payload: error,
  };
};
