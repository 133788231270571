import {
  UPDATE_PLAYER_REQUEST,
  UPDATE_PLAYER_SUCCESS,
  UPDATE_PLAYER_FAILURE,
} from "../../../Utils/Constants";

export const updatePlayerRequest = (payload) => {
  return {
    type: UPDATE_PLAYER_REQUEST,
    payload: payload,
  };
};

export const updatePlayerSuccess = (data) => {
  return {
    type: UPDATE_PLAYER_SUCCESS,
    payload: data,
  };
};

export const updatePlayerFailure = (error) => {
  return {
    type: UPDATE_PLAYER_FAILURE,
    payload: error,
  };
};
