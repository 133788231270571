import {
  SEND_OTP_LOGIN_REQUEST,
  SEND_OTP_LOGIN_SUCCESS,
  SEND_OTP_LOGIN_FAILURE,
  SEND_OTP_LOGIN_RESET,
  LOGOUT,
} from "../../Utils/Constants";

// Action Creators
export const sendOtpLoginRequest = (payload) => {
  return {
    type: SEND_OTP_LOGIN_REQUEST,
    payload: payload,
  };
};

export const sendOtpLoginSuccess = (data) => {
  return {
    type: SEND_OTP_LOGIN_SUCCESS,
    payload: data,
  };
};

export const sendOtpLoginFailure = (error) => {
  return {
    type: SEND_OTP_LOGIN_FAILURE,
    payload: error,
  };
};
export const sendOtpLoginReset = () => {
  return {
    type: SEND_OTP_LOGIN_RESET,
  };
};
export const logoutAction = () => {
  return {
    type: LOGOUT,
  };
};
