import {
  GET_PLAYERS_REQUEST,
  GET_PLAYERS_SUCCESS,
  GET_PLAYERS_FAILURE,
} from "../../../Utils/Constants";

const initialState = {
  allPlayer: {},
  loading: false,
  error: null,
};

const getPlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLAYERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_PLAYERS_SUCCESS:
      return {
        ...state,
        allPlayer: action.payload,
        loading: false,
        error: null,
      };

    case GET_PLAYERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getPlayerReducer;
