import {
  GET_MANAGE_ACCESS_REQUEST,
  GET_MANAGE_ACCESS_SUCCESS,
  GET_MANAGE_ACCESS_FAILURE,
  GET_MANAGE_ACCESS_SESSION,
} from "../../Utils/Constants";

const initialState = {
  manageAccess: [],
  loading: false,
  error: null,
  sessionExpired: false,
};

const manageAccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGE_ACCESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_MANAGE_ACCESS_SUCCESS:
      return {
        ...state,
        manageAccess: action.payload,
        loading: false,
        error: null,
      };
    case GET_MANAGE_ACCESS_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    case GET_MANAGE_ACCESS_SESSION:
      return {
        ...state,
        sessionExpired: state.sessionExpired,
      };
    default:
      return state;
  }
};

export default manageAccessReducer;
