import {
    SEND_OTP_LOGIN_REQUEST,
    SEND_OTP_LOGIN_SUCCESS,
    SEND_OTP_LOGIN_FAILURE,
    LOGOUT,
    SEND_OTP_LOGIN_RESET,
  } from "../../Utils/Constants"

  const initialState = {
    sendOtp: {},
    loading: false,
    error: null,
  };
  
  const sendOtpWithLoginReducer = (state = initialState, action) => {
    switch (action.type) {
      case SEND_OTP_LOGIN_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case SEND_OTP_LOGIN_SUCCESS:
        return {
          ...state,
          sendOtp: action.payload,
          loading: false,
          error: null,
        };
  
      case SEND_OTP_LOGIN_FAILURE:
        return {
          ...state,
          loading: true,
          error: action.payload,
        };
        case SEND_OTP_LOGIN_RESET:
          return {
            sendOtp: {},
            loading: false,
            error: null,
          };
      default:
        return state;
    }
  };
  
  export default sendOtpWithLoginReducer;