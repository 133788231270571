import {
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE,
} from "../../Utils/Constants";

export const sendNotificationRequest = (payload) => {
  return {
    type: SEND_NOTIFICATION_REQUEST,
    payload: payload,
  };
};

export const sendNotificationSuccess = (data) => {
  return {
    type: SEND_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const sendNotificationFailure = (error) => {
  return {
    type: SEND_NOTIFICATION_FAILURE,
    payload: error,
  };
};
