import {
    DELETE_USER_UPI_BANK_ACCOUNT_REQUEST,
    DELETE_USER_UPI_BANK_ACCOUNT_SUCCESS,
    DELETE_USER_UPI_BANK_ACCOUNT_FAILURE,
  } from "../../Utils/Constants";

  const initialState = {
    deleteBankUpiAccounts: {},
    loading: false,
    error: null,
  };
  
  const deleteBankUpiAccountsReducer = (state = initialState, action) => {
    switch (action.type) {
      case DELETE_USER_UPI_BANK_ACCOUNT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case DELETE_USER_UPI_BANK_ACCOUNT_SUCCESS:
        return {
          ...state,
          deleteBankUpiAccounts: action.payload,
          loading: false,
          error: null,
        };
      case DELETE_USER_UPI_BANK_ACCOUNT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default deleteBankUpiAccountsReducer;