import {
    DELETE_TOPIC_FAILURE,
    DELETE_TOPIC_REQUEST,
    DELETE_TOPIC_RESET,
    DELETE_TOPIC_SUCCESS,
    LOGOUT,
  } from "../Utils/Constants";
  
  // Action Creators
  export const DeleteTopicRequest = (payload) => {
    return {
      type: DELETE_TOPIC_REQUEST,
      payload:payload,
    };
  };
  
  export const DeleteTopicSuccess = (response) => {
    return {
      type: DELETE_TOPIC_SUCCESS,
      payload: response,
    };
  };
  
  export const DeleteTopicFailure = (error) => {
    return {
      type: DELETE_TOPIC_FAILURE,
      payload: error,
    };
  };

  export const DeleteTopicReset = () => {
    return {
      type: DELETE_TOPIC_RESET
    }
  }
  export const logoutAction = () => {
    return {
      type: LOGOUT,
    };
  };
  