import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  sendNotificationFailure,
  sendNotificationSuccess,
} from "../../Actions";
import { postFormData } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";

export function* sendNotificationSaga(action) {
  const path = "admin/notification/send";
  const token = action.payload.token;
  const body = action.payload.data;
  try {
    const result = yield debounce(postFormData, path, body, token);
    yield put(sendNotificationSuccess(result));
    if (result.statusCode === 201 || 200) {
      toast.success(result.message);
    } else {
      toast.warning(result.message);
    }
  } catch (err) {
    yield put(sendNotificationFailure(err));
    toast.error(err);
  }
}
