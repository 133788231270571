import {
  getAllUserWithdrawalsFailure,
  getAllUserWithdrawalsSuccess,
} from "../../Actions";
// import { GetAPI } from "../../components/Config/Apiinterface";
import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../components/Config/Apiinterface";
import { toast } from "react-toastify";

export function* getAllWithdrawalSaga(action) {
  const token = action.payload.token;
  const userId = action.payload.userId;
  const path = `admin/user/withdrawals?userId=${userId}`;

  try {
    const response = yield call(GetAPI, token, path);

    yield put(getAllUserWithdrawalsSuccess(response));
    // toast.success(response.message);
  } catch (error) {
    yield put(getAllUserWithdrawalsFailure(error));
    toast.warning(error.message);
  }
}
