import {
  GET_SCHEDULE_NOTIFICATION_FAILURE,
  GET_SCHEDULE_NOTIFICATION_REQUEST,
  GET_SCHEDULE_NOTIFICATION_SUCCESS,
} from "../../Utils/Constants";

export const getScheduleNotificationRequest = (payload) => {
  return {
    type: GET_SCHEDULE_NOTIFICATION_REQUEST,
    payload: payload,
  };
};

export const getScheduleNotificationSuccess = (data) => {
  return {
    type: GET_SCHEDULE_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const getScheduleNotificationFailure = (error) => {
  return {
    type: GET_SCHEDULE_NOTIFICATION_FAILURE,
    payload: error,
  };
};
