import {
  UPDATE_CONTACT_US_REQUEST,
  UPDATE_CONTACT_US_SUCCESS,
  UPDATE_CONTACT_US_FAILURE,
} from "../Utils/Constants";

export const updateContactUsRequest = (payload) => {
  return {
    type: UPDATE_CONTACT_US_REQUEST,
    payload: payload,
  };
};

export const updateContactUsSuccess = (data) => {
  return {
    type: UPDATE_CONTACT_US_SUCCESS,
    payload: data,
  };
};

export const updateContactUsFailure = (error) => {
  return {
    type: UPDATE_CONTACT_US_FAILURE,
    payload: error,
  };
};

export const updateContactUsEmpty = () => {
  return {
    type: "UPDATE_CONTACT_US_EMPTY",
  };
};
