import { useLocation, useNavigate } from "react-router";
import ReadMoreLess from "../ui-elements/ReadMoreLess";
import { MdArrowBack } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Loading from "../loading";
import { TbServerOff } from "react-icons/tb";

const RandomlyData = ({ userQuestions }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [userQuestionsArray, setUserQuestionsArray] = useState([]);

  useEffect(() => {
    if (userQuestions?.loading) {
      setIsLoading(true);
    }
    if (userQuestions?.error) {
      setIsLoading(false);
    }

    if (userQuestions?.userQuestions?.data?.questions) {
      setUserQuestionsArray(userQuestions?.userQuestions?.data?.questions);
      setIsLoading(false);
    }

    if (
      userQuestions?.userQuestions?.statusCode === 404 ||
      userQuestions?.userQuestions?.statusCode === 400
    ) {
      setUserQuestionsArray([]);
      setIsLoading(false);
    }
  }, [userQuestions, userQuestions?.userQuestions?.data?.statusCode]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="mt-20 ml-2 bg-white rounded-lg min-h-[55vh] gap-2 top-10">
      <div className="flex justify-between ml-2 ">
        <div className="cursor-pointer">
          <span
            onClick={() => navigate(-1)}
            className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
          >
            <MdArrowBack className="text-base" /> Back
          </span>
        </div>
      </div>
      <div className="m-2 font-bold text-2xl">USER QUESTIONS</div>
      {userQuestionsArray?.length ? (
        <>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
            {userQuestionsArray?.map((item, index) => (
              <div className="bg-white border-b " key={index}>
                <div className="flex flex-col sm:flex-row items-center px-3 py-2 sm:py-4 gap-4">
                  <h5 className="">{`Question Id #${item?.id}`}</h5>
                  {/* <h5 className="">{`Utilization Score - ${item?.selectCount}`}</h5> */}
                  <div className="flex self-center bg-green-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                    <div className="">{item?.level}</div>
                  </div>
                </div>

                <div className="flex flex-wrap">
                  <div>
                    <td className="px-6 py-2 ">
                      <ReadMoreLess
                        descriptionEn={item.descriptionEn}
                        maxLength={100}
                      />
                    </td>
                    <h6 className="px-6 py-2">A : {item.optionsEn?.A}</h6>
                    <h6 className="px-6 py-2">B : {item.optionsEn?.B}</h6>
                    <h6 className="px-6 py-2">C : {item.optionsEn?.C}</h6>
                    <h6 className="px-6 py-2">D : {item.optionsEn?.D}</h6>{" "}
                    <h6 className="px-6 py-2 flex gap-2 text-center">
                      <p className="flex justify-center text-center">
                        Selected Answer :-
                      </p>
                      <p className=" bg-yellow-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                        {" "}
                        {item.selectedAnswer
                          ? item.selectedAnswer
                          : "user not answer'd the question"}
                      </p>
                    </h6>
                  </div>
                  <div>
                    <td className="px-6 py-2 ">
                      <ReadMoreLess
                        descriptionEn={item.descriptionHi}
                        maxLength={100}
                      />
                    </td>
                    <h6 className="px-6 py-2">A : {item.optionsHi?.A}</h6>
                    <h6 className="px-6 py-2">B : {item.optionsHi?.B}</h6>
                    <h6 className="px-6 py-2">C : {item.optionsHi?.C}</h6>
                    <h6 className="px-6 py-2">D : {item.optionsHi?.D}</h6>{" "}
                    <h6 className="px-6 py-2 flex gap-2 text-center">
                      <p className="flex justify-center text-center">
                        Correct Answer :-
                      </p>
                      <p className=" bg-green-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                        {" "}
                        {item.correctAnswer}
                      </p>
                      {/* {`Correct Answer :- ` + item.correctAnswer} */}
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center flex flex-col justify-center items-center pt-36">
          <TbServerOff className="text-[2rem] font-bold  m-2" />
          Questions Not Found
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userQuestions: state?.leaderboardUserQuestionReducer,
  };
};

export default connect(mapStateToProps)(RandomlyData);
