import { useLocation } from "react-router";
import  RandomlyData  from "../../components/table/randomlyData";
import { Pagination } from "../../components/Pagination";
import { useEffect, useState } from "react";

const SelectedQuestions = () => {
  const location = useLocation();
  const stateData = location?.state;
  const {
    filteredSelectedQuestions,
    searchValue1,
    currentPage,
    totalPages1,
    isLive,
    isLoading,
  } = stateData;
  const [currentPage1, setCurrentPage1] = useState(currentPage || 1);

  useEffect(() => {
    setCurrentPage1(currentPage || 1);
  }, [currentPage]);


  return (
    <div className="p-4">
      <RandomlyData
        selectQuestion={filteredSelectedQuestions}
        isLive={isLive}
        activeTab={"activeTab"}
      />
      {totalPages1 > 1 && !searchValue1 && (
        <div className="flex justify-end pr-5 pt-4">
          <Pagination
            totalPages={totalPages1}
            currentPage={currentPage1}
            setCurrentPage={setCurrentPage1}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default SelectedQuestions;
