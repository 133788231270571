import {
    GET_USER_BY_ID_REQUEST,
    GET_USER_BY_ID_SUCCESS,
    GET_USER_BY_ID_FAILURE,
  } from "../../Utils/Constants";
  
  const initialState = {
    userById: {},
    loading: false,
    error: null,
  };
  
  const GetUserByIdReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_USER_BY_ID_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

      case GET_USER_BY_ID_SUCCESS:
        return {
          ...state,
          userById: action.payload,
          loading: false,
          error: null,
        };

      case GET_USER_BY_ID_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

      case "GET_USER_BY_ID_EMPTY":
        return {
          userById: {},
          loading: false,
          error: null,
        };

      default:
        return state;
    }
  };
  
  export default GetUserByIdReducer;
  