export const transformData = (apiResponse) => {
  const { dimensionHeaders, metricHeaders, rows, totals } = apiResponse;
  let total = 0;
  if (totals && totals.length > 0) {
    total = totals[0].metricValues[0]?.value;
  }

  return rows?.map((row) => {
    const dimensions = dimensionHeaders?.map((header, index) => ({
      [header?.name]: row?.dimensionValues[index]?.value,
    }));

    const metrics = metricHeaders?.map((header, index) => ({
      [header?.name]: row?.metricValues[index]?.value,
    }));

    return {
      ...Object.assign({}, ...dimensions),
      ...Object.assign({}, ...metrics),
      total,
    };
  });
};
