 import React, { useEffect, useState } from "react";
import ViewQuiz from "../components/viewQuiz";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

const ViewQuizScreen = ({ updateQuizStatus }) => {
  const location = useLocation();
  const [item, setItem] = useState(location.state);
  useEffect(() => {
    if (updateQuizStatus?.quizStatus?.data) {
      setItem(updateQuizStatus?.quizStatus?.data);
    }
  }, [updateQuizStatus]);

  return <ViewQuiz item={item} />;
};

const mapStateToProps = (state) => {
  return {
    updateQuizStatus: state?.updateQuizStatusReducer,
  };
};

export default connect(mapStateToProps)(ViewQuizScreen);
