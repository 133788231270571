import { Search } from "../components/Search";
import { MdArrowBack, MdRefresh } from "react-icons/md";
import { Button } from "../components/ui-elements/Button";
import { Select } from "../components/select/SelectNew";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../Utils/Constants";
import { connect, useDispatch } from "react-redux";
import { Pagination } from "../components/Pagination";
import { getAssigneeRequiest, updateContactUsRequest } from "../Actions";
import { updateContactUsEmpty } from "../Actions/UpdateContactUsAction";
import { TbServerOff } from "react-icons/tb";
import Loading from "../components/loading";

const Assign = ({ assignee, updateContactUs }) => {
  const location = useLocation();
  const item = location.state;
  const { id, assigneeId } = item;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const [sortValue, setSortValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [showInitial, setShowInitial] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assigneeData, setAssigneeData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showFilterArea, setShowFilterArea] = useState(true);
  const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  const [selectAssigneeId, setSelectAssigneeId] = useState(assigneeId);
  const [selectedId, setSelectedId] = useState(null);
  const [error, setError] = useState(false);

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const dropdownOptions = [
    {
      id: "",
      name: "All",
    },
    {
      id: "ADMIN",
      name: "Admin",
    },
    {
      id: "CUSTOMER_SUPPORT",
      name: "Customer Support",
    },
    {
      id: "MARKETING_REPRESENTATIVE",
      name: "Marketing Representative",
    },
    {
      id: "FINANCE",
      name: "Finance",
    },
  ];

  const tableHeaders = ["id", "name", "email", "Role", "Action"];

  useEffect(() => {
    if (assignee?.loading) {
      setIsLoading(true);
    }
    if (assignee?.error) {
      setIsLoading(false);
    }

    if (assignee?.assignee?.data?.assignee) {
      setAssigneeData(assignee?.assignee?.data?.assignee);
      setIsLoading(false);
    }

    if (searchValue && assignee.data) {
      setAssigneeData([assignee.data]);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (assignee?.data?.statusCode === 404 ||
        assignee?.assignee?.data?.statusCode === 400)
    ) {
      setAssigneeData([]);
      setIsLoading(false);
    }

    if (assignee?.assignee?.data?.totalPages) {
      setTotalPages(assignee?.assignee?.data?.totalPages);
    }
    setIsSubmittingFilter(false);
    // eslint-disable-next-line
  }, [assignee, assignee?.assignee?.data]);

  const getAssigneName = () => {
    const body = {
      token: token,
      pageNo: currentPage,
    };
    dispatch(getAssigneeRequiest(body));
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("customerSortValue");
    };
  }, []);

  useEffect(() => {
    getAssigneName();
  }, [item, currentPage]);

  useEffect(() => {
    if (updateContactUs?.updateContactUs?.statusCode == 200) {
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [updateContactUs]);

  const handleFilterAreaView = () => {
    if (screenWidth < 768) {
      setShowFilterArea((prev) => !prev);
    }
  };

  const handleSortValues = (id, value) => {
    if (id === "dropdownOptions_assign") {
      setSortValue(value);
    } else if (id === "statusOptions") {
      setStatusValue(value);
    }
  };

  const handleSubmitFilter = () => {
    setIsSubmittingFilter(true);
    const params = {
      token,
      pageNo: currentPage,
      sortValue,
    };
    const body = {
      ...params,
      ...(sortValue && { sortValue }),
    };
    dispatch(getAssigneeRequiest(body));
    setShowInitial(false);
    setIsSubmittingFilter(false);
  };

  const searchByAssigneName = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event?.target.value);
    }
    const body = {
      token,
      searchValue: searchParam || event?.target.value,
      pageNo: currentPage,
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(getAssigneeRequiest(body));
    }, 10);
  };
  const handleCheckboxChange = (item) => {
    if (assigneeId !== null) {
      setSelectAssigneeId((prevId) => (prevId === item.id ? null : item.id));
    } else {
      setSelectedId((prevId) => (prevId === item.id ? null : item.id));
    }
  };

  const handleClearFilter = () => {
    const body = {
      token,
      pageNo: currentPage,
    };
    dispatch(getAssigneeRequiest(body));
    setSortValue("");
    setShowInitial(true);
  };

  const updateAssignee = (assigneeId) => {
    const params = {
      token: token,
      assigneeId: assigneeId,
      id: id,
      statusValue,
    };

    const body = {
      ...params,
      ...(statusValue && { statusValue }),
    };

    dispatch(updateContactUsRequest(body));
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
        <div className="bg-white p-4 shadow-md rounded-lg">
          <div className="flex mb-2 justify-start cursor-pointer">
            <span
              onClick={() => navigate(-1)}
              className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  border border-blue-400 inline-flex items-center justify-center"
            >
              <MdArrowBack className="text-base" /> Back
            </span>
          </div>
          <div className="flex justify-between items-center">
            <div className="font-bold uppercase text-lg text-center">
              Assign
            </div>

            {screenWidth < 768 && (
              <div onClick={handleFilterAreaView}>
                <GiHamburgerMenu className="text-2xl" />
              </div>
            )}
          </div>
          {showFilterArea && (
            <div className="flex flex-col md:flex-row justify-between md:items-center mt-3">
              <div className="flex flex-col md:flex-row gap-2 md:items-center">
                <Select
                  id="dropdownOptions_assign"
                  header="Filter By"
                  options={dropdownOptions}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                />
              </div>
              <div className="flex md:gap-2 md:self-end justify-between mt-3">
                <span onClick={handleFilterAreaView}>
                  <Button
                    callbackFn={handleSubmitFilter}
                    displayName={"Submit"}
                    type={"submit"}
                    isLoading={isSubmittingFilter}
                  />
                </span>
                <span onClick={handleFilterAreaView}>
                  <Button
                    callbackFn={handleClearFilter}
                    displayName={"Reset"}
                    type={"reset"}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg pb-10 h-max gap-2">
          <div className="flex justify-between flex-wrap">
            <button onClick={getAssigneName}>
              <MdRefresh className="w-6 h-6 mt-2" />
            </button>

            <div className="flex justify-end gap-2">
              <Search
                label={`Category`}
                placeholder={"Search by name"}
                name={"Search"}
                searchCallbackfn={searchByAssigneName}
                isSearching={isLoading}
              />
            </div>
          </div>
          {assigneeData.length ? (
            <>
              <div className="relative overflow-x-auto h-max shadow-md sm:rounded-lg mt-3">
                <table className="w-full text-sm text-left text-gray-700 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      {tableHeaders?.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-start"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {assigneeData?.map((item, index) => (
                      <tr className="bg-white border-b" key={index}>
                        <td className="px-6 py-2 text-start">
                          <div className="flex gap-2">
                            <div>
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxChange(item)}
                                checked={
                                  assigneeId !== null
                                    ? selectAssigneeId === item.id
                                    : selectedId === item.id
                                }
                                className="focus:outline-none focus:ring-0"
                              />
                            </div>
                            <div>{item.id}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-start">
                          <div>
                            <div>{item.name}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-start">
                          <div>
                            <div>{item.emailId}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-start">
                          <div>{item.role}</div>
                        </td>
                        <td className="px-6 py-1 text-start italic text-gray-500">
                          {(selectAssigneeId === item.id &&
                            assigneeId !== item.id) ||
                          (assigneeId == null &&
                            selectAssigneeId === null &&
                            selectedId === item.id) ? (
                            <button
                              className="bg-green-600 text-start border text-white py-1 px-2 rounded-lg hover:bg-green-500"
                              onClick={() => updateAssignee(item.id)}
                            >
                              Confirm
                            </button>
                          ) : selectAssigneeId !== item.id &&
                            assigneeId !== item.id ? (
                            <span></span>
                          ) : (
                            <span className="italic py-1 px-2 text-start text-gray-500">
                              assigned
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalPages > 1 && !searchValue && (
                <div className="flex justify-end pr-5 pt-2">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="text-center flex flex-col justify-center items-center pt-36">
              <TbServerOff className="text-[2rem] font-bold  m-2" />
              Data Not Found
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    conatctUs: state?.ContactUsAllReducer,
    assignee: state?.AssigneeReducer,     
    updateContactUs: state?.updateContactUsReducer,
  };
};

export default connect(mapStateToProps)(Assign);
