import { put, call } from "redux-saga/effects";
import {
  postFormData,
  GetAPI,
  searchByID,
  deleteData,
  putRequestAPI,
} from "../../components/Config/Apiinterface";
import { toast } from "react-toastify";

import {
  getQuestionsSuccess,
  getQuestionsFailure,
  createQuestionFailure,
  createQuestionSuccess,
  getQuestionByIdSuccess,
  deleteQuestionByIdSuccess,
  updateQuestionSuccess,
} from "../../Actions";
import debounce from "../../Utils/useDebounce";

//-----------------get all questions and by filter-------------------
function* questionsSaga(action) {
  const token = action.payload.token;
  const level = action.payload.filterByLevel;
  const categoryId = action.payload.filterByCategory;
  const topicId = action.payload.filterByTopic || action.payload.topicId;
  const pageNo = action.payload.pageNo;
  const easyCount = action.payload.easyCount;
  const mediumCount = action.payload.mediumCount;
  const difficultCount = action.payload.difficultCount;
  const questionType = action.payload.filterByQuestionType;

  function buildURL(
    categoryId,
    level,
    topicId,
    easyCount,
    mediumCount,
    difficultCount
  ) {
    let url = `admin/quiz/question/all?pageNo=${pageNo}&pageSize=20`;
    let hasQueryParams = true;

    if (categoryId) {
      url += hasQueryParams ? "&" : "?";
      url += "categoryId=" + encodeURIComponent(categoryId);
      hasQueryParams = true;
    }

    if (level) {
      url += hasQueryParams ? "&" : "?";
      url += "level=" + encodeURIComponent(level);
      hasQueryParams = true;
    }

    if (topicId) {
      url += hasQueryParams ? "&" : "?";
      url += "topicId=" + encodeURIComponent(topicId);
      hasQueryParams = true;
    }

    if (easyCount) {
      url += hasQueryParams ? "&" : "?";
      url += "easyCount=" + encodeURIComponent(easyCount);
      hasQueryParams = true;
    }

    if (mediumCount) {
      url += hasQueryParams ? "&" : "?";
      url += "mediumCount=" + encodeURIComponent(mediumCount);
      hasQueryParams = true;
    }

    if (difficultCount) {
      url += hasQueryParams ? "&" : "?";
      url += "difficultCount=" + encodeURIComponent(difficultCount);
      hasQueryParams = true;
    }
    if (questionType) {
      url += hasQueryParams ? "&" : "?";
      url += "filterBy=" + encodeURIComponent(questionType);
      hasQueryParams = true;
    }

    return url;
  }

  const newurl = buildURL(
    categoryId,
    level,
    topicId,
    easyCount,
    mediumCount,
    difficultCount
  );

  const path = newurl;
  try {
    const result = yield debounce(GetAPI, token, path);
    yield put(getQuestionsSuccess(result));
    if (result.statusCode === 200) {
      // toast.success(result.message);
    } else {
      toast.info(result.message);
    }
  } catch (error) {
    yield put(getQuestionsFailure(error));
  }
}

export default questionsSaga;

// ---------------create new quiestion-------------------

export function* createNewQuestion(action) {
  const token = action.payload.token;
  const body = action.payload.data;
  let url;

  if (Array.isArray(body)) {
    url = "quiz/questions/addQuestions";
  } else if (typeof body === "object") {
    url = "quiz/questions";
  }
  const path = url;

  try {
    toast.loading("Uploading...");
    const result = yield debounce(postFormData, path, body, token);
    yield put(createQuestionSuccess(result));
    if (result.statusCode === 200 || 201) {
      toast.success(result.message);
    } else {
      toast.warning(result.message);
    }
  } catch (err) {
    yield put(createQuestionFailure(err));
  }
}

// ---------------get quiestions by quiestion ID-------------------

export function* searchQuiestionByIdSaga(action) {
  const questionId = action.payload.questionId;
  const path = `admin/quiz/question/id?questionId=${questionId}`;

  try {
    const quiestion = yield debounce(searchByID, action.payload.token, path);
    yield put(getQuestionByIdSuccess(quiestion));
  } catch (error) {
    yield put(getQuestionsFailure(error));
  }
}

// Worker saga for handling DeleteCtegory request

export function* deleteQuiestionsSaga(action) {
  const token = action.payload.token;
  const quiestionId = action.payload.id;
  const path = `quiz/questions?questionId=${quiestionId}`;
  try {
    const response = yield debounce(deleteData, token, path);
    toast.success(response.message);
    yield put(deleteQuestionByIdSuccess(response));
  } catch (error) {
    yield put(getQuestionsFailure(error));
  }
}

// Worker saga for handling update request
export function* updateQuiestionsSaga(action) {
  const path = "quiz/questions";

  const token = action.payload.token;
  const body = action.payload.data;
  try {
    const response = yield debounce(putRequestAPI, token, body, path);
    toast.success(response.message);
    yield put(updateQuestionSuccess(response));
  } catch (error) {
    yield put(getQuestionsFailure(error));
  }
}
