import { connect, useDispatch } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";
import { getDashboardRequest } from "../../Actions/dashboard/dashBoardGetActions";
import { PROPERTY_ID, queries, SECRET_KEY } from "../../Utils/Constants";
import CryptoJS from "crypto-js";
import { getGoogleRequest } from "../../Actions";
import { transformData } from "../../Utils/transformData";
import MobileAppCard from "../../components/Dashboard/MobileAppCard";
import Revenue from "../../components/Dashboard/Revenue";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import DashboardCard1 from "../../components/Dashboard/DashBoardCard1";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import activity from "../../assets/images/arrows.png";
import { calculateGrowthRate } from "../../Utils/grothRateFn";
import { dashBoardGetActions } from "../../Utils/barChartFunctions";
import DashBoardCard2 from "../../components/Dashboard/DashBoardCard2";
import { useNavigate } from "react-router";
import MyComponent from "../../components/Dashboard/MapComponent";
import fetchFirestoreCollection from "../../Utils/fireBaseApiCaller";
import Loading from "../../components/loading";

const Dashboard = ({ dashboard, gToken }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([[], [], [], []]);
  const [dataPreference, setDataPreference] = useState("MONTHLY");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [googleToken, setGoogleToken] = useState(null);
  const [backendData, setBackendData] = useState();
  const [barGraphData, setBarGraphData] = useState([[], [], []]);
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardData1, setDashboardData1] = useState([]);
  const [dashboardData2, setDashboardData2] = useState([]);
  const [mobileAppData, setMobileAppData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [lastVisibleAndroid, setLastVisibleAndroid] = useState(null);
  const [lastVisibleIOS, setLastVisibleIOS] = useState(null);
  const [lastVisibleBackend, setLastVisibleBackend] = useState(null);
  const [hasMoreAndroid, setHasMoreAndroid] = useState(true);
  const [hasMoreIOS, setHasMoreIOS] = useState(true);
  const [hasMoreBackend, setHasMoreBackend] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 20;
  useEffect(() => {
    setBackendData(dashboard?.data?.data);
    if (data) {
      setBarGraphData([
        data[1],
        data[3],
        backendData?.totalQuizCompleted?.monthWiseCount,
        [],
      ]);
      setMobileAppData([
        {
          platform: data[4]?.[0]?.operatingSystem,
          // version: data[4]?.[0]?.appVersion ? data[4]?.[0]?.appVersion : "--",
          version: backendData?.appVersion?.iosVersion
            ? backendData?.appVersion?.iosVersion
            : "--",
          downloads: data[4]?.[0]?.total ? data[4]?.[0]?.total : "--",
          rating: 126,
        },
        {
          platform: data[5]?.[0]?.operatingSystem,
          // version: data[5]?.[0]?.appVersion ? data[5]?.[0]?.appVersion : "--",
          version: backendData?.appVersion?.androidVersion
            ? backendData?.appVersion?.androidVersion
            : "--",
          downloads: data[5]?.[0]?.total ? data[5]?.[0]?.total : "--",
          rating: 987,
        },
      ]);
      setMapData(data[8]);
    }
    setDashboardData1([
      {
        title: "Category",
        value: backendData?.category?.totalCount,
        growthRate: backendData?.category.currentCount,
        message: true,
      },
      {
        title: "Topics",
        value: backendData?.topic.totalCount,
        growthRate: backendData?.topic.currentCount,
        message: true,
      },
      {
        title: "Questions",
        value: backendData?.question.totalCount,
        growthRate: backendData?.question?.currentCount,
        message: true,
      },
    ]);
    setDashboardData([
      {
        title: "Total Active Users",
        value: backendData?.totalActiveUser?.totalCount,
        message: "Till Now",
        ios: backendData?.totalActiveUser?.iosCount,
        android: backendData?.totalActiveUser?.androidCount,
      },
      {
        title: "Daily Active Users",
        value: backendData?.activeUser?.totalCurrentCount,
        growthRate: calculateGrowthRate(
          data[2]?.[1]?.activeUsers,
          data[2]?.[0]?.activeUsers
        ),
        message: "Compare to last Day",
        ios: backendData?.activeUser?.iosCurrentCount,
        android: backendData?.activeUser?.androidCurrentCount,
      },
      {
        title: "Total Completed Quizzes",
        value: backendData?.totalQuizCompleted?.totalCount,
        growthRate: null,
        message: "Till Now",
        freeQuiz: backendData?.totalQuizCompleted?.totalFreeCount,
        cashQuiz: backendData?.totalQuizCompleted?.totalCashCount,
      },
    ]);
    setDashboardData2([
      {
        title: "Pending Tickets",
        value: backendData?.pendingTicket,
        growthRate: null,
        message: "Count of not Assigned nor Completed Queries",
      },
      {
        title: "Active Quizzes",
        value: backendData?.activeQuiz?.totalCount,
        growthRate: null,
        message: "Count of Upcoming and Live Quizzes",
        totalCashCount: backendData?.activeQuiz?.totalCashCount,
        totalFreeCount: backendData?.activeQuiz?.totalFreeCount,
      },
    ]);
  }, [dashboard, backendData, data]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2700);
  }, []);

    const fetchMoreData = async () => {
    if (!hasMoreAndroid && !hasMoreIOS && !hasMoreBackend) return;

    try {
      // Fetch data from Firestore collections
      const [androidData, iosData, analyticsBackendData] = await Promise.all([
        hasMoreAndroid
          ? fetchFirestoreCollection("Analytics_Android", pageSize, lastVisibleAndroid)
          : { docsList: [], lastVisible: null },
        hasMoreIOS
          ? fetchFirestoreCollection("Analytics_iOS", pageSize, lastVisibleIOS)
          : { docsList: [], lastVisible: null },
        hasMoreBackend
          ? fetchFirestoreCollection("Analytics_Backend", pageSize, lastVisibleBackend)
          : { docsList: [], lastVisible: null },
      ]);

      setTableData((prevData) => [
        ...prevData,
        ...androidData.docsList,
        ...iosData.docsList,
        ...analyticsBackendData.docsList,
      ]);

      // Update lastVisible for each collection
      setLastVisibleAndroid(androidData.lastVisible);
      setLastVisibleIOS(iosData.lastVisible);
      setLastVisibleBackend(analyticsBackendData.lastVisible);

      // Update pagination flags
      if (androidData.docsList.length < pageSize) setHasMoreAndroid(false);
      if (iosData.docsList.length < pageSize) setHasMoreIOS(false);
      if (analyticsBackendData.docsList.length < pageSize) setHasMoreBackend(false);
    } catch (err) {
      setError(err.message);
    }
  };

    useEffect(() => {
      fetchMoreData();
    }, []);

  const decryptToken = useCallback(() => {
    const encrypted =
      sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
    return CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
  }, []);

  const fetchData = useCallback(async () => {
    if (!googleToken) return;
    try {
      const responses = await Promise.all(
        queries.map(async (query) => {
          const OPTIONS = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${googleToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(query),
          };

          const response = await fetch(
            `https://analyticsdata.googleapis.com/v1beta/properties/${PROPERTY_ID}:runReport?alt=json`,
            OPTIONS
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          return data;
        })
      );

      const combinedResults = responses.map((result) => transformData(result));
      setData(combinedResults);
    } catch (err) {
      setError(err);
    }
  }, [googleToken]);

  useEffect(() => {
    if (gToken?.data?.data) {
      const { accessToken } = gToken.data.data;
      setGoogleToken(accessToken);
    }
  }, [gToken]);

  useEffect(() => {
    if (googleToken) {
      fetchData();
    }
  }, [googleToken, fetchData]);

  useEffect(() => {
    const token = decryptToken();
    dispatch(getDashboardRequest({ token, data: dataPreference }));
    dispatch(getGoogleRequest({ token }));
  }, [dataPreference]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh]  px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh] ">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-[#e8f5e9] w-full min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
      <div className="bg-white p-4 w-full shadow-md rounded-lg">
        <div className="font-bold uppercase text-lg mb-2 text-start">
          Dashboard
        </div>
        {/* <BigQueryApp /> */}
        {/* <BigQuery /> */}
        {/* {fetchFirestoreCollection()} */}
        <div className="flex flex-grow flex-wrap my-3 h-fit overflow-auto justify-between">
          <Revenue
            revenue={backendData?.revenue?.currentCount}
            percentage={backendData?.revenue?.growthRate}
            onChange={(data) => setDataPreference(data)}
            dataPreference={dataPreference}
            loading={loading}
            startDate={backendData?.dateRange?.currentFromDate}
            endDate={backendData?.dateRange?.currentToDate}
          />
          {dashboardData.map((card, index) => (
            <DashboardCard
              key={index}
              title={card.title}
              value={card.value}
              percentage={card.growthRate}
              dataPrefernce={dataPreference}
              barData={dashBoardGetActions(index, barGraphData[index])}
              message={card?.message}
              freeQuiz={card?.freeQuiz}
              cashQuiz={card?.cashQuiz}
              ios={card?.ios}
              android={card?.android}
            />
          ))}
        </div>
        <div className="flex w-full h-full gap-1 mt-3 mb-3 justify-between">
          <div className="overflow-auto h-full flex flex-grow flex-wrap gap-1">
            {mobileAppData.map((device, index) => (
              <MobileAppCard
                key={index}
                platform={device.platform}
                version={device.version}
                downloads={device.downloads}
                rating={device.rating}
              />
            ))}
            {dashboardData1.map((card, index) => (
              <DashboardCard1
                key={index}
                title={card.title}
                value={card.value}
                percentage={card.growthRate}
                loading={loading}
                dataPrefernce={dataPreference}
                message={card?.message}
              />
            ))}
            {dashboardData2.map((card, index) => (
              <DashBoardCard2
                key={index}
                title={card.title}
                value={card.value}
                loading={loading}
                message={card?.message}
                totalCashCount={card?.totalCashCount}
                totalFreeCount={card?.totalFreeCount}
              />
            ))}
          </div>
        </div>
        <div className="xl:flex w-full h-full">
          <div className="xl:w-[65%] w-full items-end justify-between">
            <div className="flex w-full items-end mb-2 justify-between">
              <div className="text-[1rem] text-end font-semibold align-bottom">
                APP ANALYTICS
              </div>
              <button
                onClick={() =>
                  navigate("/analyticsActivity", { state: { tableData } })
                }
                className="bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 py-1 px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap"
              >
                Activity Tracker
                <img src={activity} className="h-6 w-6" alt="" />
              </button>
            </div>
            <div className="w-full overflow-auto rounded-md shadow-md ">
              {" "}
              <DashboardTable
                data={tableData.slice(
                  (currentPage - 1) * pageSize,
                  currentPage * pageSize
                )}
              />
            </div>
          </div>
          <div className="xl:w-[35%] w-full h-fit mx-1 border rounded-md shadow-md mt-2 xl:mt-0 p-1">
            {/* <MapComponent mapData={mapData} /> */}
            <MyComponent mapData={mapData} />
          </div>
        </div>
        {/* <Appinfo /> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.DashBoardReducer,
  gToken: state.getGoogleReducer,
});

export default connect(mapStateToProps)(Dashboard);
