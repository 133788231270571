import { useDispatch } from "react-redux";
import { ActiveTopicReset, topicsByCategoryIdReset } from "../../../Actions";

const useResetTopicReducers = () => {
  const dispatch = useDispatch();

  return {
    reset: () => {
      dispatch(topicsByCategoryIdReset());
      dispatch(ActiveTopicReset());
    },
  };
};

export default useResetTopicReducers;
