import { getUpiAccountsFailure, getUpiAccountsSuccess } from "../../Actions";
// import { GetAPI } from "../../components/Config/Apiinterface";
import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../components/Config/Apiinterface";
import { toast } from "react-toastify";

export function* getUpiAccountSaga(action) {
  const token = action.payload.token;
  const userId = action.payload.userId;
  const path = `admin/user/upiAccounts?userId=${userId}`;

  try {
    const response = yield call(GetAPI, token, path);
    yield put(getUpiAccountsSuccess(response));
    // toast.success(response.message);
  } catch (error) {
    yield put(getUpiAccountsFailure(error));
  }
}
