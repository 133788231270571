import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { resetPlayerPasswordFailure, resetPlayerPasswordSuccess } from "../../../Actions";
import { putRequestAPI } from "../../../components/Config/Apiinterface";

// Worker saga for handling DeleteCtegory request
function* resetPlayerPasswordSaga(action) {
  const token = action.payload.token;
  const body = action.payload.data;
  const path = "admin/player/changePassword";
  try {
    const response = yield call(putRequestAPI, token, body, path);
    yield put(resetPlayerPasswordSuccess(response));
    if (response.statusCode === 403) {
      toast.error(response.message);
      return;
    }
    toast.success(response.message);
  } catch (error) {
    yield put(resetPlayerPasswordFailure(error));
  }
}

export default resetPlayerPasswordSaga;

