import * as CONST from "../../Utils/Constants";

const initialState = {
  leaderboard: {},
  searchLeaderboard: {},
  loading: false,
  error: null,
};

const leaderboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.GET_ALL_LEADERBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CONST.GET_ALL_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboard: action.payload,
        loading: false,
        error: null,
      };

    case CONST.GET_ALL_LEADERBOARD_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    case CONST.GET_LEADERBOARD_BY_NAME_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CONST.GET_LEADERBOARD_BY_NAME_SUCCESS:
      return {
        ...state,
        searchLeaderboard: action.payload,
        loading: false,
        error: null,
      };

    case CONST.GET_LEADERBOARD_BY_NAME_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default leaderboardReducer;
