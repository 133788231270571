import {
  GET_MANAGE_ACCESS_REQUEST,
  GET_MANAGE_ACCESS_SUCCESS,
  GET_MANAGE_ACCESS_FAILURE,
  POST_MANAGE_ACCESS_REQUEST,
  POST_MANAGE_ACCESS_SUCCESS,
  POST_MANAGE_ACCESS_FAILURE,
} from "../../Utils/Constants";

const initialState = {
  postManageAccess: [],
  loading: false,
  error: null,
};

const manageAccessPutReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_MANAGE_ACCESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case POST_MANAGE_ACCESS_SUCCESS:
      return {
        ...state,
        postManageAccess: action.payload,
        loading: false,
        error: null,
      };

    case POST_MANAGE_ACCESS_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default manageAccessPutReducer;
