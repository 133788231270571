import { call, put } from "redux-saga/effects";
import { selectQuestionSuccess, selectQuestionFailure } from "../Actions/SelectQuestionAction";
import { postFormData } from "../components/Config/Apiinterface";
import debounce from "../Utils/useDebounce";

export function* selectQuestionSaga(action) {
    const path = "admin/quiz/question/select"
    const token = action.payload.token;
    const body = action.payload.data;
    try {
        const responce = yield debounce(postFormData, path, body, token);
        yield put(selectQuestionSuccess(responce));
    } catch (err) {
        yield put(selectQuestionFailure(err));
    }
}