import React, { useEffect, useState } from "react";
import { DatePicker, Space } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const DateRangePicker = ({
  onSelect,
  callbackFn,
  id,
  resetDateRange,
}) => {
  const [dateRange, setDateRange] = useState(
  //   () => {
  //   // Retrieve the stored date range from localStorage
  //   const storedDateRange = localStorage.getItem(`dateRange-${id}`);
  //   if (storedDateRange) {
  //     const parsedDates = JSON.parse(storedDateRange);
  //     // Convert to moment objects
  //     return [moment(parsedDates[0]), moment(parsedDates[1])];
  //   }
  //   return null;
  // }
);

  const handleDateChange = (dates, dateStrings) => {
    setDateRange(dates);
    onSelect(dates, dateStrings);
  };

  useEffect(() => {
    if (dateRange) {
      // Store the date range as an array of strings in localStorage
      const datesToStore = dateRange.map((date) => date.format());
      localStorage.setItem(`dateRange-${id}`, JSON.stringify(datesToStore));
      callbackFn(id, dateRange);
    }
  }, [dateRange, id, callbackFn]);

  useEffect(() => {
    if (resetDateRange) {
      localStorage.removeItem(`dateRange-${id}`);
      setDateRange(null);
    }
  }, [resetDateRange]);
  
  return (
    <Space direction={"vertical"} size={5}>
      <RangePicker
        className="h-[2.35rem] w-full sm:min-w-[205px] sm:max-w-[240px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500"
        onChange={handleDateChange}
        value={dateRange}
      />
    </Space>
  );
};

export default DateRangePicker;
