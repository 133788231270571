import { put, call } from "redux-saga/effects";
import { loginFailure, loginSuccess } from "../Actions";
import { loginAPI } from "../components/Config/Apiinterface";
import debounce from "../Utils/useDebounce";

// Worker saga for handling login request
function* LogInSagas(action) {
  // const { email, password } = action.payload;
  const body={
    emailId:  action.payload.email,
    password: action.payload.password
  }
  try {
    const user = yield debounce(loginAPI,body);
    yield put(loginSuccess(user));
  } catch (error) {
    yield put(loginFailure(error));
  }
}

export default LogInSagas;
