import {
  DELETE_SCHEDULE_NOTIFICATION_FAILURE,
  DELETE_SCHEDULE_NOTIFICATION_REQUEST,
  DELETE_SCHEDULE_NOTIFICATION_SUCCESS,
} from "../../Utils/Constants";

const initialState = {
  deleteScheduleNotification: {},
  loading: false,
  error: null,
};

const deleteScheduleNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_SCHEDULE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_SCHEDULE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        deleteScheduleNotification: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_SCHEDULE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default deleteScheduleNotificationReducer;
