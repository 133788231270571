import {
  GET_CONTACTUS_ALL_REQUEST,
  GET_CONTACTUS_ALL_SUCCESS,
  GET_CONTACTUS_ALL_FAILURE,
} from "../Utils/Constants";

const initialState = {
  conatctUs: {},
  loading: false,
  error: null,
};

export const ContactUsAllReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTUS_ALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CONTACTUS_ALL_SUCCESS:
      return {
        ...state,
        conatctUs: action.payload,
        loading: false,
        error: null,
      };
    case GET_CONTACTUS_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
