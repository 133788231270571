import {
  DELETE_PLAYER_REQUEST,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_FAILURE,
} from "../../../Utils/Constants";

const initialState = {
  deletePlayer: {},
  loading: false,
  error: null,
};

const deletePlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_PLAYER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PLAYER_SUCCESS:
      return {
        ...state,
        deletePlayer: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_PLAYER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default deletePlayerReducer;
