import {
  GET_COMMISSION_RATES_REQUEST,
  GET_COMMISSION_RATES_SUCCESS,
  GET_COMMISSION_RATES_FAILURE,
} from "../../Utils/Constants";

export const getCommissionRateRequest = (payload) => {
  return {
    type: GET_COMMISSION_RATES_REQUEST,
    payload: payload,
  };
};

export const getCommissionRateSuccess = (data) => {
  return {
    type: GET_COMMISSION_RATES_SUCCESS,
    payload: data,
  };
};

export const getCommissionRateFailure = (error) => {
  return {
    type: GET_COMMISSION_RATES_FAILURE,
    payload: error,
  };
};
