import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { MdArrowBack } from "react-icons/md";
import { Pagination } from "../../components/Pagination";
import Loading from "../../components/loading";
import { capitalizeWords } from "../../Utils/capitalizeFn";

const tableHeaders = ["PARTICIPANT NAME", "EMAIL", "PHONE"];

const Participants = () => {
  const location = useLocation();
  const { state } = location;
  const [participants, setParticipants] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 20;
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.eventList) {
      const allParticipants = state.eventList
        .map((event) => event.values?.participants || [])
        .flat();
      setParticipants(allParticipants);
      setTotalPages(Math.ceil(allParticipants.length / itemsPerPage));
      paginateData(allParticipants, 1);
    }
  }, [state]);

  const paginateData = (data, page) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedData(data.slice(startIndex, endIndex));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    paginateData(participants, newPage);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
      <div className="bg-white p-4 shadow-md rounded-lg min-h-[85vh] gap-2">
        <span
          onClick={() => navigate(-1)}
          className="bg-blue-100 cursor-pointer hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
        >
          <MdArrowBack className="text-base" /> Back
        </span>
        <div className="my-2 text-lg font-bold uppercase">
          Participants List
        </div>

        {paginatedData?.length ? (
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    {tableHeaders?.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-3 py-3 text-start"
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((participant, index) => (
                    <tr className="bg-white border-b" key={index}>
                      <td className="px-3 py-2 text-start">
                        {participant?.name
                          ? capitalizeWords(participant.name)
                          : "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {participant?.email || "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {participant?.phone || "Guest"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalPages > 1 && (
              <div className="flex justify-end pr-5 pt-4">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={handlePageChange}
                  isLoading={loading}
                />
              </div>
            )}
          </>
        ) : (
          <div className="text-center flex flex-col justify-center items-center pt-36">
            No Participants Found
          </div>
        )}
      </div>
    </div>
  );
};

export default Participants;
