import {
  ALL_CATEGORIES_FAILURE,
  ALL_CATEGORIES_REQUEST,
  ALL_CATEGORIES_RESET,
  ALL_CATEGORIES_SUCCESS,
} from "../Utils/Constants";

// Initial State
const initialState = {
  AllCategories: {},
  loading: false,
  error: null,
};
const AllCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        AllCategories: action.payload,
        loading: false,
        error: null,
      };
    case ALL_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ALL_CATEGORIES_RESET:
      return {
        AllCategories: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default AllCategoriesReducer;
