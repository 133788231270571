// src/redux/sagas.js

import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { putRequestAPI } from "../../components/Config/Apiinterface";
import { updateUserFailure, updateUserSuccess } from "../../Actions";

export function* updateUserSaga(action) {
  const path = "admin/user/update";

  const token = action.payload.token;
  const body = action.payload;

  try {
    const response = yield call(putRequestAPI, token, body, path);
    yield put(updateUserSuccess(response));
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    alert("Something went wrong");
    yield put(updateUserFailure(error));
  }
}

export default updateUserSaga;
