import { GiHamburgerMenu } from "react-icons/gi";
import { Select } from "../../components/select/SelectNew";
import { Button } from "../../components/ui-elements/Button";
import { useEffect, useState } from "react";
import { Pagination } from "../../components/Pagination";
import { MdArrowBack, MdRefresh } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { getNotificationRequest } from "../../Actions/notification/getNotificationAction";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import DescriptionModal from "../../components/modal/descriptionModal";
import { SendNotification } from "../../components/modal/sendNotificationModal";
import {
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../../components/constant";
import Loading from "../../components/loading";
import { TbServerOff } from "react-icons/tb";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import { useNavigate } from "react-router";

const NotificationHistory = ({ notification, sendNotification }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showFilterArea, setShowFilterArea] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [notificationArray, setNotificationArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  // const [currentPage, setCurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("notifyCurrentPage")) || 1
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [sortValue, setSortValue] = useState("");
  const [sortValue, setSortValue] = useState(
    localStorage.getItem("notifyFilterValue") || ""
  );
  const [showInitial, setShowInitial] = useState(false);
  const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  const [sendNotificationModal, setSendNotificationModal] = useState(false);
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const formatDate = (dateTimeString) => {
    const formattedDateTimeString = dateTimeString?.replace(
      /(\d{2})-(\d{2})-(\d{4})/,
      "$2/$1/$3"
    );

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const formattedDate = new Date(formattedDateTimeString).toLocaleString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const tableHeaders = ["id", "Sent At", "title", "body", "type", "role"];
  const dropdownOptions = [
    {
      id: "ALL",
      name: "All",
    },
    {
      id: "PLAYER",
      name: "Player",
    },
    {
      id: "ADMIN",
      name: "Admins",
    },
    {
      id: "INVESTOR",
      name: "Investors",
    },
    {
      id: "FINANCE",
      name: "Finance/Legal",
    },
    {
      id: "CUSTOMER_SUPPORT",
      name: "Customer Support",
    },
    {
      id: "MARKETING_REPRESENTATIVE",
      name: "Marketing",
    },
  ];

  const [expandedIds, setExpandedIds] = useState([]);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const toggleText = (id, data) => {
    setExpandedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((prevId) => prevId !== id)
        : [...prevIds, id]
    );

    if (!expandedIds.includes(id)) {
      setModalData(data);
      setOpenDescriptionModal(true);
    }
  };

  const renderText = (item) => {
    if (expandedIds.includes(item.id)) {
      return (
        <>
          {openDescriptionModal ? null : (
            <div className="flex gap-2 items-center">
              {item.body?.slice(0, 20)}
              {item.body?.length > 20 && (
                <span
                  onClick={() => toggleText(item.id, item.body)}
                  className="text-blue-500 flex text-center items-center cursor-pointer"
                >
                  {" "}
                  Read More
                </span>
              )}
            </div>
          )}
        </>
      );
    } else {
      return (
        <div className="flex gap-2  items-center">
          {item.body?.slice(0, 20)}
          {item.body?.length > 20 && (
            <span
              onClick={() => toggleText(item.id, item.body)}
              className="text-blue-500 flex  text-center items-center cursor-pointer"
            >
              {" "}
              Read More
            </span>
          )}
        </div>
      );
    }
  };
  const getNotification = () => {
    const body = {
      token: token,
      pageNo: currentPage,
      sortValue,
    };
    dispatch(getNotificationRequest(body));
    setShowInitial(true);
  };

  useEffect(() => {
    getNotification();
  }, [currentPage, sendNotificationModal]);

  useEffect(() => {
    getNotification();
    localStorage.setItem("notifyCurrentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (notification?.loading) {
      setIsLoading(true);
    }
    if (notification?.error) {
      setIsLoading(false);
    }

    if (notification?.notification?.data?.notifications) {
      setNotificationArray(notification?.notification?.data?.notifications);
      setIsLoading(false);
    }

    if (searchValue && notification?.data) {
      setNotificationArray([notification?.data]);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (notification?.notification?.statusCode === 404 ||
        notification?.notification?.statusCode === 400)
    ) {
      setNotificationArray([]);
      setIsLoading(false);
    }

    if (notification?.notification?.data?.totalPages) {
      setTotalPages(notification?.notification?.data?.totalPages);
    }
    setIsSubmittingFilter(false);
    setShowInitial(true);
    // eslint-disable-next-line
  }, [notification]);

  useEffect(() => {
    resetQuestionLocalFilters();
    resetUserLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
  }, []);

  const dropDown = localStorage.getItem("notifyDrop");

  const handleSortValues = (id, value) => {
    if (id === "dropdownOptions") {
      setSortValue(value);
      localStorage.setItem("notifyFilterValue", value);
      localStorage.setItem("notifyDrop", true);
    }
  };

  const handleSubmitFilter = () => {
    setIsSubmittingFilter(true);
    const params = {
      token,
      pageNo: currentPage,
      sortValue,
    };

    const body = {
      ...params,
      ...(sortValue && { sortValue }),
    };
    dispatch(getNotificationRequest(body));
    setShowInitial(false);
    setIsSubmittingFilter(false);
  };

  const resetFilters = () => {
    localStorage.removeItem("notifyFilterValue");
    localStorage.removeItem("notifyDrop");
    setCurrentPage(1);
  };

  const handleClearFilter = () => {
    resetFilters();
    const body = {
      token,
      pageNo: currentPage,
    };
    dispatch(getNotificationRequest(body));
    setSortValue("");
    setShowInitial(true);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
      <div className="bg-white  p-4 shadow-md rounded-lg">
        <div className="cursor-pointer">
          <span
            onClick={() => navigate(-1)}
            className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 mb-3 rounded border border-blue-400 inline-flex items-center justify-center"
          >
            <MdArrowBack className="text-base" /> Back
          </span>
        </div>
        <div className="flex justify-between items-center">
          <div className="font-bold uppercase text-lg text-center">
             Notification History
          </div>

          {screenWidth < 768 && (
            <div
            //   onClick={handleFilterAreaView}
            >
              <GiHamburgerMenu className="text-2xl" />
            </div>
          )}
        </div>
        {showFilterArea && (
          <div className="flex flex-col md:flex-row justify-between md:items-center mt-3">
            <div className="flex flex-col md:flex-row gap-2 md:items-center">
              <Select
                id="dropdownOptions"
                header="Filter By"
                options={dropdownOptions}
                callbackFn={handleSortValues}
                showInitial={showInitial}
                dropDown={dropDown}
              />
            </div>
            <div className="flex md:gap-2 md:self-end justify-between mt-3">
              <span>
                <Button
                  callbackFn={() => {
                    setCurrentPage(1);
                    handleSubmitFilter();
                  }}
                  displayName={"Submit"}
                  type={"submit"}
                  isLoading={isSubmittingFilter}
                />
              </span>
              <span>
                <Button
                  callbackFn={handleClearFilter}
                  displayName={"Reset"}
                  type={"reset"}
                />
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] gap-2">
        <div className="flex justify-between flex-wrap">
          <button onClick={getNotification}>
            <MdRefresh className="w-6 h-6 mt-2" />
          </button>

          {/* <div className="flex justify-end gap-2">
            <button
              disabled={!checkAccess("NOTIFICATION", "C")}
              onClick={() => {
                setSendNotificationModal(true);
              }}
              className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 py-1 px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                !checkAccess("NOTIFICATION", "C")
                  ? "accessDenied"
                  : "hover:cursor-pointer"
              }`}
            >
              Send Notification
              <FaPlus />
            </button>
          </div> */}
        </div>
        {notificationArray?.length ? (
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    {tableHeaders?.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-center"
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {notificationArray.map((item, index) => (
                    <tr className="bg-white border-b" key={index}>
                      <td className="px-6 py-2 text-center">{item.id}</td>
                      <td className="px-6 py-2 text-center">
                        {formatDate(item.createDate)}
                      </td>
                      <td className="px-6 py-2 text-center">{item.title}</td>
                      <td className="px-6 py-2  text-center">
                        {renderText(item)}
                      </td>
                      <td className="px-6 py-2 text-center">{item.type}</td>
                      <td className="px-6 py-2 text-center">{item.role}</td>
                    </tr>
                  ))}

                  {openDescriptionModal && (
                    <DescriptionModal
                      setOpenModal={setOpenDescriptionModal}
                      data={modalData}
                      heading="Body"
                    />
                  )}
                </tbody>
              </table>
            </div>
            {totalPages > 1 && !searchValue && (
              <div className="flex justify-end pr-5 pt-4">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={handlePageChange}
                  isLoading={isLoading}
                />
              </div>
            )}
          </>
        ) : (
          <div className="text-center flex flex-col justify-center items-center pt-36">
            <TbServerOff className="text-[2rem] font-bold  m-2" />
            Notification Not Found
          </div>
        )}
      </div>

      {sendNotificationModal && (
        <SendNotification
          setOpenModal={setSendNotificationModal}
          heading={"Send Notification"}
          userType={dropdownOptions}
          token={token}
          dispatch={dispatch}
          SendNotification={sendNotification}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    notification: state?.notificationReducer,
    sendNotification: state?.sendNotificationReducer,
  };
};

export default connect(mapStateToProps)(NotificationHistory);
