import {
  UPDATE_REVIEW_TIME_REQUEST,
  UPDATE_REVIEW_TIME_SUCCESS,
  UPDATE_REVIEW_TIME_FAILURE,
} from "../../Utils/Constants";

export const updateReviewTimeRequest = (payload) => {
  return {
    type: UPDATE_REVIEW_TIME_REQUEST,
    payload: payload,
  };
};

export const updateReviewTimeSuccess = (data) => {
  return {
    type: UPDATE_REVIEW_TIME_SUCCESS,
    payload: data,
  };
};

export const updateReviewTimeFailure = (error) => {
  return {
    type: UPDATE_REVIEW_TIME_FAILURE,
    payload: error,
  };
};
