import { put, call } from "redux-saga/effects";
import { GetAPISearch } from "../components/Config/Apiinterface";
import { searchTopicsFailure, searchTopicsSuccess } from "../Actions";
import debounce from "../Utils/useDebounce";

function* SearchTopicsSaga(action) {
  const token = action.payload.token;
  const data = action.payload;
  let path;
  if (!isNaN(data.searchValue)) {
    path = `admin/category/topic?categoryId=${data.categoryId}&topicId=${data.searchValue}`;
  } else {
    path = `admin/category/topic?categoryId=${data.categoryId}&topicName=${data.searchValue}`;
  }

  try {
    const topic = yield debounce(GetAPISearch, path, token);
    yield put(searchTopicsSuccess(topic));
  } catch (error) {
    yield put(searchTopicsFailure(error));
  }
}

export default SearchTopicsSaga;
