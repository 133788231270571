// scenarioUtils.js
export const fetchAnalyticsData = (data) => {
  const allowedScenarios = [
    "category_change",
    "topic_change",
    "quiz_selection",
    "quiz_joined",
    "quiz_started",
    "quiz_resume",
    "quiz_complete",
    "quiz_cancel",
    "quiz_schedule",
    "add_cash_success",
    "add_cash_pending",
    "add_cash_failed",
    "withdraw_cash",
    "scratch_card",
    "user_login",
    "user_registration",
    "add_cash",
  ];

  const eventsArray1 = data?.[0]?.data || [];
  const eventsArray2 = data?.[1]?.data || [];
  const eventsArray3 = data?.[2]?.data || [];

  const combinedEvents = [...eventsArray1, ...eventsArray2,...eventsArray3];
  const scenarioMap = {};

  combinedEvents.forEach((event) => {
    const scenario = event.scenario_name;
    const platform = event.platform;
    if (allowedScenarios.includes(scenario)) {
      if (scenarioMap[scenario]) {
        if (scenarioMap[scenario].platforms[platform]) {
          scenarioMap[scenario].platforms[platform].count += 1;
          scenarioMap[scenario].platforms[platform].eventDetails.push(event);
        } else {
          scenarioMap[scenario].platforms[platform] = {
            count: 1,
            eventDetails: [event],
          };
        }
      } else {
        scenarioMap[scenario] = {
          platforms: {
            [platform]: {
              count: 1,
              eventDetails: [event],
            },
          },
        };
      }
    }
  });

  const uniqueScenarios = Object.keys(scenarioMap);

  return { scenarioMap, uniqueScenarios };
};
