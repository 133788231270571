import CheckboxGroup from "./selectOption";
import { useDebouncedInput } from "../../customHook";
export default function QuestionForm(props) {
  const options = ["A", "B", "C", "D"];
  const {
    register,
    setValue,
    error,
    selectedOption,
    setSelectedOption,
    watch,
    setFlag,
    flag,
  } = props;

  const callbackFunction = (value) => {
    setTimeout(() => {
      fetch(
        `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=hi&dt=t&q=${encodeURIComponent(
          value
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (flag && data[0] && data[0][0] && data[0][0][0]) {
            setValue("descriptionHi", data[0][0][0]);
          }
        })
        .catch((err) => console.error(err));
    }, 300);
    setValue("descriptionHi", "");
  };

  const [, setInputValue] = useDebouncedInput(callbackFunction, 1000);

  const onChangeEn = (e) => {
    if (e.target.value) {
      setInputValue(e.target.value);
      setFlag(true);
    } else {
      setInputValue(e.target.value);
      setValue("descriptionHi", "");
    }
  };
  return (
    <>
      <div className="flex flex-col md:flex-row md:justify-between">
        <div className="w-full md:w-5/12 mb-4 md:mb-0">
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
              {"Question description in English"}
            </label>
            <textarea
              type="text"
              name="descriptionEn"
              {...register("descriptionEn")}
              onChange={(e) => onChangeEn(e)}
              required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1 md:mb-2  font-medium"
            />
          </div>
          <div className="mt-4">
            <span>Select Answer</span>
            <CheckboxGroup
              register={register}
              watch={watch}
              options={options}
              placholder={"Option"}
              Ans={"answerEn.option"}
              name={"optionsEn"}
              setValue={setValue}
              error={error}
            />
          </div>
        </div>
        <div className="w-full md:w-5/12">
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
              {"प्रश्न का विवरण हिंदी में"}
            </label>
            <textarea
              type="text"
              name="descriptionHi"
              {...register("descriptionHi")}
              required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1 md:mb-2  font-medium"
            />
          </div>
          <div className="mt-4">
            <span>उत्तर चुनें</span>
            <CheckboxGroup
              register={register}
              watch={watch}
              options={options}
              np
              placholder={"विकल्प"}
              Ans={"answerHi.option"}
              name={"optionsHi"}
              setValue={setValue}
              error={error}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </div>
      </div>
    </>
  );
}
