import {
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS
} from "../../Utils/Constants"
  
  const initialState = {
    resetPassword: {},
    loading: false,
    error: null,
  };
  
  const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
      case RESET_PASSWORD_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          resetPassword: action.payload,
          loading: false,
          error: null,
        };
      case RESET_PASSWORD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default resetPasswordReducer;
  