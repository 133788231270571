import {
  getUserAllPlayedQuizFailure,
  getUserAllPlayedQuizSuccess,
} from "../../Actions";
// import { GetAPI } from "../../components/Config/Apiinterface";
import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../components/Config/Apiinterface";
import { toast } from "react-toastify";

export function* getUserAllPlayedQuizSaga(action) {
  const token = action.payload.token;
  const userId = action.payload?.data?.userId;
  const pageNo = action.payload?.data?.pageNo;
  const path = `admin/user/quiz/played?pageNo=${pageNo}&pageSize=20&userId=${userId}`;

  try {
    const response = yield call(GetAPI, token, path);

    yield put(getUserAllPlayedQuizSuccess(response));
    // toast.success(response.message);
  } catch (error) {
    yield put(getUserAllPlayedQuizFailure(error));
    toast.warning(error.message);
  }
}
