import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  deleteScheduleNotificationFailure,
  deleteScheduleNotificationSuccess,
} from "../../Actions";
import { deleteData } from "../../components/Config/Apiinterface";

// Worker saga for handling DeleteCtegory request
function* deleteScheduleNotificationSaga(action) {
  const token = action.payload.token;
  const id = action.payload?.data?.id;
  const path = `/notification/schedule?id=${id}`;

  try {
    const response = yield call(deleteData, token, path);
    yield put(deleteScheduleNotificationSuccess(response));
    if (response.statusCode === 403) {
      toast.error(response.message);
      return;
    }
    toast.success(response.message);
  } catch (error) {
    yield put(deleteScheduleNotificationFailure(error));
  }
}

export default deleteScheduleNotificationSaga;
