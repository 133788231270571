import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { SpinnerLoader } from "../../components/Loaders/SpinnerLoader";
import { Input } from "../../components/constant";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { MdArrowBack } from "react-icons/md";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import { connect, useDispatch } from "react-redux";
import { AddUserRequest } from "../../Actions";
import { Select } from "../../components/select/Select";
import { toast } from "react-toastify";

const CreateUser = ({ addUser, flage }) => {
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const [dropdownOptions, setDropdownOptions] = useState([
    {
      id: "",
      name: "Select",
    },
    {
      id: "PLAYER",
      name: "Player",
    },
    {
      id: "ADMIN",
      name: "Admin",
    },
    {
      id: "INVESTOR",
      name: "Investor",
    },
    {
      id: "FINANCE",
      name: "Finance",
    },
    {
      id: "CUSTOMER_SUPPORT",
      name: "Customer Support",
    },
    {
      id: "MARKETING_REPRESENTATIVE",
      name: "Marketing Representative",
    },
  ]);
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [loading, setisLoading] = useState(addUser.loading);
  const form = useForm();
  const state = useLocation();
  const data = state.state;
  const dispetch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showInitial, setShowInitial] = useState(false);
  const [roleValue, setRoleValue] = useState("");
  const [submitForm, setSubmitForm] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSortValues = (id, value) => {
    if (id === "dropdownOptions") {
      setRoleValue(value);
    }
  };

  useEffect(() => {
    if (data === "Player") {
      setDropdownOptions((prev) => {
        return prev.filter((item) => {
          return item.name === data || item.name === "Select";
        });
      });
    }
    if (data === "manageAccess") {
      setDropdownOptions((prev) => {
        return prev.filter((item) => {
          return item.name !== "Player";
        });
      });
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = form;

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const onSubmit = (data) => {
    if (!roleValue) {
      toast.warning("Please Select Role!!");
    } else {
      const body = {
        ...data,
        role: roleValue,
        token: token,
      };
      dispetch(AddUserRequest(body));
      setSubmitForm(true);
    }
  };

  // if (addUser.addUser.statusCode === 201) {
  //   navigate(-1);
  // }

  useEffect(() => {
    if (
      isSubmitSuccessful &&
      submitForm &&
      !addUser.loading &&
      addUser?.addUser?.statusCode === 201
    ) {
      if (data === "manageAccess") {
        navigate("/manageAccess");
      } else {
        navigate("/players");
      }
    }
  }, [addUser, submitForm]);

  return (
    <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
      <div className="bg-white p-4 shadow-md rounded-lg">
        <div className="flex justify-start cursor-pointer">
          <span
            onClick={() => navigate(-1)}
            className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
          >
            <MdArrowBack className="text-base" /> Back
          </span>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center gap-2 mt-4 pb-3">
          <div className="font-bold uppercase text-lg text-center">
            Create {data === "Player" ? data : "User"}
          </div>
        </div>
        <form
          className="space-y-6 mt-4 my-12"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid gap-4 mb-4 sm:grid-cols-1 lg:grid-cols-2">
            <div className="w-full">
              <Select
                id="dropdownOptions"
                header="Select Role *"
                options={dropdownOptions}
                callbackFn={handleSortValues}
                showInitial={showInitial}
                flage="createUser"
              />
            </div>
            <div className="">
              <Input
                type="file"
                name="profilePic"
                label="Upload User Image"
                register={register}
                totalWinnings={handleImageChange}
              />
            </div>
            <div className="">
              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                label="Enter Full Name *"
                register={register}
                required
              />
            </div>
            <div className="">
              <Input
                type="email"
                name="emailId"
                placeholder="E-mail"
                label="Enter E-mail *"
                register={register}
                required
              />
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
                {"Enter Mobile no. *"}
              </label>
              <input
                type="text"
                name="mobile"
                placeholder="Mobile number"
                {...register("mobile", { required: true, maxLength: 10 })}
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1 md:mb-2 font-medium"
              />
              {errors.mobile && errors.mobile.type === "required" && (
                <span className="text-red-500">Mobile no. is required</span>
              )}
              {errors.mobile && errors.mobile.type === "maxLength" && (
                <span className="text-red-500">Max length exceeded</span>
              )}
            </div>

            <div className="py-2" data-show={showPassword}>
              <div className="relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  label="Enter Passcode *"
                  placeholder={"Passcode"}
                  register={register}
                  required
                />
                <div className="absolute inset-y-0 mt-4  right-0  pr-5 flex items-center text-sm leading-5">
                  <svg
                    className="h-6 text-gray-700 cursor-pointer"
                    fill="none"
                    onClick={togglePasswordVisibility}
                    style={{ display: showPassword ? "block" : "none" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                    ></path>
                  </svg>
                  <svg
                    className="h-6 text-gray-700 cursor-pointer"
                    fill="none"
                    onClick={togglePasswordVisibility}
                    style={{ display: showPassword ? "none" : "block" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="w-auto text-white bg-gradient-to-r from-[#4CAF50] to-[#23304C] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          >
            {addUser.loading ? <SpinnerLoader size={"sm"} /> : "Create User"}
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addUser: state?.addUserReducer,
  };
};

export default connect(mapStateToProps)(CreateUser);
