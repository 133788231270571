import { UPDATE_QUIZ_SPOT_PERCENTAGE_FAILURE,
     UPDATE_QUIZ_SPOT_PERCENTAGE_REQUEST, 
     UPDATE_QUIZ_SPOT_PERCENTAGE_SUCCESS } 
     from "../../Utils/Constants";


const initialState = {
  updateQuizSpotRate: {},
  loading: false,
  error: null,
};

const updateQuizSpotPercentageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_QUIZ_SPOT_PERCENTAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_QUIZ_SPOT_PERCENTAGE_SUCCESS:
      return {
        ...state,
        updateQuizSpotRate: action.payload,
        loading: false,
        error: null,
      };

    case UPDATE_QUIZ_SPOT_PERCENTAGE_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default updateQuizSpotPercentageReducer;
