import React, { useState, useEffect } from "react";

export const Select = ({
  id,
  header,
  initialValue,
  options,
  callbackFn,
  showInitial,
  dropDown,
  error,
  disabled,
  selectedValue,
}) => {
  const [key, setKey] = useState(0);
  const [selected, setSelectedValue] = useState(selectedValue);

  useEffect(() => {
    if (showInitial) {
      setKey((prevKey) => prevKey + 1);
      const storedValue = localStorage.getItem(`${id}_selectedValue`);

      if (storedValue) {
        setSelectedValue(storedValue);
        callbackFn(id, storedValue); // Invoke callback function with stored value
      } else {
        setSelectedValue("");
      }
    }
  }, [showInitial]);

  const handleSelectChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedValue(selectedOption);
    localStorage.setItem(`${id}_selectedValue`, selectedOption);
    callbackFn(id, selectedOption); // Invoke callback function with selected option
  };

  const handleReset = () => {
    setSelectedValue("");
    localStorage.removeItem(`${id}_selectedValue`);
    callbackFn(id, ""); // Invoke callback function with empty value
  };

  useEffect(() => {
    if (!dropDown) {
      handleReset();
    }
  }, [dropDown, error]);

  useEffect(() => {
    setSelectedValue(selectedValue);
  }, [selectedValue]);

  return (
    <div key={key}>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 md:mb-2"
      >
        {header}
      </label>
      <select
        id={id}
        disabled={disabled}
        onChange={handleSelectChange}
        value={selected}
        className={`${
          error
            ? "border-2 border-red-800 bg-red-200"
            : "border-gray-300 border"
        } ${
          disabled ? "accessDenied text-gray-400" : "text-gray-900"
        } custom-select  bg-gray-50   text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full md:w-[100px] lg:w-[160px] p-2.5`}
      >
        {initialValue && (
          <option value={""} disabled>
            {initialValue}
          </option>
        )}
        {options?.map((option, index) => (
          <option value={option.id} key={index}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};
