import {
  UPDATE_MENU_REQUEST,
  UPDATE_MENU_SUCCESS,
  UPDATE_MENU_FAILURE,
} from "../../Utils/Constants";

export const updateMenuRequest = (payload) => {
  return {
    type: UPDATE_MENU_REQUEST,
    payload: payload,
  };
};

export const updateMenuSuccess = (data) => {
  return {
    type: UPDATE_MENU_SUCCESS,
    payload: data,
  };
};

export const updateMenuFailure = (error) => {
  return {
    type: UPDATE_MENU_FAILURE,
    payload: error,
  };
};
