import React, { useState } from "react";

const ReadMoreLess = (props) => {
  const { descriptionEn, maxLength } = props;
  const [isFullDescriptionVisible, setIsFullDescriptionVisible] = useState(
    false
  );

  const toggleDescription = () => {
    setIsFullDescriptionVisible(!isFullDescriptionVisible);
  };

  return (
    <div className={`max-w-md`}>
      <p className={`${isFullDescriptionVisible ? "block" : "line-clamp-3"} ${descriptionEn?.length > maxLength && "text-center"}`}>
        {isFullDescriptionVisible
          ? descriptionEn
          : descriptionEn?.slice(0, maxLength)}
      </p>
      {descriptionEn?.length > maxLength && (
        <button
          onClick={toggleDescription}
          className="text-blue-500 flex float-right -mt-4 text-sm focus:outline-none"
        >
          {isFullDescriptionVisible ? "Read Less" : "Read More"}
        </button>
      )}
    </div>
  );
};

export default ReadMoreLess;


