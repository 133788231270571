import {
  SEARCH_TOPIC_REQUEST,
  SEARCH_TOPIC_SUCCESS,
  SEARCH_TOPIC_FAILURE,
} from "../Utils/Constants";

const initialState = {
  searchTopics: {},
  loading: false,
  error: null,
};

const SearchTopicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_TOPIC_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SEARCH_TOPIC_SUCCESS:
      return {
        ...state,
        searchTopics: action.payload,
        loading: false,
        error: null,
      };

    case SEARCH_TOPIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default SearchTopicsReducer;
