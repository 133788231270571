import {
    ACTIVE_CATEGORY_FAILURE,
    ACTIVE_CATEGORY_REQUEST,
    ACTIVE_CATEGORY_SUCCESS,
      LOGOUT,
    } from "../Utils/Constants";
    
    // Initial State
    const initialState = {
      ActiveCategory: {},
      loading: false,
      error: null,
    };
    
    // Reducer
    const ActiveCategoryReducer = (state = initialState, action) => {
      switch (action.type) {
        case ACTIVE_CATEGORY_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case ACTIVE_CATEGORY_SUCCESS:
          return {
            ...state,
            ActiveCategory: action.payload,
            loading: false,
            error: null,
          };
        case ACTIVE_CATEGORY_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
        case LOGOUT:
          return {
            ActiveCategory: null,
          };
        default:
          return state;
      }
    };
    
    export default ActiveCategoryReducer;
    