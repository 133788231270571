import {
  VERSION_POST_REQUEST,
  VERSION_POST_SUCCESS,
  VERSION_POST_FAILURE,
} from "../Utils/Constants";

// Initial State
const initialState = {
  version: {},
  loading: false,
  error: null,
};
const VersionPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERSION_POST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VERSION_POST_SUCCESS:
      return {
        ...state,
        version: action.payload,
        loading: false,
        error: null,
      };
    case VERSION_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default VersionPostReducer;
