import { put, call } from "redux-saga/effects";
import {
  putRequestAPI,
  updateContact,
} from "../components/Config/Apiinterface";
import { updateContactUsSuccess, updateContactUsFailure } from "../Actions";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

export function* updateContactUsSaga(action) {
  const token = action.payload.token;
  const assigneeId = action.payload.assigneeId;
  const id = action.payload.id;
  const statusValue = action.payload.statusValue;

  let url;

  if (assigneeId) {
    url = `contactUs/update?assigneeId=${assigneeId}&id=${id}`;
  } else {
    url = `contactUs/update?stage=${statusValue}&id=${id}`;
  }

  const path = url;

  try {
    toast.loading("Assiging Query ...");
    const response = yield call(updateContact, token, path);
    toast.dismiss();
    yield put(updateContactUsSuccess(response));
    if (response.statusCode === 200) {
     if (assigneeId) {
       toast.success("Assigned Successfully");
     } else {
       toast.success("Status Updated Successfully");
     }
    } else {
      // toast.error("Something went wrong");
    }
  } catch (error) {
    alert("Something went wrong");
    yield put(updateContactUsFailure(error));
  }
}

export default updateContactUsSaga;
