import {
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_RESET,
  DELETE_CATEGORY_SUCCESS,
  LOGOUT,
} from "../Utils/Constants";

// Action Creators
export const DeleteCategoryRequest = (payload) => {
  return {
    type: DELETE_CATEGORY_REQUEST,
    payload:payload,
  };
};

export const DeleteCategorySuccess = (response) => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload: response,
  };
};

export const DeleteCategoryFailure = (error) => {
  return {
    type: DELETE_CATEGORY_FAILURE,
    payload: error,
  };
};

export const DeleteCategoryReset = () => {
  return {
    type: DELETE_CATEGORY_RESET
  }
}

export const logoutAction = () => {
  return {
    type: LOGOUT,
  };
};
