export function getCurrentWeekDateRange() {
  // Get the current date
  const today = new Date();

  // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const currentDayOfWeek = today.getDay();

  // Calculate the difference to the previous Monday
  const diffToMonday = (currentDayOfWeek + 6) % 7;

  // Calculate the previous Monday's date
  const monday = new Date(today);
  monday.setDate(today.getDate() - diffToMonday);

  // Calculate the next Sunday's date
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);

  // Format the dates as YYYY-MM-DD
  const startDate = `${monday.getFullYear()}-${String(
    monday.getMonth() + 1
  ).padStart(2, "0")}-${String(monday.getDate()).padStart(2, "0")}`;
  const endDate = `${sunday.getFullYear()}-${String(
    sunday.getMonth() + 1
  ).padStart(2, "0")}-${String(sunday.getDate()).padStart(2, "0")}`;

  return { startDate, endDate };
}

export function getLastWeekDateRange() {
  // Get the current date
  const today = new Date();

  // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const currentDayOfWeek = today.getDay();

  // Calculate the difference to the previous Monday
  const diffToMonday = (currentDayOfWeek + 6) % 7;

  // Calculate the previous Monday's date
  const monday = new Date(today);
  monday.setDate(today.getDate() - diffToMonday - 7);

  // Calculate the next Sunday's date
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);

  // Format the dates as YYYY-MM-DD
  const startDate = `${monday.getFullYear()}-${String(
    monday.getMonth() + 1
  ).padStart(2, "0")}-${String(monday.getDate()).padStart(2, "0")}`;
  const endDate = `${sunday.getFullYear()}-${String(
    sunday.getMonth() + 1
  ).padStart(2, "0")}-${String(sunday.getDate()).padStart(2, "0")}`;

  return { startDate, endDate };
}

export function getDateRange() {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const dayBeforeYesterday = new Date(yesterday);
  dayBeforeYesterday.setDate(yesterday.getDate() - 1);

  const formattedToday = `${today.getFullYear()}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  const formattedYesterday = `${yesterday.getFullYear()}-${String(
    yesterday.getMonth() + 1
  ).padStart(2, "0")}-${String(yesterday.getDate()).padStart(2, "0")}`;
  const formattedDayBeforeYesterday = `${dayBeforeYesterday.getFullYear()}-${String(
    dayBeforeYesterday.getMonth() + 1
  ).padStart(2, "0")}-${String(dayBeforeYesterday.getDate()).padStart(2, "0")}`;

  return {
    today: formattedToday,
    yesterday: formattedYesterday,
    dayBeforeYesterday: formattedDayBeforeYesterday,
    yesterdayToToday: {
      startDate: formattedYesterday,
      endDate: formattedToday,
    },
    dayBeforeYesterdayToYesterday: {
      startDate: formattedDayBeforeYesterday,
      endDate: formattedYesterday,
    },
  };
}