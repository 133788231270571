import {
  GET_COMMISSION_RATES_REQUEST,
  GET_COMMISSION_RATES_SUCCESS,
  GET_COMMISSION_RATES_FAILURE,
} from "../../Utils/Constants";

const initialState = {
    commissionRate: {},
    loading: false,
    error: null,
  };
  
  const commissionRatesReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_COMMISSION_RATES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case GET_COMMISSION_RATES_SUCCESS:
        return {
          ...state,
          commissionRate: action.payload,
          loading: false,
          error: null,
        };
  
      case GET_COMMISSION_RATES_FAILURE:
        return {
          ...state,
          loading: true,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default commissionRatesReducer;
  