import {
  SEARCH_CATEGORIES_FAILURE,
  SEARCH_CATEGORIES_REQUEST,
  SEARCH_CATEGORIES_SUCCESS,
  SEARCH_CATEGORIES_RESET,
  LOGOUT,
} from "../Utils/Constants";

// Action for Search
export const SearchCategoriesRequest = (payload) => {
  return {
    type: SEARCH_CATEGORIES_REQUEST,
    payload: payload,
  };
};

export const SearchCategoriesSuccess = (data) => {
  return {
    type: SEARCH_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const SearchCategoriesFailure = (error) => {
  return {
    type: SEARCH_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const SearchCategoriesReset = () => {
  return {
    type: SEARCH_CATEGORIES_RESET,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT,
  };
};
