import { useDispatch } from "react-redux";
import {
  AddCategoriesReset,
  DeleteCategoryReset,
  UpdateCategoryReset,
} from "../../Actions";
import { toast } from "react-toastify";

const useCategoryAlerts = () => {
  const dispatch = useDispatch();

  return {
    showAlert: (newCategory, updateCategory, deleteCategory) => {
      if (newCategory?.newCategory?.statusCode === 201) {
        toast.success("Category Added Successfully");
        dispatch(AddCategoriesReset());
      }
      if (updateCategory?.updateCategory?.statusCode === 200) {
        toast.success("Category Updated Successfully");
        dispatch(UpdateCategoryReset());
      }
      if (deleteCategory?.response?.statusCode === 200) {
        toast.success("Category Deleted Successfully");
        dispatch(DeleteCategoryReset());
      }
      if (newCategory.error || updateCategory.error || deleteCategory.error) {
        toast.error("Something Went Wrong!!! Please Try Again");
        newCategory.error && dispatch(AddCategoriesReset());
        updateCategory.error && dispatch(UpdateCategoryReset());
        deleteCategory.error && dispatch(DeleteCategoryReset());
      }
    },
  };
};

export default useCategoryAlerts;
