import * as CONST from "../../Utils/Constants";

const initialState = {
  questions: {},
  searchQuestions: {},
  updateQuestions: {},
  newQuestion: {},
  deleteMessage: {},
  loading: false,
  error: null,
};

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.GET_ALL_QUWSTIONS_REQUEST:
      return {
        ...state,
        questions: {},
        loading: true,
        error: null,
      };
    case CONST.GET_ALL_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.payload,
        loading: false,
        error: null,
      };
    case CONST.GET_QUESTIONS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CONST.GET_QUESTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        searchQuestions: action.payload,
        loading: false,
        error: null,
      };
    case CONST.CREATE_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CONST.CREATE_QUESTIONS_SUCCESS:
      return {
        ...state,
        newQuestion: action.payload,
        loading: false,
        error: null,
      };
    case CONST.DELETE_QUESTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        deleteMessage: action.payload,
        loading: false,
        error: null,
      };
    case CONST.UPDATE_QUESTIONS_SUCCESS:
      return {
        ...state,
        deleteMessage: action.payload,
        loading: false,
        error: null,
      };
    case CONST.GET_ALL_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default questionsReducer;
