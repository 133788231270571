import React from "react";
import {
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { SpinnerLoader } from "./Loaders/SpinnerLoader";

export const Pagination = ({
  totalPages,
  currentPage,
  setCurrentPage,
  isLoading,
}) => {
  const generatePageNumbers = () => {
    const totalVisiblePages = 3;
    const pages = [];
    const ellipsis = "...";

    if (totalPages <= totalVisiblePages + 2) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - 1);
      let endPage = Math.min(totalPages, startPage + totalVisiblePages - 1);

      if (startPage > 1) {
        pages.push(1, ellipsis);
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages) {
        pages.push(ellipsis, totalPages);
      }
    }
 
    return pages;
  };

  const handleCurrentPage = (number) => {
    if (number < 1 || number > totalPages) return;
    setCurrentPage(number);
  };

  return (
    <div className="flex items-center text-sm cursor-pointer">
      <div
        onClick={() => handleCurrentPage(currentPage - 1)}
        className="flex items-center justify-center px-2 h-8 leading-tight text-gray-500 border border-gray-300 rounded-l-lg hover:text-gray-700 hover:bg-green-300"
      >
        <MdOutlineKeyboardDoubleArrowLeft />
      </div>
      {generatePageNumbers().map((pageItem, index) => (
        <div
          key={index}
          onClick={() =>
            pageItem !== "..." ? handleCurrentPage(pageItem) : null
          }
          className={`${
            pageItem === currentPage
              ? "bg-green-200 hover:bg-green-300 lg:w-8 md:w-8 sm:w-6"
              : "w-6"
          } flex justify-center overflow-hidden`}
        >
          <div
            className={`flex items-center justify-center px-2 w-8 h-8 leading-tight text-gray-500 border border-gray-300 hover:text-gray-700  hover:bg-green-300 ${
              pageItem === currentPage && isLoading
                ? "cursor-not-allowed bg-gray-200"
                : ""
            }`}
          >
            {pageItem === currentPage && isLoading ? (
              <span className="flex justify-center">
                <SpinnerLoader size="xs" />
              </span>
            ) : pageItem === "..." ? (
              ".."
            ) : (
              <span>{pageItem}</span>
            )}
          </div>
        </div>
      ))}
      <div
        onClick={() => handleCurrentPage(currentPage + 1)}
        className={`flex items-center justify-center px-2 h-8 leading-tight text-gray-500 border border-gray-300 rounded-r-lg hover:text-gray-700  hover:bg-green-300 ${
          currentPage === totalPages
            ? "cursor-not-allowed hover:bg-green-50 bg-gray-200 border-gray-300 hover:text-gray-700"
            : "cursor-pointer"
        }`}
      >
        <MdOutlineKeyboardDoubleArrowRight />
      </div>
    </div>
  );
};
