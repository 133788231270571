import {
  SELECT_QUESTION_SUCCESS,
  SELECT_QUESTION_REQUEST,
  SELECT_QUESTION_FAILURE,
} from "../Utils/Constants";

const initialState = {
  selectQuestion: {},
  loading: false,
  error: null,
};

const selectQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SELECT_QUESTION_SUCCESS:
      return {
        ...state,
        selectQuestion: action.payload,
        loading: false,
        error: null,
      };
      

    case SELECT_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default selectQuestionReducer;
