import {
  SEND_SHEDULED_NOTIFICATION_FAILURE,
  SEND_SHEDULED_NOTIFICATION_REQUEST,
  SEND_SHEDULED_NOTIFICATION_SUCCESS,
} from "../../Utils/Constants";

export const sendSheduledNotificationRequest = (payload) => {
  return {
    type: SEND_SHEDULED_NOTIFICATION_REQUEST,
    payload: payload,
  };
};

export const sendSheduledNotificationSuccess = (data) => {
  return {
    type: SEND_SHEDULED_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const sendSheduledNotificationFailure = (error) => {
  return {
    type: SEND_SHEDULED_NOTIFICATION_FAILURE,
    payload: error,
  };
};
