import { getUserByIdFailure, getUserByIdSuccess } from "../../Actions";
// import { GetAPI } from "../../components/Config/Apiinterface";
import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../../Utils/useDebounce";

export function* getUserByIdSaga(action) {
  const token = action.payload.token;
  const userId = action.payload.id;
  const path = `admin/user/id?userId=${userId}`;

  try {
    const response = yield debounce(GetAPI, token, path);
    if (response?.statusCode === 404) {
      toast.error(response?.message);
    }

    yield put(getUserByIdSuccess(response));
  } catch (error) {
    yield put(getUserByIdFailure(error));
  }
}
