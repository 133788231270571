const CheckboxBtn = ({ handleClick, label, checked, disabled }) => {
  return (
    <div className="flex flex-wrap ">
      <label className="ml-2 text-md font-medium text-gray-900 flex gap-2 items-center">
        <input
          type="checkbox"
          onClick={() => handleClick()}
          checked={checked}
          disabled={disabled}
        />
        <span>{label} </span>
      </label>
    </div>
  );
};

export default CheckboxBtn;
