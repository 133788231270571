import { MdRefresh } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
import { connect, useDispatch } from "react-redux";
import { Select } from "../../components/select/SelectNew";
import { Button } from "../../components/ui-elements/Button";
import { Search } from "../../components/Search";
import { Pagination } from "../../components/Pagination";
import { SECRET_KEY } from "../../Utils/Constants";
import { getTransactionRequest } from "../../Actions";
import DatePicker from "react-datepicker";

import {
  notificationLocalReset,
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../../components/constant";
import { FaFileExport } from "react-icons/fa6";
import DateRangePicker from "../../components/DateRangePicker";
import Loading from "../../components/loading";
import { TbServerOff } from "react-icons/tb";
import { getBaseUrl, getUrlString } from "../../components/Config/appConfig";
import GetExcelFile from "../../Utils/exportTransactionExcel";
import { checkAccess } from "../../Utils/RoleBasedRoute";

const Transections = ({ transaction }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showFilterArea, setShowFilterArea] = useState(true);
  // const [sortValue, setSortValue] = useState("");
  const [sortValue, setSortValue] = useState(
    localStorage.getItem("transFilterValue") || ""
  );
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInitial, setShowInitial] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("transCurrentPage")) || 1
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  // const [isSearchDropdown, setIsSearchDropdown] = useState("");
  const [isSearchDropdown, setIsSearchDropdown] = useState(
    localStorage.getItem("transSearchDropdown") || ""
  );
  // const [searchValue, setSearchValue] = useState("");
  const [searchValue, setSearchValue] = useState(
    localStorage.getItem("transSearchValue") || ""
  );
  const [transactionArray, setTransactionArray] = useState([]);
  const timerRef = useRef(null);
  // const [sortDropdownValue, setSortDropdownValue] = useState("");
  const [sortDropdownValue, setSortDropdownValue] = useState(
    localStorage.getItem("transSortValue") || ""
  );
  // const [sortOrderDropdown, setSortOrderDropdown] = useState(false);
  const [sortOrderDropdown, setSortOrderDropdown] = useState(
    localStorage.getItem("transSortOrder") || false
  );
  const [serchDropdown, setSearchDropdown] = useState(false);
  const [desabledSerchBox, setDesabledSerchBox] = useState(true);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
   const [exportDate, setExportDate] = useState({
     startDate: localStorage.getItem("transactionStartDate") || "",
     endDate: localStorage.getItem("transactionEndDate") || "",
   });
   const [resetDateRange, setResetDateRange] = useState(false);
   const [filter, setFilter] = useState(
     localStorage.getItem("transactionDateFilter") || ""
   );
   const [subFilter, setSubFilter] = useState(
     localStorage.getItem("transactionDateSubFilter") || ""
   );

  const dropdownOptions = [
    {
      id: "",
      name: "Recent",
    },
    {
      id: "DEPOSIT",
      name: "Deposit",
    },
    {
      id: "WITHDRAW",
      name: "Withdraw",
    },
    {
      id: "WINNINGS",
      name: "Winnings",
    },
    {
      id: "ENTRY_FEE",
      name: "Entry fees",
    },
    {
      id: "CASH_BONUS",
      name: "Cashbonus",
    },
    {
      id: "REFUND",
      name: "Refund",
    },
  ];

  const sortDropdown = [
    {
      id: "",
      name: "Recent",
    },
    {
      id: "amount",
      name: "Amount",
    },
  ];

  const sortOrder = [
    {
      id: true,
      name: "Ascending Order",
    },
    {
      id: false,
      name: "Descending Order ",
    },
  ];

  const serchDropDown = [
    {
      id: "",
      name: "Search By",
    },
    {
      id: "name",
      name: "Name",
    },
    {
      id: "emailId",
      name: "E-mail",
    },
    {
      id: "transactionId",
      name: "Transaction Id",
    },
  ];

  const tableHeaders = [
    "id",
    "transaction id",
    "user name",
    "email",
    "date & time",
    "amount",
    "type",
    "status",
  ];

  const handleFilterAreaView = () => {
    if (screenWidth < 768) {
      setShowFilterArea((prev) => !prev);
    }
  };

  const getTransaction = () => {
    const body = {
      pageNo: currentPage,
      token: token,
      sortValue,
      sortDropdownValue,
      sortOrderDropdown,
      startDate: exportDate.startDate,
      endDate: exportDate.endDate,
    };
    dispatch(getTransactionRequest(body));
    setShowInitial(true);
  };

  useEffect(() => {
    setSortValue(localStorage.getItem("transFilterValue"));
    setIsSearchDropdown(localStorage.getItem("transSearchDropdown"));
    setSearchValue(localStorage.getItem("transSearchValue"));
    setSortDropdownValue(localStorage.getItem("transSortValue"));
    setSortOrderDropdown(localStorage.getItem("transSortOrder"));
    setExportDate({
      startDate: localStorage.getItem("transactionStartDate"),
      endDate: localStorage.getItem("transactionEndDate"),
    });
    getTransaction();
  }, []);

  const resetFilterInLocal = () => {
    transactionResetLocalData();
    setCurrentPage(1);
  };

  const refreshTransactionData = () => {
    // resetFilterInLocal();
    const body = {
      pageNo: currentPage,
      token: token,
      sortValue,
      sortDropdownValue,
      sortOrderDropdown,
    };
    dispatch(getTransactionRequest(body));
    setShowInitial(true);
  };

  useEffect(() => {
    localStorage.setItem("transCurrentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    getTransaction();
  }, [currentPage]);

  useEffect(() => {
    if (transaction?.loading) {
      setIsLoading(true);
    }
    if (transaction?.error) {
      setIsLoading(false);
    }

    if (transaction?.transaction?.data?.transactions) {
      setTransactionArray(transaction?.transaction?.data?.transactions);
      setIsLoading(false);
    }

    if (searchValue && transaction?.data) {
      setTransactionArray([transaction?.data]);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (transaction?.transaction?.data?.statusCode === 404 ||
        transaction?.transaction?.data?.statusCode === 400)
    ) {
      setTransactionArray([]);
      setIsLoading(false);
    }

    if (transaction?.transaction?.data?.totalPages) {
      setTotalPages(transaction?.transaction?.data?.totalPages);
    }
    setIsSubmittingFilter(false);
    setShowInitial(true);
    // eslint-disable-next-line
  }, [transaction]);

  useEffect(() => {
    resetQuestionLocalFilters();
    resetUserLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    notificationLocalReset();
  }, []);

  const searchByTransaction = (event) => {
    if (event) {
      setSearchPage(1);
      event.preventDefault();
      setSearchValue(event?.target.value);
      localStorage.setItem("transSearchValue", event?.target.value);
    }

    const parems = {
      token,
      pageNo: searchPage,
      searchBy: event?.target.value || localStorage.getItem("transSearchValue"),
      isSearchDropdown,
      startDate: exportDate.startDate,
      endDate: exportDate.endDate,
      sortValue,
      sortDropdownValue,
      sortOrderDropdown,
    };
    const body = {
      ...parems,
      ...(isSearchDropdown && { isSearchDropdown }),
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(getTransactionRequest(body));
    }, 10);
  };

  useEffect(() => {
    if (
      isSearchDropdown === "name" ||
      isSearchDropdown === "emailId" ||
      isSearchDropdown === "transactionId"
    ) {
      setDesabledSerchBox(false);
    } else {
      setDesabledSerchBox(true);
    }
  }, [isSearchDropdown]);

  const dropDown = localStorage.getItem("transDrop");

  useEffect(() => {
    if (searchValue) {
      localStorage.setItem("transSearchValue", searchValue);
    }
  }, [searchValue]);

  const handleSortValues = (id, value) => {
    if (id === "dropdownOptions") {
      setSortValue(value);
      localStorage.setItem("transFilterValue", value);
      localStorage.setItem("transDrop", true);
    }
    if (id === "searchDropdown") {
      setIsSearchDropdown(value);
      localStorage.setItem("transSearchDropdown", value);
      localStorage.setItem("transDrop", true);
    }
    if (id === "sortBy") {
      setSortDropdownValue(value);
      localStorage.setItem("transSortValue", value);
      localStorage.setItem("transDrop", true);
    }
    if (id === "sortOrder") {
      setSortOrderDropdown(value);
      localStorage.setItem("transSortOrder", value.toString());
    }
    if(id=="transactionFilterDate"){
      localStorage.setItem("transactionStartDate", exportDate?.startDate);
      localStorage.setItem("transactionEndDate", exportDate?.endDate);

    }
  };

  useEffect(() => {
    searchByTransaction();
  }, [searchPage]);

  const handleSubmitFilter = () => {
    setIsSubmittingFilter(true);
    const params = {
      token,
      pageNo: currentPage,
      sortValue,
      sortDropdownValue,
      sortOrderDropdown,
      startDate: exportDate.startDate,
      endDate: exportDate.endDate,
    };

    const body = {
      ...params,
      ...(sortValue && { sortValue }),
      ...(sortDropdownValue && { sortDropdownValue }),
      ...(sortOrderDropdown && { sortOrderDropdown }),
      ...(exportDate.startDate && { startDate: exportDate.startDate }),
      ...(exportDate.endDate && { endDate: exportDate.endDate }),
    };

    dispatch(getTransactionRequest(body));
    setShowInitial(false);
    setIsSubmittingFilter(false);
  };

  const handleClearFilter = () => {
    setResetDateRange(true);
    resetFilterInLocal();
    const body = {
      token,
      pageNo: currentPage,
    };
    dispatch(getTransactionRequest(body));
    setSortValue("");
    setSortDropdownValue("");
    setSortOrderDropdown("");
    setSearchValue("");
    setExportDate({ startDate: "", endDate: "" });
    setShowInitial(true);
     setTimeout(() => setResetDateRange(false), 0);

  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchValue(localStorage.getItem("transSearchValue") || "");
  };

  const handleExport = async () => {
    GetExcelFile({
      token: token,
      sortBy: sortDropdownValue,
      sortOrder: sortOrderDropdown,
      searchBy: isSearchDropdown,
      searchValue: searchValue,
      filterBy: sortValue,
      startDate: exportDate?.startDate,
      endDate: exportDate?.endDate,
    });
  };

  const handleTimeChange = () => {};

  const onSelect = (date, dateString) => {
    setExportDate({
      startDate: dateString[0],
      endDate: dateString[1],
    });
  };

  function getStatusClass(status) {
    if (status === "SUCCESS" || status === "COMPLETED") {
      return "text-green-500";
    } else if (status === "PROCESSING" || status === "QUEUED") {
      return "text-yellow-500";
    } else {
      return "text-red-600";
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
        <div className="bg-white p-4 shadow-md rounded-lg">
          <div className="flex justify-between items-center">
            <div className="font-bold uppercase text-lg text-center">
              Transactions
            </div>

            {screenWidth < 768 && (
              <div onClick={handleFilterAreaView}>
                <GiHamburgerMenu className="text-2xl" />
              </div>
            )}
          </div>
          {showFilterArea && (
            <div className="flex flex-col flex-wrap md:flex-row justify-between md:items-center mt-3">
              <div className="flex flex-col md:flex-row gap-2 md:items-center">
                <Select
                  id="dropdownOptions"
                  header="Filter By"
                  options={dropdownOptions}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                  dropDown={dropDown}
                />
                <Select
                  id="sortBy"
                  header="Sort By"
                  options={sortDropdown}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                  dropDown={dropDown}
                />
                <Select
                  id="sortOrder"
                  header="Sort Order"
                  initialValue="Select Sorting Order"
                  options={sortOrder}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                  dropDown={dropDown}
                />
                <div className="flex-shrink-0 md:w-auto w-full flex-row">
                  <span className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
                    Date
                  </span>
                  <DateRangePicker
                    onSelect={onSelect}
                    callbackFn={handleSortValues}
                    id="transactionFilterDate"
                    resetDateRange={resetDateRange}
                  />
                </div>
              </div>
              <div className="flex md:gap-2 md:self-end justify-between mt-3">
                <span onClick={handleFilterAreaView}>
                  <Button
                    callbackFn={() => {
                      setCurrentPage(1);
                      handleSubmitFilter();
                    }}
                    displayName={"Submit"}
                    type={"submit"}
                    isLoading={isSubmittingFilter}
                  />
                </span>
                <span onClick={handleFilterAreaView}>
                  <Button
                    callbackFn={handleClearFilter}
                    displayName={"Reset"}
                    type={"reset"}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] gap-2">
          <div className="flex justify-between items-start flex-wrap">
            <button
              onClick={() => {
                setSearchValue("");
                setIsSearchDropdown("");
                localStorage.removeItem("transSearchValue");
                localStorage.removeItem("searchDropdown_selectedValue");
                setSearchDropdown(true);
                refreshTransactionData();
              }}
            >
              <MdRefresh className="w-6 h-6 mt-2" />
            </button>

            <div className="flex justify-end gap-2">
              <div className="-mt-2.5">
                <Select
                  id="searchDropdown"
                  options={serchDropDown}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                  dropDown={dropDown}
                  selectedValue={isSearchDropdown}
                />
              </div>
              <Search
                label={`Category`}
                placeholder={"search record by name, email or transaction id"}
                name={"Search"}
                searchCallbackfn={searchByTransaction}
                isSearching={isLoading}
                searchValue={searchValue}
                disabled={desabledSerchBox}
              />
              <button
                disabled={!checkAccess("TRANSACTION", "R")}
                onClick={() => {
                  handleExport();
                }}
                className={`bg-transparent hover:bg-[#4CAF50] px-2 justify-center  text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 md:py-1 md:px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                  !checkAccess("TRANSACTION", "R")
                    ? "accessDenied"
                    : "hover:cursor-pointer"
                }`}
              >
                Export
                <FaFileExport />
              </button>
            </div>
          </div>
          {transactionArray?.length ? (
            <>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
                <table className="w-full text-sm text-left text-gray-700">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      {tableHeaders?.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-2 py-3 text-start"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {transactionArray?.map((items, index) => (
                      <tr className="bg-white border-b" key={index}>
                        <td className="px-2 py-2 text-start">
                          <div>
                            <div>{items.id}</div>
                          </div>
                        </td>
                        <td className="px-2 py-2 text-start">
                          <div>
                            <div>{items.transactionId}</div>
                          </div>
                        </td>
                        <td className="px-2 py-2 text-start">
                          <div>
                            <div
                              className={`text-blue-400 ${
                                !checkAccess("PLAYER", "R")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                              onClick={() => {
                                if (checkAccess("PLAYER", "R")) {
                                  const item = {
                                    id: items.userId,
                                  };
                                  navigate(
                                    {
                                      pathname: "/viewPlayers",
                                    },
                                    { state: item }
                                  );
                                }
                              }}
                            >
                              {items.name}
                            </div>
                          </div>
                        </td>
                        <td className="px-2 py-2 text-start">
                          <div>
                            <div>{items.emailId}</div>
                          </div>
                        </td>
                        <td className="px-2 py-2 text-start">
                          <div>
                            <div className="min-w-28">{items.createDate}</div>
                          </div>
                        </td>
                        <td className="px-2 py-2 text-start">
                          <div>
                            <div>{items.amount.toFixed(2)}</div>
                          </div>
                        </td>
                        <td className="px-2 py-2 text-start">
                          <div>
                            <div>{items.type}</div>
                          </div>
                        </td>
                        <td
                          className={`px-2 py-2 font-semibold uppercase text-start ${getStatusClass(
                            items.status
                          )}`}
                        >
                          <div>
                            <div>{items.status}</div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {(totalPages > 1 || searchValue) && (
                <div className="flex justify-end pr-5 pt-4">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={searchValue ? searchPage : currentPage}
                    setCurrentPage={(value) =>
                      searchValue
                        ? setSearchPage(value)
                        : handlePageChange(value)
                    }
                    isLoading={isLoading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="text-center flex flex-col justify-center items-center pt-36">
              <TbServerOff className="text-[2rem] font-bold  m-2" />
              Transactions Not Found
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    transaction: state?.transactionReducer,
  };
};

export default connect(mapStateToProps)(Transections);
