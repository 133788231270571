import {
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAILURE,
} from "../../Utils/Constants";

export const getTransactionRequest = (payload) => {
  return {
    type: GET_TRANSACTION_REQUEST,
    payload: payload,
  };
};

export const getTransactionSuccess = (data) => {
  return {
    type: GET_TRANSACTION_SUCCESS,
    payload: data,
  };
};

export const getTransactionFailure = (error) => {
  return {
    type: GET_TRANSACTION_FAILURE,
    payload: error,
  };
};
