import { all, takeLatest } from "redux-saga/effects";
import * as CONST from "../Utils/Constants";
import LogInSagas from "./LogInSagas";
import CategoriesSagas from "./CategoriesSaga";
import TopicsSaga from "./TopicsSaga";
import SearchCategoriesSagas from "./SearchCategoriesSaga";
import DeleteCategorySagas from "./DeleteCategorySaga";
import AddCategorySaga from "./AddCategorySaga";
import AddTopicSaga from "./AddTopicSaga";
import DeleteTopicSaga from "./DeleteTopicSaga";
import UpdateCategorySaga from "./UpdateCategorySaga";
import UpdateTopicSaga from "./UpdateTopicSaga";
import ActiveCategorySaga from "./ActiveCategorySaga";
import ActiveTopicSaga from "./ActiveTopicSaga";
import GetTopicByCatergoryIdSaga from "./TopicsByCategoryIdSaga";
import SearchTopicsSaga from "./SearchTopicsSaga";
import quizSaga from "./QuizSaga";
import SearchQuizSaga from "./SearchQuizSaga";
import deleteQuizSaga from "./DeleteQuizSaga";
import updateQuizSaga from "./UpdateQuizSaga";
import updateQuizeStatusSagas from "./updateQuizeStatusSaga";
import questionsSaga, {
  createNewQuestion,
  searchQuiestionByIdSaga,
  deleteQuiestionsSaga,
  updateQuiestionsSaga,
} from "./questionSaga/getAllQuestionsSaga";

import { addQuizSaga } from "./AddQuizSaga";
import { selectQuestionSaga } from "./SelectQuestionSaga";
import { cancelQuizSaga } from "./CancelQuizSaga";
import {
  leaderboardSaga,
  searchLeaderboardSaga,
} from "./leaderboardSaga/leaderboardSaga";
import { selectQuestionByQuizIdSaga } from "./SelectQuestionByQuizIdSaga";
import { randomQuestionSaga } from "./RandomQuestionSaga";
import { addUserSaga } from "./User/addUserSaga";
import { updateUserSaga } from "./User/updateUserSaga";
import { getUserByIdSaga } from "./User/getUserByIdSaga";
import resetPasswordSaga from "./User/resetPasswordSaga";
import { addBalanceSaga } from "./User/addBalanceSaga";
import activeUserSaga from "./User/activeUserSaga";
import deleteMultipulQuestionsSaga from "./DeleteMultipulQuestionsSaga";
import { getAllWithdrawalSaga } from "./User/getAllWithdrawalsSaga";
import { getBankAccountSaga } from "./User/getBankAccountSaga";
import { getUpiAccountSaga } from "./User/getUpiAccountSaga";
import { getUserAllSaga } from "./User/user_all/getUserAllSaga";
import deleteUserSaga from "./User/deleteUserSaga";
import deleteBankUpiAccountsSaga from "./User/deleteBankUpiAccountsSaga";
import { getUserAllPlayedQuizSaga } from "./User/getAllUserPlayedQuizSaga";
import { getUserAllTransectionsSaga } from "./User/getUserAllTransectionsSaga";
import contactUsAllSaga from "./ContactUsAllSaga";
import AssigneeSaga from "./AssigneeSaga";
import updateContactUsSaga from "./UpdateContactUsSaga";
import { notificationSaga } from "./notification/getNotificationSaga";
import { sendNotificationSaga } from "./notification/sendNotificationSaga";
import transactionSaga from "./Transaction/transactionSaga";
import { leaderboardUserQuestionSaga } from "./leaderboardSaga/leaderboardUserQuestionsSaga";
import activityLogSaga from "./settings/activityLogSaga";
import { getCommissionRateSaga } from "./settings/commissionRatesSaga";
import { updateCommissionRateSaga } from "./settings/updateCommissionRateSaga";
import { getReviewTimeSaga } from "./settings/reviewTimeSaga";
import { updateReviewTimeSaga } from "./settings/updateReviewTimeSaga";
import { menuSaga } from "./settings/menuSaga";
import { updateMenuSaga } from "./settings/updateMenuSaga";
import sendOtpWithLoginSaga from "./login/sendOtpWithLoginSaga";
import verifyOtpLoginSaga from "./login/verifyOtpWithSaga";
import versionPostSaga from "./versionPostSaga";
import versionGetSaga from "./versionGetSaga";
import { getPlayerAllSaga } from "./User/user_all/getPlayerAllSaga";
import { getManageAccessSaga } from "./settings/getManageAccessSaga";
import { postManageAccessSaga } from "./settings/postManageAccessSaga";
import dashboardSaga from "./dashboard/dashboardSaga";
import getGoogleSaga from "./dashboard/getGoogleSaga";
import { serverInfoSaga } from "./settings/serverInfoSaga";
import versionDeleteSaga from "./versionDeleteSaga";
import deletePlayerSaga from "./User/player_all/deletePlayerSaga";
import resetPlayerPasswordSaga from "./User/player_all/resetPlayerPasswordSaga";
import updatePlayerSaga from "./User/player_all/updatePlayerSaga";
import { getQuizSpotPercntageSaga } from "./settings/quizSpotPercentageSaga";
import { updateQuizSpotPercentageSaga } from "./settings/updateQuizSpotPercentageSaga";
import { sendSheduledNotificationSaga } from "./notification/sendSheduledNotificationSaga";
import updateScheduleNotificationSaga from "./notification/updateSheduledNotificationSaga";
import deleteScheduleNotificationSaga from "./notification/deleteScheduleNotificationSaga";
import { scheduleNotificationSaga } from "./notification/getScheduleNotificationSaga";
import { getQuizBotsProbabilitySaga } from "./settings/quizBotsProbabilitySaga";
import { updateQuizBotsProbabilitySaga } from "./settings/updateQuizBotsProbabilitySaga";

export default function* root() {
  yield all([takeLatest(CONST.LOGIN_REQUEST, LogInSagas)]);
  yield all([takeLatest(CONST.ALL_CATEGORIES_REQUEST, CategoriesSagas)]);
  yield all([takeLatest(CONST.TOPICS_REQUEST, TopicsSaga)]);
  yield all([takeLatest(CONST.DELETE_CATEGORY_REQUEST, DeleteCategorySagas)]);
  yield all([takeLatest(CONST.ADD_CATEGORY_REQUEST, AddCategorySaga)]);
  yield all([takeLatest(CONST.ADD_TOPIC_REQUEST, AddTopicSaga)]);
  yield all([takeLatest(CONST.DELETE_TOPIC_REQUEST, DeleteTopicSaga)]);
  yield all([takeLatest(CONST.UPDATE_CATEGORY_REQUEST, UpdateCategorySaga)]);
  yield all([takeLatest(CONST.UPDATE_TOPIC_REQUEST, UpdateTopicSaga)]);
  yield all([takeLatest(CONST.ACTIVE_CATEGORY_REQUEST, ActiveCategorySaga)]);
  yield all([takeLatest(CONST.ACTIVE_TOPIC_REQUEST, ActiveTopicSaga)]);
  yield all([takeLatest(CONST.SEARCH_TOPIC_REQUEST, SearchTopicsSaga)]);
  yield all([takeLatest(CONST.QUIZ_REQUEST, quizSaga)]);
  yield all([takeLatest(CONST.SEARCH_QUIZ_REQUEST, SearchQuizSaga)]);
  yield all([takeLatest(CONST.DELETE_QUIZ_REQUEST, deleteQuizSaga)]);
  yield all([takeLatest(CONST.UPDATE_QUIZ_REQUEST, updateQuizSaga)]);
  yield all([takeLatest(CONST.GET_ALL_QUWSTIONS_REQUEST, questionsSaga)]);
  yield all([takeLatest(CONST.CREATE_QUESTIONS_REQUEST, createNewQuestion)]);
  yield all([takeLatest(CONST.UPDATE_QUESTIONS_REQUEST, updateQuiestionsSaga)]);
  yield all([takeLatest(CONST.ADD_QUIZ_REQUEST, addQuizSaga)]);
  yield all([takeLatest(CONST.SELECT_QUESTION_REQUEST, selectQuestionSaga)]);

  yield all([
    takeLatest(CONST.DELETE_QUESTIONS_BY_ID_REQUEST, deleteQuiestionsSaga),
  ]);
  yield all([
    takeLatest(CONST.SEARCH_CATEGORIES_REQUEST, SearchCategoriesSagas),
  ]);

  yield all([
    takeLatest(CONST.GET_QUESTIONS_BY_ID_REQUEST, searchQuiestionByIdSaga),
  ]);

  yield all([
    takeLatest(CONST.TOPIC_BY_CATEGORYID_REQUEST, GetTopicByCatergoryIdSaga),
  ]);

  yield all([
    takeLatest(CONST.ACTIVE_QUIZZES_STATUS_REQUEST, updateQuizeStatusSagas),
  ]);
  yield all([takeLatest(CONST.CANCEL_QUIZ_REQUEST, cancelQuizSaga)]);

  yield all([takeLatest(CONST.GET_ALL_LEADERBOARD_REQUEST, leaderboardSaga)]);

  yield all([
    takeLatest(CONST.GET_LEADERBOARD_BY_NAME_REQUEST, searchLeaderboardSaga),
  ]);

  yield all([
    takeLatest(
      CONST.GET_SELECT_QUESTION_BY_ID_REQUEST,
      selectQuestionByQuizIdSaga
    ),
  ]);

  yield all([
    takeLatest(CONST.GET_RANDOM_QUESTION_REQUEST, randomQuestionSaga),
  ]);
  yield all([takeLatest(CONST.ADD_USER_REQUEST, addUserSaga)]);

  yield all([takeLatest(CONST.UPDATE_USER_REQUEST, updateUserSaga)]);

  yield all([takeLatest(CONST.GET_USER_BY_ID_REQUEST, getUserByIdSaga)]);

  yield all([takeLatest(CONST.RESET_PASSWORD_REQUEST, resetPasswordSaga)]);

  yield all([takeLatest(CONST.ADD_BALANCE_REQUEST, addBalanceSaga)]);

  yield all([takeLatest(CONST.ACTIVE_USER_REQUEST, activeUserSaga)]);





  yield all([
    takeLatest(
      CONST.DELETE_MULTIPUL_QUESTIONS_REQUEST,
      deleteMultipulQuestionsSaga
    ),
  ]);

  yield all([
    takeLatest(CONST.GET_ALL_USER_WITHDRAWALS_REQUEST, getAllWithdrawalSaga),
  ]);

  yield all([
    takeLatest(CONST.GET_USER_BANK_ACCOUNT_REQUEST, getBankAccountSaga),
  ]);

  yield all([
    takeLatest(CONST.GET_USER_UPI_ACCOUNT_REQUEST, getUpiAccountSaga),
  ]);

  yield all([takeLatest(CONST.GET_USER_ALL_REQUEST, getUserAllSaga)]);


  yield all([takeLatest(CONST.DELETE_USER_REQUEST, deleteUserSaga)]);

  yield all([takeLatest(CONST.GET_PLAYER_ALL_REQUEST, getPlayerAllSaga)]);

  yield all([takeLatest(CONST.DELETE_PLAYER_REQUEST, deletePlayerSaga)]);

  yield all([takeLatest(CONST.DELETE_SCHEDULE_NOTIFICATION_REQUEST, deleteScheduleNotificationSaga)]);

  yield all([takeLatest(CONST.UPDATE_PLAYER_REQUEST, updatePlayerSaga)]);

  yield all([takeLatest(CONST.UPDATE_SCHEDULE_NOTIFICATION_REQUEST, updateScheduleNotificationSaga)]);

  yield all([takeLatest(CONST.GET_SCHEDULE_NOTIFICATION_REQUEST, scheduleNotificationSaga)]);

  yield all([takeLatest(CONST.RESET_PLAYER_PASSWORD_REQUEST, resetPlayerPasswordSaga)]);

  yield all([
    takeLatest(
      CONST.DELETE_USER_UPI_BANK_ACCOUNT_REQUEST,
      deleteBankUpiAccountsSaga
    ),
  ]);

  yield all([
    takeLatest(
      CONST.GET_USER_ALL_PLAYED_QUIZ_REQUEST,
      getUserAllPlayedQuizSaga
    ),
  ]);

  yield all([
    takeLatest(
      CONST.GET_USER_ALL_TRANSACTIONS_REQUEST,
      getUserAllTransectionsSaga
    ),
  ]);

  yield all([takeLatest(CONST.GET_CONTACTUS_ALL_REQUEST, contactUsAllSaga)]);

  yield all([takeLatest(CONST.GET_ASSIGNEE_ALL_REQUEST, AssigneeSaga)]);

  yield all([takeLatest(CONST.UPDATE_CONTACT_US_REQUEST, updateContactUsSaga)]);

  yield all([takeLatest(CONST.GET_NOTIFICATION_REQUEST, notificationSaga)]);

  yield all([
    takeLatest(CONST.SEND_NOTIFICATION_REQUEST, sendNotificationSaga),
  ]);

  yield all([
    takeLatest(CONST.SEND_SHEDULED_NOTIFICATION_REQUEST, sendSheduledNotificationSaga),
  ]);

  yield all([takeLatest(CONST.GET_TRANSACTION_REQUEST, transactionSaga)]);

  yield all([
    takeLatest(CONST.GET_USER_QUIESTION_REQUEST, leaderboardUserQuestionSaga),
  ]);

  yield all([takeLatest(CONST.GET_ACTIVITY_LOG_REQUEST, activityLogSaga)]);

  yield all([
    takeLatest(CONST.GET_COMMISSION_RATES_REQUEST, getCommissionRateSaga),
  ]);

  yield all([
    takeLatest(CONST.GET_QUIZ_BOTS_PROBABILITY_REQUEST, getQuizBotsProbabilitySaga),
  ]);
  yield all([
    takeLatest(CONST.GET_QUIZ_SPOT_PERCENTAGE_REQUEST, getQuizSpotPercntageSaga),
  ]);
  yield all([
    takeLatest(CONST.UPDATE_QUIZ_SPOT_PERCENTAGE_REQUEST, updateQuizSpotPercentageSaga),
  ]);

  yield all([
    takeLatest(CONST.UPDATE_COMMISSION_RATES_REQUEST, updateCommissionRateSaga),
  ]);

  yield all([
    takeLatest(CONST.UPDATE_QUIZ_BOTS_PROBABILITY_REQUEST, updateQuizBotsProbabilitySaga),
  ]);

  yield all([takeLatest(CONST.GET_REVIEW_TIME_REQUEST, getReviewTimeSaga)]);

  yield all([
    takeLatest(CONST.UPDATE_REVIEW_TIME_REQUEST, updateReviewTimeSaga),
  ]);

  yield all([takeLatest(CONST.GET_MENU_REQUEST, menuSaga)]);
  yield all([takeLatest(CONST.VERSION_POST_REQUEST, versionPostSaga)]);
  yield all([takeLatest(CONST.VERSION_GET_REQUEST, versionGetSaga)]);

  yield all([takeLatest(CONST.UPDATE_MENU_REQUEST, updateMenuSaga)]);

  yield all([takeLatest(CONST.SEND_OTP_LOGIN_REQUEST, sendOtpWithLoginSaga)]);

  yield all([takeLatest(CONST.VERIFY_OTP_LOGIN_REQUEST, verifyOtpLoginSaga)]);
  yield all([takeLatest(CONST.GET_MANAGE_ACCESS_REQUEST, getManageAccessSaga)]);
  yield all([
    takeLatest(CONST.POST_MANAGE_ACCESS_REQUEST, postManageAccessSaga),
  ]);
  yield all([takeLatest(CONST.GET_DASHBOARD_REQUEST, dashboardSaga)]);
  yield all([takeLatest(CONST.GET_GOOGLE_REQUEST, getGoogleSaga)]);
  yield all([takeLatest(CONST.GET_SERVER_REQUEST, serverInfoSaga)]);
  yield all([takeLatest(CONST.DELETE_VERSION_REQUEST, versionDeleteSaga)]);
}
