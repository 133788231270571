import {
  ACTIVE_TOPIC_FAILURE,
  ACTIVE_TOPIC_REQUEST,
  ACTIVE_TOPIC_SUCCESS,
  ACTIVE_TOPIC_RESET,
  LOGOUT,
} from "../Utils/Constants";

// Initial State
const initialState = {
  ActiveTopic: {},
  loading: false,
  error: null,
};

// Reducer
const ActiveActiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_TOPIC_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTIVE_TOPIC_SUCCESS:
      return {
        ...state,
        ActiveTopic: action.payload,
        loading: false,
        error: null,
      };
    case ACTIVE_TOPIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTIVE_TOPIC_RESET:
      return {
        ActiveTopic: {},
        loading: false,
        error: null,
      };
    case LOGOUT:
      return {
        ActiveTopic: null,
      };
    default:
      return state;
  }
};

export default ActiveActiveReducer;
