// import React, { useState, useEffect } from "react";
// import {
//   MapContainer,
//   TileLayer,
//   CircleMarker,
//   Popup,
//   useMapEvents,
// } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// const MapComponent = ({ mapData }) => {
//   const [zoomLevel, setZoomLevel] = useState(4.2);
//   const [hoveredCity, setHoveredCity] = useState(null);
//   const [clickedCity, setClickedCity] = useState(null);

//   const handleZoom = (event) => {
//     const { target } = event;
//     setZoomLevel(target.getZoom());
//   };

//   const handleMouseOver = (city) => {
//     setHoveredCity(city);
//   };

//   const handleMouseOut = () => {
//     setHoveredCity(null);
//   };

//   const handleClick = (city) => {
//     setClickedCity(city);
//   };

//   const MapEventsHandler = () => {
//     useMapEvents({
//       zoomend: handleZoom,
//     });
//     return null;
//   };

//   return (
//     <MapContainer
//       center={[23.888687, 82.155279]} // Center of India for initial view
//       zoom={zoomLevel}
//       style={{ height: "400px", width: "100%", zIndex: 0 }}
//     >
//       <TileLayer
//         url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
//         attribution="&copy; <a href='https://carto.com/attributions'>CARTO</a>"
//       />

//       <MapEventsHandler />

//       {mapData?.map((location, index) => {
//         const { city, activeUsers } = location;
//         const radius = Math.max(
//           5,
//           parseInt(activeUsers) * 0.1 * (zoomLevel / 10)
//         );

//         return true ? (
//           <CircleMarker
//             key={index}
//             radius={radius}
//             color="green"
//             fillColor="green"
//             fillOpacity={0.3}
//             stroke={false}
//             city={city}
//             eventHandlers={{
//               mouseover: () => handleClick(city),
//               mouseout: handleMouseOut,
//               click: () => handleClick(city),
//             }}
//           >
//             <Popup>
//               <b>{city}</b>
//               <br />
//               Active Users: {activeUsers}
//             </Popup>
//           </CircleMarker>
//         ) : null;
//       })}
//     </MapContainer>
//   );
// };

// export default MapComponent;

import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  CircleMarker,
  Popup,
  useMap,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const MapComponent = ({ mapData }) => {
  const [zoomLevel, setZoomLevel] = useState(5);
  const [hoveredCity, setHoveredCity] = useState(null);
  const [clickedCity, setClickedCity] = useState(null);

  const handleZoom = (event) => {
    const { target } = event;
    setZoomLevel(target.getZoom());
  };

  const handleMouseOver = (city) => {
    setHoveredCity(city);
  };

  const handleMouseOut = () => {
    setHoveredCity(null);
  };

  const handleClick = (city) => {
    setClickedCity(city);
  };

  const MapEventsHandler = () => {
    useMapEvents({
      zoomend: handleZoom,
    });
    return null;
  };

  // Component to handle fitting bounds based on map data
  const FitBoundsComponent = () => {
    const map = useMap();

    useEffect(() => {
      if (mapData?.length > 0) {
        const bounds = L.latLngBounds(
          mapData?.map((location) => [location.lat, location.lng])
        );
        map.fitBounds(bounds);
      }
    }, [mapData, map]);

    return null; // This component does not render anything visible
  };

  const filteredMapData = mapData?.filter(
    (location) => location.city !== "(not set)"
  );

  return (
    <MapContainer
      center={[23.888687, 82.155279]}
      zoom={zoomLevel}
      style={{ height: "400px", width: "100%", zIndex: 0 }}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution="&copy; <a href='https://carto.com/attributions'>CARTO</a>"
      />
      <MapEventsHandler />
      <FitBoundsComponent />{" "}
      {/* Ensure this is a direct child of MapContainer */}
      {filteredMapData?.map((location, index) => {
        const { city, activeUsers } = location;
        const radius = Math.max(
          10,
          parseInt(activeUsers) * 0.2 * (zoomLevel / 10)
        );

        return (
          <CircleMarker
            key={index}
            radius={radius}
            color="green"
            fillColor="green"
            fillOpacity={0.5}
            stroke={false}
            eventHandlers={{
              mouseover: () => handleMouseOver(city),
              mouseout: handleMouseOut,
              click: () => handleClick(city),
            }}
          >
            <Popup>
              <b>{city}</b>
              <br />
              Active Users: {activeUsers}
            </Popup>
          </CircleMarker>
        );
      })}
    </MapContainer>
  );
};

export default MapComponent;
