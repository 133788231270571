import React from "react";
import { getUrlString } from "../components/Config/appConfig";
import { toast } from "react-toastify";
const GetExcelFile = async (data) => {
  const token = data?.token;
  const difficultCount = data?.difficultCount;
  const mediumCount = data?.mediumCount;
  const easyCount = data?.easyCount;
  const topicId = data?.topicId;
  const filterBy = data?.filterBy;
  const categoryId = data?.categoryId;
  const sortOrder = data?.sortOrder;
  const level = data?.level;
  const sortBy = data?.sortBy;
  const searchBy = data?.searchBy;
  const searchValue = data?.searchValue;
  function buildURL(
    difficultCount,
    mediumCount,
    easyCount,
    topicId,
    filterBy,
    categoryId,
    sortOrder,
    level,
    sortBy,
    searchBy,
    searchValue
  ) {
    let url = ``;
    url = `admin/quiz/question/all/export?pageNo=${0}&pageSize=${0}`;

    if (difficultCount) {
      url += "&difficultCount=" + encodeURIComponent(difficultCount);
    }

    if (mediumCount) {
      url += "&mediumCount=" + encodeURIComponent(mediumCount);
    }

    if (easyCount) {
      url += "&easyCount=" + encodeURIComponent(easyCount);
    }

    if (topicId) {
      url += "&topicId=" + encodeURIComponent(topicId);
    }

    if (filterBy) {
      url += "&filterBy=" + encodeURIComponent(filterBy);
    }

    if (categoryId) {
      url += "&categoryId=" + encodeURIComponent(categoryId);
    }
    if (sortOrder) {
      url += "&sortOrder=" + encodeURIComponent(sortOrder);
    }
    if (level) {
      url += "&level=" + encodeURIComponent(level);
    }
    if (sortBy) {
      url += "&sortBy=" + encodeURIComponent(sortBy);
    }
    if (searchBy) {
      url += "&searchBy=" + encodeURIComponent(searchBy);
    }
    if (searchValue) {
      url += "&searchValue=" + encodeURIComponent(searchValue);
    }

    return url;
  }

  const newurl = buildURL(
    difficultCount,
    mediumCount,
    easyCount,
    topicId,
    filterBy,
    categoryId,
    sortOrder,
    level,
    sortBy,
    searchBy,
    searchValue
  );

  try {
    const options = {
      method: "GET",
      headers: {
        Accesstoken: token,
        accept: "*/*",
      },
    };
    toast.loading("Preparing Excel ...");
    const response = await fetch(`${getUrlString()}${newurl}`, options);
    toast.dismiss();
    if (!response.ok) {
      if (response.status === 403) {
        window.location.href = "/blocked";
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    let fileName = "";
    if (searchValue) {
      fileName += "Searched_" + searchValue + "_";
    }
    if (difficultCount) {
      fileName += difficultCount + "_";
    }
    if (mediumCount) {
      fileName += mediumCount + "_";
    }
    if (easyCount) {
      fileName += easyCount + "_";
    }
    if (topicId) {
      const tName = data.allTopics.filter((item) => item.id == topicId);

      fileName += tName[0].name + "_";
    }
    if (categoryId) {
      const cName = data.allCategories.filter((item) => item.id == topicId);

      fileName += cName[0].name + "_";
    }
    if (sortOrder) {
      fileName += sortOrder + "_";
    }
    if (sortBy) {
      fileName += sortBy + "_";
    }
    if (filterBy) {
      fileName += filterBy + "_";
    }
    if (level) {
      fileName += level + "_";
    }
    if (fileName.endsWith("_")) {
      fileName = fileName.slice(0, -1);
    }
    a.download = fileName + "Questions.xlsx";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error exporting file:", error);
  }
};

export default GetExcelFile;
