import {
  ACTIVE_QUIZZES_STATUS_REQUEST,
  ACTIVE_QUIZZES_STATUS_SUCCESS,
  ACTIVE_QUIZZES_STATUS_FAILURE,
  ACTIVE_QUIZZES_STATUS_EMPTY,
} from "../Utils/Constants";

const initialState = {
  quizStatus: {},
  // loading: false,
  error: null,
};

const updateQuizStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_QUIZZES_STATUS_REQUEST:
      return {
        ...state,
        // loading: true,
        error: null,
      };
    case ACTIVE_QUIZZES_STATUS_SUCCESS:
      return {
        ...state,
        quizStatus: action.payload,
        // loading: false,
        error: null,
      };
    case ACTIVE_QUIZZES_STATUS_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.payload,
      };
    case ACTIVE_QUIZZES_STATUS_EMPTY:
      return {
        ...state,
        quizStatus: {},
        // loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default updateQuizStatusReducer;
