import {
  GET_ALL_USER_WITHDRAWALS_REQUEST,
  GET_ALL_USER_WITHDRAWALS_SUCCESS,
  GET_ALL_USER_WITHDRAWALS_FAILURE,
} from "../../Utils/Constants";

const initialState = {
  allWithdrawal: {},
  loading: false,
  error: null,
};

const GetAllWithdrawalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USER_WITHDRAWALS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ALL_USER_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        allWithdrawal: action.payload,
        loading: false,
        error: null,
      };

    case GET_ALL_USER_WITHDRAWALS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default GetAllWithdrawalReducer;
