import {
    ACTIVE_CATEGORY_FAILURE,
    ACTIVE_CATEGORY_REQUEST,
    ACTIVE_CATEGORY_SUCCESS,
    LOGOUT,
  } from "../Utils/Constants";
  
  // Action Creators
  export const ActiveCategoryRequest = (payload) => {
    return {
      type: ACTIVE_CATEGORY_REQUEST,
      payload: payload,
    };
  };
  
  export const ActiveCategorySuccess = (data) => {
    return {
      type: ACTIVE_CATEGORY_SUCCESS,
      payload: data,
    };
  };
  
  export const ActiveCategoryFailure = (error) => {
    return {
      type: ACTIVE_CATEGORY_FAILURE,
      payload: error,
    };
  };
  export const logoutAction = () => {
    return {
      type: LOGOUT,
    };
  };
  