import {
  DELETE_USER_UPI_BANK_ACCOUNT_REQUEST,
  DELETE_USER_UPI_BANK_ACCOUNT_SUCCESS,
  DELETE_USER_UPI_BANK_ACCOUNT_FAILURE,
} from "../../Utils/Constants";

export const deleteBankUpiAccountsRequest = (payload) => {
  return {
    type: DELETE_USER_UPI_BANK_ACCOUNT_REQUEST,
    payload: payload,
  };
};

export const deleteBankUpiAccountsSuccess = (response) => {
  return {
    type: DELETE_USER_UPI_BANK_ACCOUNT_SUCCESS,
    payload: response,
  };
};

export const deleteBankUpiAccountsFailure = (error) => {
  return {
    type: DELETE_USER_UPI_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
};
