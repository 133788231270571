import {
  VERSION_GET_REQUEST,
  VERSION_GET_SUCCESS,
  VERSION_GET_FAILURE,
} from "../Utils/Constants";

// Action Creators
export const versionGetRequest = (payload) => {
  return {
    type: VERSION_GET_REQUEST,
    payload: payload,
  };
};

export const versionGetSuccess = (data) => {
  return {
    type: VERSION_GET_SUCCESS,
    payload: data,
  };
};

export const versionGetFailure = (error) => {
  return {
    type: VERSION_GET_FAILURE,
    payload: error,
  };
};
