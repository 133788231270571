import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";
import {
  getQuizSpotPercentageFailure,
  getQuizSpotPercentageSuccess,
} from "../../Actions";

export function* getQuizSpotPercntageSaga(action) {
  const token = action.payload.token;
  const path = `admin/quiz/special/requiredSpotsPercent`;

  try {
    const response = yield debounce(GetAPI, token, path);
    yield put(getQuizSpotPercentageSuccess(response));
  } catch (error) {
    yield put(getQuizSpotPercentageFailure(error));
  }
}
