import {
  DELETE_PLAYER_FAILURE,
  DELETE_PLAYER_REQUEST,
  DELETE_PLAYER_SUCCESS,
} from "../../../Utils/Constants";

export const deletePlayerRequest = (payload) => {
  return {
    type: DELETE_PLAYER_REQUEST,
    payload: payload,
  };
};

export const deletePlayerSuccess = (response) => {
  return {
    type: DELETE_PLAYER_SUCCESS,
    payload: response,
  };
};

export const deletePlayerFailure = (error) => {
  return {
    type: DELETE_PLAYER_FAILURE,
    payload: error,
  };
};


