import {
  GET_ASSIGNEE_ALL_REQUEST,
  GET_ASSIGNEE_ALL_SUCCESS,
  GET_ASSIGNEE_ALL_FAILURE,
} from "../Utils/Constants";

// Action Creators

export const getAssigneeRequiest = (payload) => {
  return {
    type: GET_ASSIGNEE_ALL_REQUEST,
    payload: payload,
  };
};

export const getAssigneeSuccess = (data) => {
  return {
    type: GET_ASSIGNEE_ALL_SUCCESS,
    payload: data,
  };
};

export const getAssigneeFailure = (error) => {
  return {
    type: GET_ASSIGNEE_ALL_FAILURE,
    payload: error,
  };
};
