import { put, call } from "redux-saga/effects";
import { GetAPISearch } from "../components/Config/Apiinterface";
import { SearchCategoriesFailure, SearchCategoriesSuccess } from "../Actions";
import debounce from "../Utils/useDebounce";

// Worker saga for handling SearchCategories request
function* SearchCategoriesSagas(action) {
  const token = action.payload.token;
  const data = action.payload.data;
  let path;
  if (!isNaN(data)) {
    path = `admin/category?categoryId=${data}`;
  } else {
    path = `admin/category?categoryName=${data}`;
  }
  try {
    const SearchCategories = yield debounce(GetAPISearch, path, token);
    yield put(SearchCategoriesSuccess(SearchCategories));
  } catch (error) {
    yield put(SearchCategoriesFailure(error));
  }
}

export default SearchCategoriesSagas;
