import { useState } from "react";
import bank from "../../../../assets/svg/icons8-bank-building-96 3.svg";
import Upi from "../../../../assets/svg/upi-money-transfer 1.svg";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { DeleteModal } from "../../../../components/modal/DeleteModal";
import { deleteBankUpiAccountsRequest } from "../../../../Actions";
import cashQuiz from "../../../../assets/images/cashQuiz.svg";
import freeQuiz from "../../../../assets/images/freeQuiz.svg";
import { useNavigate } from "react-router";
import { TbServerOff } from "react-icons/tb";

const AllWidthrawal = ({
  tableHeaders,
  dataArray,
  flage,
  setActiveTab,
  activeTab,
  handleTabClick,
  token,
  handleUserUpiAccount,
  handleUserBankAccount,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deletBankAccount, setDeleteBankAccount] = useState({});
  const [openBankAccountDeleteModal, setBankAccountDeleteModal] =
    useState(false);

  const [deletUpiAccount, setDeleteUpiAccount] = useState({});
  const [openUpiAccountDeleteModal, setUpiAccountDeleteModal] = useState(false);

  const withdrawalTable = ["transaction id", "date & time", "amount", "status"];
  const bankAccountTable = [
    "id",
    "Account Name",
    "Account Number",
    "IFSC code",
    "Bank Name",
    "Action",
  ];
  const UpiIdTable = ["id", "UPI ID", "Action"];
  const userAllPlayedQuizHeaders = [
    "Id",
    "StartTime  Date",
    "Category",
    "Spots",
    "Winning Ammount",
    "Fees",
    "Status",
    "Details",
  ];

  const userTransectionHeader = [
    "transaction id",
    "type",
    "date & time",
    "amount",
    "status",
  ];

  const handleBankAccountDelete = (data) => {
    const body = {
      data: {
        fundAccountId: data,
        accountType: "BANK",
      },
      token: token,
    };
    dispatch(deleteBankUpiAccountsRequest(body));
    handleUserBankAccount();
  };

  const handleUpiAccountDelete = (data) => {
    const body = {
      data: {
        fundAccountId: data,
        accountType: "UPI",
      },
      token: token,
    };
    dispatch(deleteBankUpiAccountsRequest(body));
    handleUserUpiAccount();
  };

  const formatDate = (dateTimeString) => {
    const formattedDateTimeString = dateTimeString.replace(
      /(\d{2})-(\d{2})-(\d{4})/,
      "$2/$1/$3"
    );

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const formattedDate = new Date(formattedDateTimeString).toLocaleString(
      "en-US",
      options
    );
    return formattedDate;
  };

  return (
    <>
      {flage === "Bank&Upi" && (
        <>
          <div>
            <div className="flex">
              <div className="border border-gray-400 p-2 rounded-lg w-[400px]">
                <button
                  className={`w-50 p-2.5 rounded-lg ${
                    activeTab === 1
                      ? "text-white bg-[#182241]"
                      : "text-[#182241]"
                  }`}
                  onClick={() => handleTabClick(1)}
                >
                  <div className="flex text-center gap-2">
                    <div>
                      <img src={bank} width={25} height={25} />
                    </div>
                    <div>Bank Account</div>
                  </div>
                </button>
                <button
                  className={`w-50 p-2.5 rounded-lg ${
                    activeTab === 2
                      ? "text-white bg-[#182241]"
                      : "text-[#182241]"
                  }`}
                  onClick={() => handleTabClick(2)}
                >
                  <div className="flex gap-2">
                    <div>
                      <img src={Upi} width={25} height={25} />
                    </div>
                    <div>Upi Account</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {dataArray?.length ? (
        <>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4 ">
            <table className="w-full text-sm text-left text-gray-700 ">
              {flage === "allWithdrawal" && (
                <>
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      {withdrawalTable?.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-center"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {dataArray?.map((item, index) => (
                        <tr className="bg-white border-b" key={index}>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.referenceId}</div>
                            </div>
                          </td>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.createDate}</div>
                            </div>
                          </td>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.amount}</div>
                            </div>
                          </td>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.payoutStatus}</div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </>
              )}

              {activeTab === 1 && flage === "Bank&Upi" && (
                <>
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      {bankAccountTable?.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-center"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {dataArray?.map((item, index) => (
                        <tr className="bg-white border-b " key={index}>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.id}</div>
                            </div>
                          </td>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.accountHolderName}</div>
                            </div>
                          </td>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.accountNumber}</div>
                            </div>
                          </td>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.ifsc}</div>
                            </div>
                          </td>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.bankName}</div>
                            </div>
                          </td>
                          <div>
                            <td className="text-center flex justify-center">
                              <div
                                onClick={() => {
                                  setDeleteBankAccount({
                                    name: `Bank with id ${item.id}`,
                                    id: item.fundAccountId,
                                  });
                                  setBankAccountDeleteModal(true);
                                }}
                                className="hover:bg-gray-100 hover:cursor-pointer p-2 rounded-full"
                              >
                                <MdDelete className="text-2xl" />
                              </div>
                            </td>
                          </div>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </>
              )}
              {activeTab === 2 && flage === "Bank&Upi" && (
                <>
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      {UpiIdTable?.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-center"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {dataArray?.map((item, index) => (
                        <tr className="bg-white border-b " key={index}>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.id}</div>
                            </div>
                          </td>
                          <td className="px-6 py-2 text-center">
                            <div>
                              <div>{item.upiId}</div>
                            </div>
                          </td>
                          <div>
                            <td className="text-center flex justify-center">
                              <div
                                onClick={() => {
                                  setDeleteUpiAccount({
                                    name: `Upi with id ${item.id}`,
                                    id: item.fundAccountId,
                                  });
                                  setUpiAccountDeleteModal(true);
                                }}
                                className="hover:bg-gray-100 hover:cursor-pointer p-2 rounded-full"
                              >
                                <MdDelete className="text-2xl" />
                              </div>
                            </td>
                          </div>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </>
              )}

              {flage === "userAllPlayedQuiz" && (
                <>
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      {userAllPlayedQuizHeaders?.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-center"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {dataArray?.map((newItem, index) => (
                      <tr className="bg-white border-b " key={index}>
                        <td className="px-6 py-2 text-center">
                          <div className="flex gap-2">
                            <div className="flex justify-center w-8">
                              <img
                                className="w-8"
                                src={newItem.entryFees ? cashQuiz : freeQuiz}
                                alt=""
                              />
                            </div>
                            <div>{newItem.id}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2  text-center">
                          <div>
                            <div className="w-[9rem]">
                              {formatDate(newItem.startTime)}
                            </div>
                          </div>
                        </td>

                        <td className="px-6 py-2 text-center">
                          <div>
                            <div>{newItem?.categoryName}</div>
                            <div>{newItem?.topicName}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div>
                            <div>
                              {newItem.filledSpots}/{newItem.totalSpots}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div>
                            <div>{newItem.totalWinnings}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          {newItem.entryFees}
                        </td>
                        <td className="px-6 py-2 text-center">
                          {newItem.quizStatus}
                        </td>
                        <td className="px-6 py-2 text-center">
                          <button
                            onClick={() => {
                              const flage = "userPlayedQuiz";
                              const item = { ...newItem, flage };
                              navigate("/viewQuiz", { state: item });
                            }}
                            type="button"
                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-blue-500 rounded-lg hover:bg-blue-100 focus:ring-4 focus:outline-none focus:ring-green-100 "
                          >
                            <span className="hidden md:inline-block">
                              View Details{" "}
                            </span>
                            <span className="inline-block md:hidden">
                              Details{" "}
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}

              {flage === "viewAllTransactions" && (
                <>
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      {userTransectionHeader?.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-center"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {dataArray?.map((item, index) => (
                      <tr className="bg-white border-b " key={index}>
                        <td className="px-6 py-2 text-center">
                          <div>
                            <div>{item?.transactionId}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div>
                            <div>{item?.type}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div>
                            <div>{item?.createDate}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div className="flex justify-center">
                            {item?.amount}
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div className="flex justify-center">
                            {item?.status}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </table>
          </div>
          {openBankAccountDeleteModal && (
            <DeleteModal
              data={deletBankAccount}
              handleDelete={handleBankAccountDelete}
              setOpenDeleteModal={setBankAccountDeleteModal}
            />
          )}

          {openUpiAccountDeleteModal && (
            <DeleteModal
              data={deletUpiAccount}
              handleDelete={handleUpiAccountDelete}
              setOpenDeleteModal={setUpiAccountDeleteModal}
            />
          )}
        </>
      ) : (
        <>
          <div className="text-center flex flex-col justify-center items-center pt-10">
            <TbServerOff className="text-[2rem] font-bold  m-2" />
            Data Not Found
          </div>
        </>
      )}
    </>
  );
};

export default AllWidthrawal;
