import { call, put } from "redux-saga/effects";
import { postFormData } from "../../components/Config/Apiinterface";
import { toast } from "react-toastify";
import { addBalanceFailure, addBalanceSuccess } from "../../Actions";

export function* addBalanceSaga(action) {
  const path = "admin/user/addBalance";
  const token = action.payload.token;
  const body = action.payload.data;
  try {
    const result = yield call(postFormData, path, body, token);
    yield put(addBalanceSuccess(result));
    toast.success(result.message);
  } catch (err) {
    yield put(addBalanceFailure(err));
    toast.error(err);
  }
}
