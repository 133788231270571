import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { GetAPI } from "../../components/Config/Apiinterface";
import { getNotificationFailure, getNotificationSuccess } from "../../Actions";
import debounce from "../../Utils/useDebounce";

export function* notificationSaga(action) {
  const token = action.payload.token;
  const pageNo = action.payload.pageNo;
  const sortBy = action.payload.sortValue;
  const searchBy = "role";

  function buildURL(pageNo, pageSize, searchBy, sortBy) {
    let url;

    if (sortBy) {
      url = `admin/notification/all?pageNo=${pageNo}&pageSize=${pageSize}`;
    } else {
      url = `admin/notification/all?pageNo=${pageNo}&pageSize=${pageSize}`;
    }

    let hasQueryParams = false;

    if (sortBy) {
      url += hasQueryParams ? "?" : "&";
      url += "searchBy=" + encodeURIComponent(searchBy);
      hasQueryParams = true;
    }

    if (sortBy) {
      url += hasQueryParams ? "&" : "?";
      url += "searchValue=" + encodeURIComponent(sortBy);
      hasQueryParams = true;
    }

    return url;
  }

  const newurl = buildURL(pageNo, 20, searchBy, sortBy);

  const path = newurl;

  try {
    const response = yield debounce(GetAPI, token, path);
    yield put(getNotificationSuccess(response));
    if (response.statusCode === 200) {
      // toast.success(response.message);
    } else {
      toast.warning(response.message);
    }
  } catch (error) {
    yield put(getNotificationFailure(error));
  }
}
