import {
  GET_SERVER_REQUEST,
  GET_SERVER_SUCCESS,
  GET_SERVER_FAILURE,
} from "../../Utils/Constants";

const initialState = {
  reviewTime: {},
  loading: false,
  error: null,
};

const serverInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_SERVER_SUCCESS:
      return {
        ...state,
        reviewTime: action.payload,
        loading: false,
        error: null,
      };

    case GET_SERVER_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default serverInfoReducer;
