import { ModalOptionsSelector } from "../../components/select/ModalOptionsSelector";
import { quiz_Mode } from "../../components/constant";

export function SelectQuizMode(props) {
  const { topics, categories, handleSortValues, item, disabled } = props;
  return (
    <>
      <ModalOptionsSelector
        id="categoryOptions"
        header="Category"
        initialValue={
          item?.category?.name ? item?.category?.name : "Select Category"
        }
        options={categories}
        callbackFn={handleSortValues}
      />
      <ModalOptionsSelector
        id="topicOptions"
        header="Topic"
        initialValue={item?.topic?.name ? item?.topic?.name : "Select Topic"}
        options={topics}
        callbackFn={handleSortValues}
      />

      {!disabled && (
        <ModalOptionsSelector
          id="quizModeOptions"
          header="Quiz-mode"
          options={quiz_Mode}
          callbackFn={handleSortValues}
        />
      )}
    </>
  );
}
