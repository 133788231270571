/**
 * @param TWA = Total Winning Amount
 * @param A = Membar of group A
 * @param R4_TO_A = Membar of group B
 * @param A_TO_B = Membar of group C
 * @function  Math.floor() or @function Math.ceil() = use for round down or round up, respectively, instead of rounding to the nearest integer.
 * @function toFixed(2) use for show only two value ofter (.) point
 */

// const commission = 20;

export function formatDateAndTime(date) {
  const year = date?.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

export const totalWinning = (totalSpot, entreeFees, props, specialChecked) => {
  let commission;
  if (specialChecked) {
    commission = props.quizSpotPercentageRate?.data?.requiredSpotPercent;
  } else {
    commission = props?.commissionRate?.data?.rateInPercent;
  }
  if (totalSpot && entreeFees) {
    const totalWinningAmount =
      totalSpot * entreeFees - (totalSpot * entreeFees * commission) / 100;
    return Math.floor(totalWinningAmount);
  }
};

function findPercentage(TWA, totalWinningPercentage) {
  if (!TWA) {
    return 0;
  }

  const percentageOfTotalWinningAmount = (totalWinningPercentage / 100) * TWA;
  return percentageOfTotalWinningAmount;
}

export const winnerBreakup = (data) => {
  var { quizMode, totalWinningAmount, totalSpots } = data;
  if (quizMode === "Winner takes all") {
    const classicBreackup = [{ amount: totalWinningAmount, rank: "1" }];

    const totalBreakup = {
      totalWinner: 1,
      numberOfWinner: classicBreackup,
    };
    return totalBreakup;
  } else if (quizMode === "Classic") {
    const totalWinner = totalSpots / 2;
    let TWA = totalWinningAmount;
    let classicBreackup = classic(totalWinner, TWA);

    const totalBreakup = {
      totalWinner: totalWinner,
      numberOfWinner: classicBreackup,
    };

    return totalBreakup;
  } else if (quizMode === "Mega contest") {
    const totalWinner = totalSpots / 2;
    let TWA = totalWinningAmount;
    let classicBreackup = classic(totalWinner, TWA);

    const totalBreakup = {
      totalWinner: totalWinner,
      numberOfWinner: classicBreackup,
    };

    return totalBreakup;
  } else if (quizMode === "Equal distribution") {
    const totalWinner = totalSpots;
    let TWA = totalWinningAmount;
    let perUserWinningAmount = (TWA / totalWinner).toFixed(2);

    if (totalWinner > 1000) {
      let classicBreackup = classic(totalWinner, TWA);

      const totalBreakup = {
        totalWinner: totalWinner,
        numberOfWinner: classicBreackup,
      };
      return totalBreakup;
    } else {
      return {
        numberOfWinner: [
          {
            amount: parseFloat(perUserWinningAmount),
            rank: `1 - ${totalWinner}`,
          },
        ],
        totalWinner: totalWinner,
      };
    }
  } else {
    return 0;
  }
};

const classic = (totalWinner, TWA) => {
  let remainingWinner = Math.floor(totalWinner);
  if (remainingWinner == 1) {
    return [{ amount: findPercentage(TWA, 100).toFixed(2), rank: "1" }];
  } else if (remainingWinner == 2) {
    return [
      { amount: findPercentage(TWA, 70).toFixed(2), rank: "1" },
      { amount: findPercentage(TWA, 30).toFixed(2), rank: "2" },
    ];
  } else if (remainingWinner == 3) {
    return [
      { amount: findPercentage(TWA, 70).toFixed(2), rank: "1" },
      { amount: findPercentage(TWA, 20).toFixed(2), rank: "2" },
      { amount: findPercentage(TWA, 10).toFixed(2), rank: "3" },
    ];
  } else if (totalWinner > 3 && totalWinner <= 10) {
    let remainingWinner = totalWinner - 2;
    if (remainingWinner % 2 == 0) {
      let A = Math.floor(remainingWinner);
      let R3_TO_R10 = findPercentage(TWA, 20) / A;
      return [
        { amount: findPercentage(TWA, 60).toFixed(2), rank: "1" },
        { amount: findPercentage(TWA, 20).toFixed(2), rank: "2" },
        {
          amount: parseFloat(R3_TO_R10.toFixed(2)),
          rank: `3 - ${totalWinner}`,
        },
      ];
    } else {
      let remainingWinner = totalWinner - 2;
      let A = Math.floor(remainingWinner);
      let R3_TO_R10 = findPercentage(TWA, 20) / A;
      return [
        { amount: findPercentage(TWA, 60).toFixed(2), rank: "1" },
        { amount: findPercentage(TWA, 20).toFixed(2), rank: "2" },
        {
          amount: parseFloat(R3_TO_R10.toFixed(2)),
          rank: `3 - ${Math.floor(totalWinner)}`,
        },
      ];
    }
  } else if (totalWinner > 10 && totalWinner <= 100) {
    let remainingWinner = totalWinner - 3;
    if (remainingWinner % 2 == 0) {
      let A = Math.floor(remainingWinner / 2);
      let R4_TO_A = findPercentage(TWA, 15) / A;
      let A_TO_B = findPercentage(TWA, 5) / A;
      return [
        { amount: findPercentage(TWA, 50).toFixed(2), rank: "1" },
        { amount: findPercentage(TWA, 20).toFixed(2), rank: "2" },
        { amount: findPercentage(TWA, 10).toFixed(2), rank: "3" },
        {
          amount: parseFloat(R4_TO_A.toFixed(2)),

          rank: `4 - ${Math.ceil(A + 3)}`,
        },
        {
          amount: parseFloat(A_TO_B.toFixed(2)),

          rank: `${Math.ceil(A) + 4} - ${totalWinner}`,
        },
      ];
    } else {
      let A1 = Math.floor(remainingWinner / 2);
      let B1 = Math.round(remainingWinner - A1);
      let R4_TO_A = findPercentage(TWA, 15) / A1;
      let A_TO_B = findPercentage(TWA, 5) / B1;
      return [
        { amount: findPercentage(TWA, 50).toFixed(2), rank: "1" },
        { amount: findPercentage(TWA, 20).toFixed(2), rank: "2" },
        { amount: findPercentage(TWA, 10).toFixed(2), rank: "3" },
        {
          amount: parseFloat(R4_TO_A.toFixed(2)),

          rank: `4 - ${Math.ceil(A1 + 3)}`,
        },
        {
          amount: parseFloat(A_TO_B.toFixed(2)),

          rank: `${Math.ceil(A1) + 4} - ${Math.round(totalWinner)}`,
        },
      ];
    }
  } else if (totalWinner > 100 && totalWinner <= 1000) {
    let remainingWinner = totalWinner - 3;
    if (remainingWinner % 3 == 0) {
      let A = Math.floor(remainingWinner / 3);
      let B = A;
      let C = B;
      let toA = A + 3;
      let toB = A + toA;
      let toC = toB;
      let R4_TO_A = findPercentage(TWA, 9) / A;
      let A_TO_B = findPercentage(TWA, 7) / B;
      let B_TO_C = findPercentage(TWA, 4) / C;
      return [
        { amount: findPercentage(TWA, 50).toFixed(2), rank: "1" },
        { amount: findPercentage(TWA, 20).toFixed(2), rank: "2" },
        { amount: findPercentage(TWA, 10).toFixed(2), rank: "3" },
        {
          amount: parseFloat(R4_TO_A.toFixed(2)),
          rank: `4 - ${Math.round(toA)}`,
        },
        {
          amount: parseFloat(A_TO_B.toFixed(2)),
          rank: `${Math.floor(toA + 1)} - ${Math.floor(A + toA)}`,
        },
        {
          amount: parseFloat(B_TO_C.toFixed(2)),
          rank: `${Math.floor(toC + 1)} - ${Math.round(totalWinner)}`,
        },
      ];
    } else {
      let A1 = Math.floor(remainingWinner / 3);
      let B1 = A1;
      let C1 = Math.round(remainingWinner - (A1 + B1));
      let toA1 = A1 + 3;
      let toB1 = A1 + toA1;
      let toC1 = toB1;
      let R4_TO_A = findPercentage(TWA, 9) / A1;
      let A1_TO_B1 = findPercentage(TWA, 7) / B1;
      let B1_TO_C1 = findPercentage(TWA, 4) / C1;
      return [
        { amount: findPercentage(TWA, 50).toFixed(2), rank: "1" },
        { amount: findPercentage(TWA, 20).toFixed(2), rank: "2" },
        { amount: findPercentage(TWA, 10).toFixed(2), rank: "3" },
        {
          amount: parseFloat(R4_TO_A.toFixed(2)),
          rank: `4 - ${Math.floor(toA1)}`,
        },
        {
          amount: parseFloat(A1_TO_B1.toFixed(2)),
          rank: `${Math.floor(toA1 + 1)} - ${Math.floor(A1 + toA1)}`,
        },
        {
          amount: parseFloat(B1_TO_C1.toFixed(2)),
          rank: `${Math.floor(toC1 + 1)} - ${Math.round(totalWinner)}`,
        },
      ];
    }
  } else if (totalWinner > 1000) {
    let remainingWinner = totalWinner - 100;
    if (remainingWinner % 5 == 0) {
      let A = Math.floor(remainingWinner / 5);
      let toA = A + 100;
      let toB = A + toA;
      let R4_TO_R10 = findPercentage(TWA, 10) / 7;
      let R11_TO_R100 = findPercentage(TWA, 25) / 90;
      let R101_TO_A = findPercentage(TWA, 17.5) / A;
      let A_TO_B = findPercentage(TWA, 10) / A;
      let B_TO_C = findPercentage(TWA, 5) / A;
      let C_TO_D = findPercentage(TWA, 2.5) / A;
      let D_TO_E = findPercentage(TWA, 2.5) / A;

      return [
        { amount: findPercentage(TWA, 20).toFixed(2), rank: "1" },
        { amount: findPercentage(TWA, 5).toFixed(2), rank: "2" },
        { amount: findPercentage(TWA, 2.5).toFixed(2), rank: "3" },
        { amount: parseFloat(R4_TO_R10.toFixed(2)), rank: "4 - 10" },
        { amount: parseFloat(R11_TO_R100.toFixed(2)), rank: "11 - 100" },
        {
          amount: parseFloat(R101_TO_A.toFixed(2)),
          rank: `101 - ${Math.round(toA)}`,
        },
        {
          amount: parseFloat(A_TO_B.toFixed(2)),
          rank: `${Math.round(toA + 1)} - ${Math.round(toB)}`,
        },
        {
          amount: parseFloat(B_TO_C.toFixed(2)),
          rank: `${Math.round(toB + 1)} - ${Math.round(toB + A)}`,
        },
        {
          amount: parseFloat(C_TO_D.toFixed(2)),
          rank: `${Math.round(toB + A + 1)} - ${Math.round(toB + A + A)}`,
        },
        {
          amount: parseFloat(D_TO_E.toFixed(2)),
          rank: `${Math.round(toB + A + A + 1)} - ${totalWinner}`,
        },
      ];
    } else {
      let A1 = Math.floor(remainingWinner / 5);
      let B = A1;
      let C = B;
      let D = C;
      let E = Math.round(remainingWinner - (A1 + B + C + D));
      let toA1 = A1 + 100;
      let toB1 = A1 + toA1;
      let R4_TO_R10 = findPercentage(TWA, 10) / 7;
      let R11_TO_R100 = findPercentage(TWA, 25) / 90;
      let R101_TO_A = findPercentage(TWA, 17.5) / A1;
      let A_TO_B = findPercentage(TWA, 10) / B;
      let B_TO_C = findPercentage(TWA, 5) / C;
      let C_TO_D = findPercentage(TWA, 2.5) / D;
      let D_TO_E = findPercentage(TWA, 2.5) / E;
      return [
        { amount: findPercentage(TWA, 20).toFixed(2), rank: "1" },
        { amount: findPercentage(TWA, 5).toFixed(2), rank: "2" },
        { amount: findPercentage(TWA, 2.5).toFixed(2), rank: "3" },
        { amount: parseFloat(R4_TO_R10.toFixed(2)), rank: "4 - 10" },
        { amount: parseFloat(R11_TO_R100.toFixed(2)), rank: "11 - 100" },
        {
          amount: parseFloat(R101_TO_A.toFixed(2)),
          rank: `101 - ${Math.round(toA1)}`,
        },
        {
          amount: parseFloat(A_TO_B.toFixed(2)),
          rank: `${Math.round(toA1 + 1)} - ${Math.round(toB1)}`,
        },
        {
          amount: parseFloat(B_TO_C.toFixed(2)),
          rank: `${Math.round(toB1 + 1)} - ${Math.round(toB1 + A1)}`,
        },
        {
          amount: parseFloat(C_TO_D.toFixed(2)),
          rank: `${Math.round(toB1 + A1 + 1)} - ${Math.round(toB1 + A1 + A1)}`,
        },
        {
          amount: parseFloat(D_TO_E.toFixed(2)),
          rank: `${Math.round(toB1 + A1 + A1 + 1)} - ${Math.round(
            totalWinner
          )}`,
        },
      ];
    }
  }
};
