import {
  UPDATE_QUIZ_REQUEST,
  UPDATE_QUIZ_SUCCESS,
  UPDATE_QUIZ_FAILURE,
  UPDATE_QUIZ_RESET,
} from "../Utils/Constants";

export const updateQuizRequest = (payload) => {
    return {
        type: UPDATE_QUIZ_REQUEST,
        payload: payload
    }
}

export const updateQuizSuccess = (data) => {
    return {
        type: UPDATE_QUIZ_SUCCESS,
        payload: data
    }
}

export const updateQuizFailure = (error) => {
    return {
        type: UPDATE_QUIZ_FAILURE,
        payload: error
    }
}

export const updateQuizReset = () => {
    return {
        type: UPDATE_QUIZ_RESET
    }
}