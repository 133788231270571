import {
  DELETE_MULTIPUL_QUESTIONS_SUCCESS,
  DELETE_MULTIPUL_QUESTIONS_REQUEST,
  DELETE_MULTIPUL_QUESTIONS_FAILURE,
  DELETE_MULTIPUL_QUESTIONS_RESET,
} from "../Utils/Constants";

const initialState = {
  deleteMultipulQuestions: {},
  loading: false,
  error: null,
};

const deleteMultipulQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_MULTIPUL_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_MULTIPUL_QUESTIONS_SUCCESS:
      return {
        ...state,
        deleteMultipulQuestions: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_MULTIPUL_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_MULTIPUL_QUESTIONS_RESET:
      return {
        ...state,
        deleteMultipulQuestions: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default deleteMultipulQuestionsReducer;
