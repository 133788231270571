import { call, put } from "redux-saga/effects";
import { postFormData } from "../../components/Config/Apiinterface";
import { updateMenuFailure, updateMenuSuccess } from "../../Actions";
import { toast } from "react-toastify";
import debounce from "../../Utils/useDebounce";

export function* updateMenuSaga(action) {
  const path = "admin/getMenu";
  const token = action.payload.token;
  const body = action.payload.data;
  try {
    const responce = yield debounce(postFormData, path, body, token);
    yield put(updateMenuSuccess(responce));
    if (responce.statusCode === 200) {
      toast.success(responce.message);
    } else {
      toast.warning(responce.message);
    }
  } catch (err) {
    yield put(updateMenuFailure(err));
  }
}
