import { put, call } from "redux-saga/effects";
import { activeQuizStatusFailure, activeQuizStatusSuccess } from "../Actions";
import { putRequestAPI } from "../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

// Worker saga for handling DeleteCtegory request
function* updateQuizeStatusSagas(action) {
  const token = action.payload.token;
  const body = {
    id: action.payload.id,
    isEnabled: action.payload.isEnabled,
    topicId: action.payload.topicId,
  };
  const path = "quiz";
  try {
    const response = yield debounce(putRequestAPI, token, body, path);
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
    yield put(activeQuizStatusSuccess(response));
  } catch (error) {
    yield put(activeQuizStatusFailure(error));
  }
}

export default updateQuizeStatusSagas;
