// src/redux/sagas.js

import { put, call } from "redux-saga/effects";
import { UpdateAPI } from "../components/Config/Apiinterface";
import { UpdateCategoryFailure, UpdateCategorySuccess } from "../Actions";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Update Category request
function* UpdateCategorySaga(action) {
  const formdata = new FormData();
  formdata.append("categoryImage", action.payload.image);

  const categoryId = action.payload.categoryId;
  const name = action.payload.name;
  const token = action.payload.token;
  let path;

  if (name.length) {
    path = `category/updateCategory?categoryId=${categoryId}&categoryName=${name}`;
  } else {
    path = `category/updateCategory?categoryId=${categoryId}`;
  }

  try {
    const UpdateCategory = yield debounce(UpdateAPI, token, path, formdata);
    yield put(UpdateCategorySuccess(UpdateCategory));
  } catch (error) {
    alert("Something went wrong");
    yield put(UpdateCategoryFailure(error));
  }
}

export default UpdateCategorySaga;
