import {
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
} from "../../Utils/Constants";

export const deleteUserRequest = (payload) => {
  return {
    type: DELETE_USER_REQUEST,
    payload: payload,
  };
};

export const deleteUserSuccess = (response) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: response,
  };
};

export const deleteUserFailure = (error) => {
  return {
    type: DELETE_USER_FAILURE,
    payload: error,
  };
};
