import { put, call } from "redux-saga/effects";
import { postFormData, UpdateAPI } from "../components/Config/Apiinterface";
import { versionPostFailure, versionPostSuccess } from "../Actions";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Update Category request
function* versionPostSaga(action) {
  const token = action.payload.token;
  let path = "app/addVersion";
  const data = action.payload;
  try {
    const versionPost = yield debounce(postFormData, path, data, token);
    if (versionPost.statusCode === 200) {
      toast.success(versionPost?.message);
    } else {
      toast.error(versionPost?.message);
    }
    yield put(versionPostSuccess(versionPost));
  } catch (error) {
    yield put(versionPostFailure(error));
  }
}

export default versionPostSaga;
