import { useEffect, useRef, useState } from "react";
import { Search } from "../../components/Search";
import { useNavigate } from "react-router";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdModeEditOutline, MdRefresh } from "react-icons/md";
import { DeleteModal } from "../../components/modal/DeleteModal";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import { connect, useDispatch } from "react-redux";
import {
  deleteUserRequest,
  getUserAllRequest,
  resetPasswordRequest,
} from "../../Actions";
import { Pagination } from "../../components/Pagination";
import password from "../../assets/images/password.png";
import { PasswordModal } from "../../components/modal/passwordModal";
import activity from "../../assets/images/arrows.png";
import {
  notificationLocalReset,
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  transactionResetLocalData,
} from "../../components/constant";
import { getUserByIdEmpty } from "../../Actions/User/getUserByIdAction";
import { TbServerOff } from "react-icons/tb";
import Loading from "../../components/loading";
import { checkAccess } from "../../Utils/RoleBasedRoute";

const Setting = ({ allUser, deleteUser, flage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [sortValue, setSortValue] = useState("");
  const [sortValue, setSortValue] = useState(
    localStorage.getItem("sortValue") || ""
  );
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSortOrder, setIsSortOrder] = useState(
    localStorage.getItem("isSortOrder") || true
  );
  const [showInitial, setShowInitial] = useState(false);
  const [showFilterArea, setShowFilterArea] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  // const [searchValue, setSearchValue] = useState(
  //   localStorage.getItem("searchValue") || ""
  // );
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(
  //   parseInt(localStorage.getItem("currentPage")) || 1
  // );

  const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openDeleteCUserModal, setOpenDeleteCUserModal] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [userArray, setUserArray] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [fullPic, setFullPic] = useState("");
  const [userId, setUserId] = useState("");
  const timerRef = useRef(null);

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const handleClose = () => {
    setFullscreen(false);
    setFullPic(null);
  };
  const settingTabheader = [
    "id",
    "Full Name",
    "Email",
    "last login",
    "user type",
    "Access",
    "Action",
  ];

  const getUser = (obj) => {
    const body = {
      pageNo: currentPage,
      token: token,
      sortValue: sortValue,
      isSortOrder: isSortOrder,
      searchBy: searchValue,
      filterBy: obj?.filterBy,
    };
    dispatch(getUserAllRequest(body));
    setShowInitial(true);
  };

  useEffect(() => {
    getUser({ filterBy: "EXCEPT_PLAYER" });
    dispatch(getUserByIdEmpty());
    setSearchValue(localStorage.getItem("searchValue"));
  }, []);

  useEffect(() => {
    resetQuestionLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
    notificationLocalReset();
  }, []);

  const resetFilterInLocal = () => {
    localStorage.removeItem("sortValue");
    localStorage.removeItem("isSortOrder");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("userDrop");
    setCurrentPage(1);
  };

  const refreshUserData = (obj) => {
    resetFilterInLocal();
    const body = {
      pageNo: currentPage,
      token: token,
      ...obj,
    };
    dispatch(getUserAllRequest(body));
    setShowInitial(true);
  };

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    const storedSortValue = localStorage.getItem("sortValue");
    const storedIsSortOrder = localStorage.getItem("isSortOrder");
    if (storedSortValue) {
      setSortValue(storedSortValue);
    }
    if (storedIsSortOrder) {
      setIsSortOrder(storedIsSortOrder === "true");
    }
  }, []);

  const searchByUser = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event?.target.value);
      if (event?.target.value !== null) {
        localStorage.setItem("searchValue", event?.target.value);
      } else {
        localStorage.setItem("searchValue", "");
      }
    }
    let body = {};

    body = {
      token,
      pageNo: currentPage,
      searchBy: searchParam || event?.target.value,
      filterBy: "EXCEPT_PLAYER",
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(getUserAllRequest(body));
    }, 10);
  };

  useEffect(() => {
    if (allUser?.loading) {
      setIsLoading(true);
    }
    if (allUser?.error) {
      setIsLoading(false);
    }

    if (allUser?.allUser?.data?.users) {
      setUserArray(allUser?.allUser?.data?.users);
      setIsLoading(false);
    }

    if (searchValue && allUser?.data) {
      setUserArray([allUser?.data]);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (allUser?.allUser?.data?.statusCode === 404 ||
        allUser?.allUser?.data?.statusCode === 400)
    ) {
      setUserArray([]);
      setIsLoading(false);
    }

    if (allUser?.allUser?.data?.totalPages) {
      setTotalPages(allUser?.allUser?.data?.totalPages);
    }
    if (allUser?.allUser?.statusCode === 403) {
      window.location.href = "/";
    }
    setIsSubmittingFilter(false);
    // eslint-disable-next-line
  }, [allUser]);

  useEffect(() => {
    getUser({ filterBy: "EXCEPT_PLAYER" });
  }, [currentPage, deleteUser]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchValue(localStorage.getItem("searchValue") || "");
  };

  const handleFilterAreaView = () => {
    if (screenWidth < 768) {
      setShowFilterArea((prev) => !prev);
    }
  };

  const dropDown = localStorage.getItem("userDrop");

  useEffect(() => {
    if (searchValue !== null) {
      localStorage.setItem("searchValue", searchValue);
    } else {
      localStorage.setItem("searchValue", "");
    }
  }, [searchValue]);

  const handleSortValues = (id, value) => {
    setError1(false);
    if (id === "dropdownOptions") {
      setSortValue(value);
      localStorage.setItem("sortValue", value);
      localStorage.setItem("userDrop", true);
    }
    if (id === "sortOrder") {
      setIsSortOrder(value);
      localStorage.setItem("isSortOrder", value.toString());
    }
  };

  const handleSubmitFilter = () => {
    if (!sortValue || !isSortOrder || isSortOrder === "") {
      setError1(true);
      return;
    }
    setIsSubmittingFilter(true);
    let params = {
      token,
      pageNo: currentPage,
      sortValue,
      isSortOrder,
      filterBy: "PLAYER",
    };
    if (flage) {
      params = {
        token,
        pageNo: currentPage,
        sortValue,
        isSortOrder,
        filterBy: "EXCEPT_PLAYER",
      };
    }

    const body = {
      ...params,
      ...(sortValue && { sortValue }),
      ...(isSortOrder && { isSortOrder }),
    };
    dispatch(getUserAllRequest(body));
    setShowInitial(false);
  };

  const handleClearFilter = () => {
    setError1(false);
    resetFilterInLocal();
    const body = {
      token,
      pageNo: currentPage,
    };
    dispatch(getUserAllRequest(body));
    setSortValue("");
    setIsSortOrder("");
    setShowInitial(true);
  };

  const handleDeleteUser = (data) => {
    const body = {
      data: {
        id: data,
      },
      token: token,
    };
    dispatch(deleteUserRequest(body));
  };

  const handleChangePassword = (newPassword) => {
    const body = {
      data: {
        userId: userId,
        newPassword: newPassword,
      },
      token: token,
    };
    dispatch(resetPasswordRequest(body));
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh]  px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh] ">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`${
          flage && "bg-[#e8f5e9]"
        } bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3`}
      >
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[85vh] gap-2">
          <div className="flex justify-between items-center">
            <div className="font-bold uppercase text-lg text-center">
              Manage Access
            </div>
          </div>
          <div className="flex justify-between flex-wrap">
            <button
              onClick={() => {
                setSearchValue("");
                refreshUserData({ filterBy: "EXCEPT_PLAYER" });
              }}
            >
              <MdRefresh className="w-6 h-6 mt-2" />
            </button>

            <div className="flex justify-end gap-2">
              <Search
                label={`Category`}
                placeholder={
                  flage ? "Search by Name" : "Search by name, email, phone"
                }
                name={"Search"}
                searchCallbackfn={searchByUser}
                isSearching={isLoading}
                searchValue={searchValue === null ? "" : searchValue}
              />

              <button
                disabled={!checkAccess("MANAGEACCESS", "C")}
                onClick={() => {
                  {
                    navigate(
                      {
                        pathname: "/createUser",
                      },
                      {
                        state: "manageAccess",
                      }
                    );
                  }
                }}
                className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 py-1 px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                  !checkAccess("MANAGEACCESS", "C")
                    ? "accessDenied"
                    : "hover:cursor-pointer"
                }`}
              >
                Create User
                <FaPlus />
              </button>

              <button
                disabled={!checkAccess("MANAGEACCESS", "R")}
                onClick={() => {
                  navigate("/activity");
                }}
                className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 py-1 px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                  !checkAccess("MANAGEACCESS", "R")
                    ? "accessDenied"
                    : "hover:cursor-pointer"
                }`}
              >
                Activity
                <img src={activity} className="h-6 w-6" alt="" />
              </button>
            </div>
          </div>
          {userArray?.length ? (
            <>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
                <table className="w-full text-sm text-left text-gray-700 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      {settingTabheader.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-3 py-2 text-start"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {userArray?.map((item, index) => (
                      <tr className="bg-white border-b " key={index}>
                        <>
                          <td className="px-3 py-1 text-start">
                            <div>
                              <div>{item.id}</div>
                            </div>
                          </td>
                          <td className="px-3 py-1 text-start">
                            <div>
                              <div>{item.name}</div>
                            </div>
                          </td>
                          <td className="px-3 py-1 text-start">
                            <div>
                              <div>{item.emailId}</div>
                            </div>
                          </td>
                          <td className="px-3 py-1 text-start">
                            <div>
                              <div>{item.lastLogin}</div>
                            </div>
                          </td>
                          <td className="px-3 py-1 text-start truncate">
                            <div>
                              <div>{item.role}</div>
                            </div>
                          </td>
                          <td className="px-3 py-1 text-start">
                            <button
                              disabled={!checkAccess("MANAGEACCESS", "U")}
                              type="button"
                              onClick={() => {
                                navigate(
                                  {
                                    pathname: `/manageAccess/${item.id}`,
                                  },
                                  { state: item }
                                );
                              }}
                              className={`inline-flex items-center w-max py-1 text-sm justify-start font-medium text-center text-blue-500 rounded-lg hover:text-blue-800 ${
                                !checkAccess("MANAGEACCESS", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                            >
                              Manage Access
                            </button>
                          </td>
                          <td className="px-3 py-2 text-start">
                            <div className="flex gap-2 justify-start">
                              <div
                                className={`hover:text-blue-600 rounded-full ${
                                  !checkAccess("MANAGEACCESS", "U")
                                    ? "accessDenied"
                                    : "hover:cursor-pointer"
                                }`}
                                onClick={() => {
                                  if (checkAccess("MANAGEACCESS", "U")) {
                                    navigate(
                                      {
                                        pathname: "/updateUser",
                                      },
                                      { state: item }
                                    );
                                  }
                                }}
                              >
                                <MdModeEditOutline className="text-3xl" />
                              </div>
                              <div
                                onClick={() => {
                                  if (checkAccess("MANAGEACCESS", "D")) {
                                    setDeleteUserData({
                                      name: `User with id ${item.id}`,
                                      id: item.id,
                                    });
                                    setOpenDeleteCUserModal(true);
                                    //   setSearchValue("");
                                  }
                                }}
                                className={`hover:text-red-600 rounded-full ${
                                  !checkAccess("MANAGEACCESS", "D")
                                    ? "accessDenied"
                                    : "hover:cursor-pointer"
                                }`}
                              >
                                <MdDelete className="text-3xl" />
                              </div>
                            </div>
                          </td>
                        </>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalPages > 1 && !searchValue && (
                <div className="flex justify-end pr-5 pt-4">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    isLoading={allUser?.loading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="text-center flex flex-col justify-center items-center pt-36">
              <TbServerOff className="text-[2rem] font-bold m-2" />
              Users Not Found
            </div>
          )}
        </div>
      </div>

      {openDeleteCUserModal && (
        <DeleteModal
          data={deleteUserData}
          handleDelete={handleDeleteUser}
          setOpenDeleteModal={setOpenDeleteCUserModal}
        />
      )}

      {openResetPasswordModal && (
        <PasswordModal
          heading={"Change Passcode"}
          nameLabel={"New Passcode"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={handleChangePassword}
          openModal={openResetPasswordModal}
          setOpenModal={setOpenResetPasswordModal}
          secondNameLable={"Confirm Password"}
          flage={"resetPassword"}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allUser: state?.getUserAllReducer,
    deleteUser: state?.deleteUserReducer,
  };
};

export default connect(mapStateToProps)(Setting);
