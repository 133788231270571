import {
  UPDATE_QUIZ_REQUEST,
  UPDATE_QUIZ_SUCCESS,
  UPDATE_QUIZ_FAILURE,
  UPDATE_QUIZ_RESET,
} from "../Utils/Constants";

const initialState = {
  updateQuiz: {},
  loading: false,
  error: null,
};

const updateQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_QUIZ_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_QUIZ_SUCCESS:
      return {
        ...state,
        updateQuiz: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_QUIZ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_QUIZ_RESET:
      return {
        updateQuiz: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default updateQuizReducer;
