import {
    GET_SELECT_QUESTION_BY_ID_SUCCESS,
    GET_SELECT_QUESTION_BY_ID_REQUEST,
    GET_SELECT_QUESTION_BY_ID_FAILURE,
  } from "../Utils/Constants";
  
  export const getSelectQuestionByIdRequest = (payload) => {
    return {
      type: GET_SELECT_QUESTION_BY_ID_REQUEST,
      payload: payload,
    };
  };
  
  export const getSelectQuestionByIdSuccess = (data) => {
    return {
      type: GET_SELECT_QUESTION_BY_ID_SUCCESS,
      payload: data,
    };
  };
  
  export const getSelectQuestionByIdFailure = (error) => {
    return {
      type: GET_SELECT_QUESTION_BY_ID_FAILURE,
      payload: error,
    };
  };