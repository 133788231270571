import {
  GET_CONTACTUS_ALL_REQUEST,
  GET_CONTACTUS_ALL_SUCCESS,
  GET_CONTACTUS_ALL_FAILURE,
} from "../Utils/Constants";

// Action Creators
export const getContactUSAllRequest = (payload) => {
  return {
    type: GET_CONTACTUS_ALL_REQUEST,
    payload: payload,
  };
};

export const getContactUSAllSuccess = (data) => {
  return {
    type: GET_CONTACTUS_ALL_SUCCESS,
    payload: data,
  };
};

export const getContactUSAllFailure = (error) => {
  return {
    type: GET_CONTACTUS_ALL_FAILURE,
    payload: error,
  };
};
