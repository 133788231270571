import {
    GET_RANDOM_QUESTION_REQUEST,
    GET_RANDOM_QUESTION_SUCCESS,
    GET_RANDOM_QUESTION_FAILURE,
  } from "../Utils/Constants";
  
  export const getRandomQuestionRequest = (payload) => {
    return {
      type: GET_RANDOM_QUESTION_REQUEST,
      payload: payload,
    };
  };
  
  export const getRandomQuestionSuccess = (data) => {
    return {
      type: GET_RANDOM_QUESTION_SUCCESS,
      payload: data,
    };
  };
  
  export const getRandomQuestionFailure = (error) => {
    return {
      type: GET_RANDOM_QUESTION_FAILURE,
      payload: error,
    };
  };
  