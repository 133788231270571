import {
  ADD_QUIZ_REQUEST,
  ADD_QUIZ_SUCCESS,
  ADD_QUIZ_FAILURE,
  ADD_QUIZ_RESET,
} from "../Utils/Constants";

// initial state

const initialState = {
  AddQuiz: {},
  loading: false,
  error: null,
  flage: false,
};

// Reducer

const AddQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_QUIZ_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_QUIZ_SUCCESS:
      return {
        ...state,
        AddQuiz: action.payload,
        loading: false,
        error: null,
        flage: true,
        status: action.payload?.statusCode,
      };
    case ADD_QUIZ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        flage: false,
      };

    case ADD_QUIZ_RESET:
      return {
        AddQuiz: {},
        loading: false,
        error: null,
        flage: false,
        status: action.payload?.statusCode,
      };

    default:
      return state;
  }
};

export default AddQuizReducer;
