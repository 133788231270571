import { Navigate, Outlet } from "react-router-dom";
import Layout from "../components/Layout";
export const ProtectedRoutes = () => {
  const userToken =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  return userToken ? (
    <div>
      <Layout />
      <div className="md:pl-60 overflow-auto">
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};
