import {
  ACTIVE_USER_REQUEST,
  ACTIVE_USER_SUCCESS,
  ACTIVE_USER_FAILURE,
} from "../../Utils/Constants";

export const activeUserRequest = (payload) => {
  return {
    type: ACTIVE_USER_REQUEST,
    payload: payload,
  };
};

export const activeUserSuccess = (data) => {
  return {
    type: ACTIVE_USER_SUCCESS,
    payload: data,
  };
};

export const activeUserFailure = (error) => {
  return {
    type: ACTIVE_USER_FAILURE,
    payload: error,
  };
};
