import React, { useState } from "react";
import { SpinnerLoader } from "../Loaders/SpinnerLoader";

export const Button = ({ callbackFn, displayName, type, isLoading }) => {
  const [click, setClick] = useState(false)
  let style = "";

  if (type === "submit") {
    style =
      "text-sm text-green-500 hover:text-green-600 hover:bg-green-200 border-green-500";
  }
  if (type === "reset") {
    style =
      "text-sm text-red-500 hover:text-red-600 hover:bg-red-200 border-red-500";
  }
  return (
    <>
      <button
        onClick={()=>{
          setClick(true)
          callbackFn()}}
        className={
          "font-semibold py-2 px-4 border hover:border-transparent rounded min-w-[100px] " +
          style
        }
      >
        {isLoading && displayName === 'Submit' ? (
          <span className="ml-2">
            <SpinnerLoader size="xs" />
          </span>
        ) : (
          <span className="text-sm"> { displayName }</span>
         
        )}
      </button>
    </>
  );
};
