import {
  UPDATE_COMMISSION_RATES_REQUEST,
  UPDATE_COMMISSION_RATES_SUCCESS,
  UPDATE_COMMISSION_RATES_FAILURE,
} from "../../Utils/Constants";

export const updateCommissionRateRequest = (payload) => {
  return {
    type: UPDATE_COMMISSION_RATES_REQUEST,
    payload: payload,
  };
};

export const updateCommissionRateSuccess = (data) => {
  return {
    type: UPDATE_COMMISSION_RATES_SUCCESS,
    payload: data,
  };
};

export const updateCommissionRateFailure = (error) => {
  return {
    type: UPDATE_COMMISSION_RATES_FAILURE,
    payload: error,
  };
};
