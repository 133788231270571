import {
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    ADD_USER_FAILURE,
  } from "../../Utils/Constants";

  export const AddUserRequest = (payload) => {
    return {
        type: ADD_USER_REQUEST,
        payload: payload,
    }
  }

  export const AddUserSuccess = (data) => {
    return {
        type: ADD_USER_SUCCESS,
        payload: data,
    }
  }

  export const AddUserFailure = (error) => {
    return {
        type: ADD_USER_FAILURE,
        payload: error,
    }
  }