import {
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
  } from "../../Utils/Constants";
  
  export const updateUserRequest = (payload) => {
      return {
          type: UPDATE_USER_REQUEST,
          payload: payload
      }
  }
  
  export const updateUserSuccess = (data) => {
      return {
          type: UPDATE_USER_SUCCESS,
          payload: data
      }
  }
  
  export const updateUserFailure = (error) => {
      return {
          type: UPDATE_USER_FAILURE,
          payload: error
      }
  }
