import {
  ACTIVE_QUIZZES_STATUS_REQUEST,
  ACTIVE_QUIZZES_STATUS_SUCCESS,
  ACTIVE_QUIZZES_STATUS_FAILURE,
  ACTIVE_QUIZZES_STATUS_EMPTY,
} from "../Utils/Constants";

// Action Creators
export const activeQuizStatus = (payload) => {
  return {
    type: ACTIVE_QUIZZES_STATUS_REQUEST,
    payload: payload,
  };
};

export const activeQuizStatusSuccess = (data) => {
  return {
    type: ACTIVE_QUIZZES_STATUS_SUCCESS,
    payload: data,
  };
};

export const activeQuizStatusFailure = (error) => {
  return {
    type: ACTIVE_QUIZZES_STATUS_FAILURE,
    payload: error,
  };
};

export const activeQuizStatusEmpty = () => {
  return {
    type: ACTIVE_QUIZZES_STATUS_EMPTY,
  };
};
