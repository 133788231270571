import { put, call } from "redux-saga/effects";
import { DeleteCategoryFailure, DeleteCategorySuccess} from "../Actions";
import { DeleteAPI } from "../components/Config/Apiinterface";
import debounce from "../Utils/useDebounce";

// Worker saga for handling DeleteCtegory request
function* DeleteCategorySagas(action) {
    const token = action.payload.token;
    const categoryId = action.payload.categoryId;
    const path="category?categoryId="
  try {
    const response = yield debounce(DeleteAPI, token, categoryId,path);
    yield put(DeleteCategorySuccess(response));
  } catch (error) {
    yield put(DeleteCategoryFailure(error));
  }
}

export default DeleteCategorySagas;
