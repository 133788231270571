import { call, put } from "redux-saga/effects";
import { addQuizSuccess, addQuizFailure } from "../Actions/AddQuizsAction";
import { postFormData } from "../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

export function* addQuizSaga(action) {
  const path = "quiz";
  const token = action.payload.token;
  const body = action.payload.data;
  
  try {
    const result = yield debounce(postFormData, path, body, token);
    yield put(addQuizSuccess(result));
    if (result.statusCode === 201) {
      toast.success(result.message);
    } else {
      toast.warning(result.message);
    }
  } catch (err) {
    yield put(addQuizFailure(err));
    toast.error(err);
  }
}
