import { put, call } from "redux-saga/effects";
import { putRequestAPI } from "../../components/Config/Apiinterface";
import { resetPasswordSuccess, resetPasswordFailure } from "../../Actions";
import { toast } from "react-toastify";

// Worker saga for handling DeleteCtegory request
function* resetPasswordSaga(action) {
  const token = action.payload.token;
  const body = action.payload.data;
  const path = "admin/user/changePassword";
  try {
    const response = yield call(putRequestAPI, token, body, path);
    yield put(resetPasswordSuccess(response));
    if (response.statusCode === 403) {
      toast.error(response.message);
      return;
    }
    toast.success(response.message);
  } catch (error) {
    yield put(resetPasswordFailure(error));
  }
}

export default resetPasswordSaga;
