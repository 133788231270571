import {
  VERSION_POST_REQUEST,
  VERSION_POST_SUCCESS,
  VERSION_POST_FAILURE,
} from "../Utils/Constants";

// Action Creators
export const versionPostRequest = (payload) => {
  return {
    type: VERSION_POST_REQUEST,
    payload: payload,
  };
};

export const versionPostSuccess = (data) => {
  return {
    type: VERSION_POST_SUCCESS,
    payload: data,
  };
};

export const versionPostFailure = (error) => {
  return {
    type: VERSION_POST_FAILURE,
    payload: error,
  };
};
