import { put, call } from "redux-saga/effects";
import { ActiveCategoryFailure, ActiveCategorySuccess } from "../Actions";
import { ActiveAPI } from "../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Add Category request
function* ActiveCategorySaga(action) {
  const enableCategory = action.payload.enableCategory;
  const ctegoryId = action.payload.categoryId;
  const token = action.payload.token;
  const path = `category/enable?categoryId=${ctegoryId}&enableCategory=${enableCategory}`;
  try {
    const response = yield debounce(ActiveAPI, token, path);
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
    yield put(ActiveCategorySuccess(response));
  } catch (error) {
    yield put(ActiveCategoryFailure(error));
  }
}

export default ActiveCategorySaga;
