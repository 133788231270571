import { put, call } from "redux-saga/effects";
import { DeleteTopicFailure, DeleteTopicSuccess } from "../Actions";
import { DeleteAPI } from "../components/Config/Apiinterface";
import debounce from "../Utils/useDebounce";

// Worker saga for handling DeleteCtegory request
function* DeleteTopicSaga(action) {
  const token = action.payload.token;
  const topicId = action.payload.topicId;
  const path = "category/topic?topicId=";
  try {
    const response = yield debounce(DeleteAPI, token, topicId, path);
    yield put(DeleteTopicSuccess(response));
  } catch (error) {
    yield put(DeleteTopicFailure(error));
  }
}

export default DeleteTopicSaga;
