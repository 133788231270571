// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../firebase";

// /**
//  * Fetch documents from a specified Firestore collection.
//  * @param {string} collectionName - The name of the Firestore collection to fetch.
//  * @returns {Promise<Array>} - A promise that resolves to an array of documents from the collection.
//  */
// const fetchFirestoreCollection = async (collectionName) => {
//   try {
//     const collectionRef = collection(db, collectionName);
//     const snapshot = await getDocs(collectionRef);
//     const docsList = snapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//     return docsList;
//   } catch (err) {
//     console.error("Error fetching documents:", err);
//     throw err;
//   }
// };

// export default fetchFirestoreCollection;

import {
  collection,
  getDocs,
  query,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../firebase";

/**
 * Fetch paginated documents from a specified Firestore collection.
 * @param {string} collectionName - The name of the Firestore collection to fetch.
 * @param {number} pageSize - The number of documents to fetch per page.
 * @param {object} [lastDoc] - The last document from the previous fetch, used for pagination.
 * @returns {Promise<{docsList: Array, lastVisible: object}>} - A promise that resolves to an array of documents and pagination metadata.
 */
const fetchFirestoreCollection = async (
  collectionName,
  pageSize,
  lastDoc = null
) => {
  try {
    const collectionRef = collection(db, collectionName);
    let queryConstraints = [limit(pageSize)];

    if (lastDoc) {
      queryConstraints.push(startAfter(lastDoc));
    }

    const q = query(collectionRef, ...queryConstraints);
    const snapshot = await getDocs(q);

    const docsList = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const lastVisible = snapshot.docs[snapshot.docs.length - 1]; // Keep track of the last document

    return { docsList, lastVisible };
  } catch (err) {
    console.error("Error fetching documents:", err);
    throw err;
  }
};

export default fetchFirestoreCollection;


// import {
//   collection,
//   getDocs,
//   query,
//   limit,
//   startAfter,
//   orderBy,
// } from "firebase/firestore";
// import { db } from "../firebase";

// /**
//  * Fetch paginated documents from a specified Firestore collection.
//  * @param {string} collectionName - The name of the Firestore collection to fetch.
//  * @param {number} pageSize - The number of documents to fetch per page.
//  * @param {object} [lastDoc] - The last document from the previous fetch, used for pagination.
//  * @returns {Promise<{docsList: Array, lastVisible: object}>} - A promise that resolves to an array of documents and pagination metadata.
//  */
// const fetchFirestoreCollection = async (
//   collectionName,
//   pageSize,
//   lastDoc = null
// ) => {
//   try {
//     const collectionRef = collection(db, collectionName);
//     let queryConstraints = [
//       orderBy("timestamp", "desc"), // Order by timestamp in descending order
//       limit(2),
//     ];

//     if (lastDoc) {
//       queryConstraints.push(startAfter(lastDoc));
//     }

//     const q = query(collectionRef, ...queryConstraints);
//     const snapshot = await getDocs(q);

//     const docsList = snapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     const lastVisible =
//       snapshot.docs.length > 0 ? snapshot.docs[snapshot.docs.length - 1] : null; // Keep track of the last document

//     return { docsList, lastVisible };
//   } catch (err) {
//     console.error("Error fetching documents:", err);
//     throw err;
//   }
// };

// export default fetchFirestoreCollection;
