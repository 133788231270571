import {
    GET_ALL_USER_WITHDRAWALS_REQUEST,
    GET_ALL_USER_WITHDRAWALS_SUCCESS,
    GET_ALL_USER_WITHDRAWALS_FAILURE,
} from "../../Utils/Constants";

export const getAllUserWithdrawalsRequest = (payload) => {
    return {
        type : GET_ALL_USER_WITHDRAWALS_REQUEST,
        payload: payload,
    }
}

export const getAllUserWithdrawalsSuccess = (data) => {
    return {
        type : GET_ALL_USER_WITHDRAWALS_SUCCESS,
        payload: data,
    }
}

export const getAllUserWithdrawalsFailure = (error) => {
    return {
        type : GET_ALL_USER_WITHDRAWALS_FAILURE,
        payload: error,
    }
}