import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../../../Utils/useDebounce";
import { getPlayerAllFailure, getPlayerAllSuccess } from "../../../Actions";

export function* getPlayerAllSaga(action) {
  const token = action.payload.token;
  const userId = action.payload.userId;
  const pageNo = action.payload.pageNo;
  var sortOrder = action.payload.isSortOrder;
  const searchBy = action.payload.searchBy;
  const searchValue = action.payload.searchBy;
  const sortBy = action.payload.sortValue;
  const filterBy = action.payload.filterBy;
  const startDate = action.payload.startDate;
  const endDate = action.payload.endDate;


  function buildURL(
    pageNo,
    pageSize,
    sortBy,
    sortOrder,
    searchBy,
    searchValue,
    filterBy,
    startDate,
    endDate
  ) {
    let url;
    if (searchValue) {
      url = `admin/player/all?pageNo=${pageNo}&pageSize=${20}`;
    } else {
      url = `admin/player/all?pageNo=${pageNo}&pageSize=${20}`;
    }

    let hasQueryParams = false;

    if (sortBy) {
      // url += hasQueryParams ? "?" : "&";
      url += "&sortBy=" + encodeURIComponent(sortBy);
      hasQueryParams = true;
    }

    if (sortBy && sortOrder) {
      // url += hasQueryParams ? "&" : "?";
      url += "&sortOrder=" + encodeURIComponent(sortOrder);
      hasQueryParams = true;
    }

    if (searchBy) {
      // url += hasQueryParams ? "?" : "&";
      let search;

      if (/^[0-9]+$/.test(searchBy)) {
        search = "mobile";
      } else if (searchBy.includes("@" || ".")) {
        search = "emailId";
      } else {
        search = "name";
      }
      url += "&searchBy=" + encodeURIComponent(search);
      hasQueryParams = true;
    }
    // }

    if (
      searchValue !== null &&
      searchValue !== "null" &&
      searchValue !== undefined &&
      searchValue !== ""
    ) {
      // url += hasQueryParams ? "&" : "?";
      url += "&searchValue=" + encodeURIComponent(searchValue);
      hasQueryParams = true;
    }

    if (filterBy) {
      // url += hasQueryParams ? "?" : "&";
      url += "&filterBy=" + encodeURIComponent(filterBy);
      hasQueryParams = true;
    }
     if (startDate) {
       url += hasQueryParams ? "&" : "?";
       url += "startDate=" + encodeURIComponent(startDate);
       hasQueryParams = true;
     }

     if (endDate) {
       url += hasQueryParams ? "&" : "?";
       url += "endDate=" + encodeURIComponent(endDate);
       hasQueryParams = true;
     }

    return url;
  }

  const newurl = buildURL(
    pageNo,
    5,
    sortBy,
    sortOrder,
    searchBy,
    searchValue,
    filterBy,
    startDate,
    endDate
  );
  // ...

  const path = newurl;

  try {
    const response = yield debounce(GetAPI, token, path);
    yield put(getPlayerAllSuccess(response));
  } catch (error) {
    yield put(getPlayerAllFailure(error));
    toast.error(error.message);
  }
}
