import React from "react";
import { SpinnerLoader } from "./Loaders/SpinnerLoader";

export const Search = ({
  label,
  placeholder,
  name,
  searchCallbackfn,
  isSearching,
  searchValue,
  disabled,
}) => {
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <label
        htmlFor="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only"
      >
        {label}
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          {isSearching ? (
            <div className="flex items-center">
              <SpinnerLoader size={"sm"} />
            </div>
          ) : (
            <svg
              className="w-4 h-4 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          )}
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full truncate py-2 pl-10 pr-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-[#4CAF50] focus:border-[#4CAF50]"
          placeholder={placeholder}
          value={searchValue}
          onChange={searchCallbackfn}
          required
          disabled={disabled}
        />
        {/* <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
          >
            {name}
          </button> */}
      </div>
    </form>
  );
};
