import {
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
  } from "../../Utils/Constants";
  
  const initialState = {
    deleteUser: {},
    loading: false,
    error: null,
  };
  
  const deleteUserReducer = (state = initialState, action) => {
    switch (action.type) {
      case DELETE_USER_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case DELETE_USER_SUCCESS:
        return {
          ...state,
          deleteUser: action.payload,
          loading: false,
          error: null,
        };
      case DELETE_USER_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default deleteUserReducer;
  