import {
  getLeaderboardSuccess,
  getLeaderboardFailure,
  getLeaderboardSearchByNameSuccess,
  getLeaderboardSearchByNameFailure,
} from "../../Actions";
import { GetAPI, searchByID } from "../../components/Config/Apiinterface";
import { call, put } from "redux-saga/effects";
import debounce from "../../Utils/useDebounce";

export function* leaderboardSaga(action) {
  const token = action.payload.token;
  const quizId = action.payload.id;
  const pageNo = action.payload.pageNo;
  const path = `admin/quiz/leaderboard/all?quizId=${quizId}&pageNo=${pageNo}&pageSize=20`;

  try {
    const response = yield call(GetAPI, token, path);
    yield put(getLeaderboardSuccess(response));
  } catch (error) {
    yield put(getLeaderboardFailure(error));
  }
}

// ---------------Search Leaderboard data for User Name-------------------

export function* searchLeaderboardSaga(action) {
  const quizId = action.payload.id;
  const userName = action.payload.userName;
  const path = `admin/quiz/leaderboard/search?quizId=${quizId}&userName=${userName}`;

  try {
    const result = yield debounce(searchByID, action.payload.token, path);
    yield put(getLeaderboardSearchByNameSuccess(result));
  } catch (error) {
    yield put(getLeaderboardSearchByNameFailure(error));
  }
}
