import {
    GET_USER_UPI_ACCOUNT_SUCCESS,
    GET_USER_UPI_ACCOUNT_REQUEST,
    GET_USER_UPI_ACCOUNT_FAILURE
} from "../../Utils/Constants";


export const getUpiAccountsRequest = (payload) => {
    return {
      type: GET_USER_UPI_ACCOUNT_REQUEST,
      payload: payload,
    };
  };
  
  export const getUpiAccountsSuccess = (data) => {
    return {
      type: GET_USER_UPI_ACCOUNT_SUCCESS,
      payload: data,
    };
  };
  
  export const getUpiAccountsFailure = (error) => {
    return {
      type: GET_USER_UPI_ACCOUNT_FAILURE,
      payload: error,
    };
  };
  