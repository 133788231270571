import {
  TOPIC_BY_CATEGORYID_REQUEST,
  TOPIC_BY_CATEGORYID_SUCCESS,
  TOPIC_BY_CATEGORYID_FAILURE,
  TOPIC_BY_CATEGORYID_RESET,
} from "../Utils/Constants";

export const getTopicsByCategoryIdRequest = (payload) => {
  return {
    type: TOPIC_BY_CATEGORYID_REQUEST,
    payload: payload,
  };
};

export const getTopicsByCategoryIdSuccess = (data) => {
  return {
    type: TOPIC_BY_CATEGORYID_SUCCESS,
    payload: data,
  };
};

export const getTopicsByCategoryIdFailure = (error) => {
  return {
    type: TOPIC_BY_CATEGORYID_FAILURE,
    payload: error,
  };
};

export const topicsByCategoryIdReset = () => {
  return {
    type: TOPIC_BY_CATEGORYID_RESET,
  }
}
