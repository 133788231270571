import {
  ADD_QUIZ_REQUEST,
  ADD_QUIZ_SUCCESS,
  ADD_QUIZ_FAILURE,
  ADD_QUIZ_RESET
} from "../Utils/Constants";

// Action Creators

export const addQuizRequiest = (payload) => {
  return {
    type: ADD_QUIZ_REQUEST,
    payload: payload,
  };
};

export const addQuizSuccess = (data) => {
  return {
    type: ADD_QUIZ_SUCCESS,
    payload: data,
  };
};

export const addQuizFailure = (error) => {
  return {
    type: ADD_QUIZ_FAILURE,
    payload: error,
  };
};

export const addQuizReset = () => {
  return {
    type: ADD_QUIZ_RESET,
  }
}
