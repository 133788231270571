import React from "react";
import { FcLock } from "react-icons/fc";

const SiteBlocked = () => {
  return (
    <div className="min-h-screen bg-[#e8f5e9] flex items-center justify-center select-none">
      <div className="max-w-md bg-white p-8 shadow-lg rounded-lg">
        <div className="text-xl font-bold mb-4 text-center text-red-600">
          <div className="w-full flex justify-center items-center">
            <FcLock className="text-[4rem] my-2" />
          </div>
          - 403 -
        </div>
        <p className="text-sm text-gray-700 mb-8 text-center">
          Access to this content restricted outside India.
        </p>
      </div>
    </div>
  );
};

export default SiteBlocked;
