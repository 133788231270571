import {
  GET_QUIZ_SPOT_PERCENTAGE_FAILURE,
  GET_QUIZ_SPOT_PERCENTAGE_REQUEST,
  GET_QUIZ_SPOT_PERCENTAGE_SUCCESS,
} from "../../Utils/Constants";

export const getQuizSpotPercentageRequest = (payload) => { 
  return {
    type: GET_QUIZ_SPOT_PERCENTAGE_REQUEST,
    payload: payload,
  };
};

export const getQuizSpotPercentageSuccess = (data) => {
  return {
    type: GET_QUIZ_SPOT_PERCENTAGE_SUCCESS,
    payload: data,
  };
};

export const getQuizSpotPercentageFailure = (error) => {
  return {
    type: GET_QUIZ_SPOT_PERCENTAGE_FAILURE,
    payload: error,
  };
};
