import { put, call } from "redux-saga/effects";
import { ActiveTopicFailure, ActiveTopicSuccess } from "../Actions";
import { ActiveAPI } from "../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Add Category request
function* ActiveTopicSaga(action) {
  const enableTopic = action.payload.enableTopic;
  const topicId = action.payload.topicId;
  const token = action.payload.token;
  const path = `category/topic/enable?enableTopic=${enableTopic}&topicId=${topicId}`;
  try {
    const response = yield debounce(ActiveAPI, token, path);
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
    yield put(ActiveTopicSuccess(response));
  } catch (error) {
    yield put(ActiveTopicFailure(error));
  }
}

export default ActiveTopicSaga;

// category/enable?categoryId=1&enableCategory=false
