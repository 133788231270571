import * as CONST from "../../Utils/Constants";

export const getLeaderboardRequest = (payload) => {
    return {
      type: CONST.GET_ALL_LEADERBOARD_REQUEST,
      payload: payload,
    };
  };
  
  export const getLeaderboardSuccess = (data) => {
    return {
      type: CONST.GET_ALL_LEADERBOARD_SUCCESS,
      payload: data,
    };
  };
  
  export const getLeaderboardFailure = (error) => {
    return {
      type: CONST.GET_ALL_LEADERBOARD_FAILURE,
      payload: error,
    };
  };

  export const getLeaderboardSearchByNameRequest = (payload) => {
    return {
      type: CONST.GET_LEADERBOARD_BY_NAME_REQUEST,
      payload: payload,
    };
  };

  export const getLeaderboardSearchByNameSuccess = (data) => {
    return {
      type: CONST.GET_LEADERBOARD_BY_NAME_SUCCESS,
      payload:data,
    };
  };

  export const getLeaderboardSearchByNameFailure = (error) => {
    return {
      type : CONST.GET_LEADERBOARD_BY_NAME_FAILURE,
      payload: error,
    };
  };