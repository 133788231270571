import React from "react";
import { useNavigate } from "react-router-dom";
import pageNotFoundImg from "../assets/images/error/page-not-found.png";

export const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="h-[100vh] flex flex-col justify-center items-center">
      <div className="font-medium text-2xl">Page Not Found</div>
      <img src={pageNotFoundImg} alt="" className="h-96" />
      <div
        className="text-blue-500 underline hover:cursor-pointer hover:text-blue-700 transition"
        onClick={() => {
          navigate("/",{ replace: true });
        }}
      >
        Go to Home
      </div>
    </div>
  );
};
