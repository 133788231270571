import React from "react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import avtar from "../../src/assets/images/download.png";

export const Navbar = ({ showSidebar, setShowSidebar, name }) => {
  return (
    <div className="bg-gradient-to-r from-[#ffff] from-20% via-[#4CAF50]  to-[#23304C]  backdrop-blur-sm fixed z-10 w-full shadow-sm h-12 md:h-14 ">
      {/* <div className="bg-gradient-to-r from-[#4CAF50] to-[#23304C] opacity-95 backdrop-blur-sm fixed z-10 w-full shadow-sm h-12 md:h-14 "> */}

      <div className="flex justify-between items-center px-2 md:px-10 my-auto h-full">
        <div>
          {showSidebar ? (
            <AiOutlineMenuFold className="text-3xl" />
          ) : (
            <AiOutlineMenuUnfold
              className="text-3xl"
              onClick={() => setShowSidebar((prev) => !prev)}
            />
          )}
          {/* <AiOutlineMenuFold className="text-3xl" /> */}
        </div>
        <div className="flex gap-3 items-center">
          {/* <div className="text-sm font-semibold hover:cursor-pointer text-white">Account</div> */}
          <div className="text-sm font-semibold hover:cursor-pointer text-white">
            {name}
          </div>
          <div className="w-8 h-8 rounded-full">
            <img src={avtar} alt="avtar" className="rounded-full w-8 h-8" />
          </div>
        </div>
      </div>
    </div>
  );
};
