import {
  UPDATE_CONTACT_US_REQUEST,
  UPDATE_CONTACT_US_SUCCESS,
  UPDATE_CONTACT_US_FAILURE,
} from "../Utils/Constants";

const initialState = {
  updateContactUs: {},
  loading: false,
  error: null,
};

const updateContactUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTACT_US_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_CONTACT_US_SUCCESS:
      return {
        ...state,
        updateContactUs: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_CONTACT_US_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_CONTACT_US_EMPTY":
      return {
        ...state,
        loading: false,
        error: null,
        updateContactUs: {},
      };
    default:
      return state;
  }
};

export default updateContactUsReducer;
