import {
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
} from "../../Utils/Constants";

// Action Creators
export const getDashboardRequest = (payload) => {
  return {
    type: GET_DASHBOARD_REQUEST,
    payload: payload,
  };
};

export const getDashboardSuccess = (data) => {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: data,
  };
};

export const getDashboardFailure = (error) => {
  return {
    type: GET_DASHBOARD_FAILURE,
    payload: error,
  };
};

