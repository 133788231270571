import {
  ACTIVE_TOPIC_FAILURE,
  ACTIVE_TOPIC_REQUEST,
  ACTIVE_TOPIC_RESET,
  ACTIVE_TOPIC_SUCCESS,
  LOGOUT,
} from "../Utils/Constants";

// Action Creators
export const ActiveTopicRequest = (payload) => {
  return {
    type: ACTIVE_TOPIC_REQUEST,
    payload: payload,
  };
};

export const ActiveTopicSuccess = (data) => {
  return {
    type: ACTIVE_TOPIC_SUCCESS,
    payload: data,
  };
};

export const ActiveTopicFailure = (error) => {
  return {
    type: ACTIVE_TOPIC_FAILURE,
    payload: error,
  };
};

export const ActiveTopicReset = () => {
  return {
    type: ACTIVE_TOPIC_RESET,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT,
  };
};
