import {
    GET_USER_ALL_PLAYED_QUIZ_REQUEST,
    GET_USER_ALL_PLAYED_QUIZ_SUCCESS,
    GET_USER_ALL_PLAYED_QUIZ_FAILURE,
} from "../../Utils/Constants";


export const getUserAllPlayedQuizRequest = (payload) => {
    return {
      type: GET_USER_ALL_PLAYED_QUIZ_REQUEST,
      payload: payload,
    };
  };
  
  export const getUserAllPlayedQuizSuccess = (data) => {
    return {
      type: GET_USER_ALL_PLAYED_QUIZ_SUCCESS,
      payload: data,
    };
  };
  
  export const getUserAllPlayedQuizFailure = (error) => {
    return {
      type: GET_USER_ALL_PLAYED_QUIZ_FAILURE,
      payload: error,
    };
  };
  