import {
  DELETE_SCHEDULE_NOTIFICATION_FAILURE,
  DELETE_SCHEDULE_NOTIFICATION_REQUEST,
  DELETE_SCHEDULE_NOTIFICATION_SUCCESS,
} from "../../Utils/Constants";

export const deleteScheduleNotificationRequest = (payload) => {
  return {
    type: DELETE_SCHEDULE_NOTIFICATION_REQUEST,
    payload: payload,
  };
};

export const deleteScheduleNotificationSuccess = (response) => {
  return {
    type: DELETE_SCHEDULE_NOTIFICATION_SUCCESS,
    payload: response,
  };
};

export const deleteScheduleNotificationFailure = (error) => {
  return {
    type: DELETE_SCHEDULE_NOTIFICATION_FAILURE,
    payload: error,
  };
};
