import React from "react";
import { getUrlString } from "../components/Config/appConfig";
import { toast } from "react-toastify";

const GetExcelFile = async (data) => {
  const token = data?.token;
  const sortby = data?.sortBy;
  const sortOrder = data?.sortOrder;
  const searchBy = data?.searchBy;
  const searchvalue = data?.searchValue;
  const filterBy = data?.filterBy;
  const startDate = data?.startDate;
  const endDate = data?.endDate;

  function buildURL(
    sortby,
    sortOrder,
    searchBy,
    searchvalue,
    filterBy,
    startDate,
    endDate
  ) {
    let url = ``;
    url = `admin/allTransactions/export?pageNo=${0}`;

    if (sortby) {
      url += "&sortBy=" + encodeURIComponent(sortby);
    }

    if (sortOrder) {
      url += "&sortOrder=" + encodeURIComponent(sortOrder);
    }

    if (searchBy) {
      url += "&searchBy=" + encodeURIComponent(searchBy);
    }

    if (searchvalue) {
      url += "&searchValue=" + encodeURIComponent(searchvalue);
    }

    if (filterBy) {
      url += "&filterBy=" + encodeURIComponent(filterBy);
    }

    if (startDate) {
      url += "&startDate=" + encodeURIComponent(startDate);
    }
    if (endDate) {
      url += "&endDate=" + encodeURIComponent(endDate);
    }

    return url;
  }

  const newurl = buildURL(
    sortby,
    sortOrder,
    searchBy,
    searchvalue,
    filterBy,
    startDate,
    endDate
  );

  try {
    const options = {
      method: "GET",
      headers: {
        Accesstoken: token,
        accept: "*/*",
      },
    };
    toast.loading("Preparing Excel ...");
    const response = await fetch(`${getUrlString()}${newurl}`, options);
    toast.dismiss();
    if (!response.ok) {
      if (response.status === 403) {
        window.location.href = "/";
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Assuming the API returns a Blob (Binary Large Object) containing the Excel file
    const blob = await response.blob();

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    let fileName = "";
    if (filterBy) {
      fileName += filterBy + "_";
    }
    if (searchvalue) {
      fileName += searchvalue + "_";
    }
    a.download = `${fileName}transactions.xlsx`; // Set the filename you want

    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    toast.error("Something went wrong");
    console.error("Error exporting file:", error);
  }
};

export default GetExcelFile;
