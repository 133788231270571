import {
  ALL_CATEGORIES_FAILURE,
  ALL_CATEGORIES_REQUEST,
  ALL_CATEGORIES_RESET,
  ALL_CATEGORIES_SUCCESS,
  LOGOUT,
} from "../Utils/Constants";

// Action Creators
export const CategoriesRequest = (payload) => {
  return {
    type: ALL_CATEGORIES_REQUEST,
    payload: payload,
  };
};

export const CategoriesSuccess = (data) => {
  return {
    type: ALL_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const CategoriesFailure = (error) => {
  return {
    type: ALL_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const CategoriesReset = () => {
  return {
    type: ALL_CATEGORIES_RESET,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT,
  };
};
