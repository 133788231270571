import {
  GET_USER_ALL_TRANSACTIONS_REQUEST,
  GET_USER_ALL_TRANSACTIONS_SUCCESS,
  GET_USER_ALL_TRANSACTIONS_FAILURE,
} from "../../Utils/Constants";

export const getUserAllTransectionsRequest = (payload) => {
  return {
    type: GET_USER_ALL_TRANSACTIONS_REQUEST,
    payload: payload,
  };
};

export const getUserAllTransectionsSuccess = (data) => {
  return {
    type: GET_USER_ALL_TRANSACTIONS_SUCCESS,
    payload: data,
  };
};

export const getUserAllTransectionsFailure = (error) => {
  return {
    type: GET_USER_ALL_TRANSACTIONS_FAILURE,
    payload: error,
  };
};
