import {
  GET_MANAGE_ACCESS_REQUEST,
  GET_MANAGE_ACCESS_SUCCESS,
  GET_MANAGE_ACCESS_FAILURE,
  POST_MANAGE_ACCESS_REQUEST,
  POST_MANAGE_ACCESS_SUCCESS,
  POST_MANAGE_ACCESS_FAILURE,
  GET_MANAGE_ACCESS_SESSION,
} from "../../Utils/Constants";

export const getManageRequest = (payload) => {
  return {
    type: GET_MANAGE_ACCESS_REQUEST,
    payload: payload,
  };
};

export const getManageSuccess = (data) => {
  return {
    type: GET_MANAGE_ACCESS_SUCCESS,
    payload: data,
  };
};

export const getManageFailure = (error) => {
  return {
    type: GET_MANAGE_ACCESS_FAILURE,
    payload: error,
  };
};

export const postManageRequest = (payload) => {
  return {
    type: POST_MANAGE_ACCESS_REQUEST,
    payload: payload,
  };
};

export const postManageSuccess = (data) => {
  return {
    type: POST_MANAGE_ACCESS_SUCCESS,
    payload: data,
  };
};

export const postManageFailure = (error) => {
  return {
    type: POST_MANAGE_ACCESS_FAILURE,
    payload: error,
  };
};

