import {
  SEARCH_TOPIC_REQUEST,
  SEARCH_TOPIC_SUCCESS,
  SEARCH_TOPIC_FAILURE,
} from "../Utils/Constants";

export const searchTopicsRequest = (payload) => {
    return {
        type: SEARCH_TOPIC_REQUEST,
        payload: payload
    }
};

export const searchTopicsSuccess = (data) => {
    return {
        type : SEARCH_TOPIC_SUCCESS,
        payload: data
    }
};

export const searchTopicsFailure = (error) => {
    return {
        type: SEARCH_TOPIC_FAILURE,
        payload: error
    }
};
