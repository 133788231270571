import { SEND_SHEDULED_NOTIFICATION_FAILURE, SEND_SHEDULED_NOTIFICATION_REQUEST, SEND_SHEDULED_NOTIFICATION_SUCCESS } from "../../Utils/Constants";


const initialState = {
  sendSheduledNotification: {},
  loading: false,
  error: null,
};

const sendSheduledNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_SHEDULED_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SEND_SHEDULED_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sendSheduledNotification: action.payload,
        loading: false,
        error: null,
      };

    case SEND_SHEDULED_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default sendSheduledNotificationReducer;
